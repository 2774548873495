import { registerQuery, useQuery } from 'data-fetching-client';
import { stringify } from 'hub-http/helpers/params';
import { httpClientWithRaven } from '../../clients/hubHttpWithRaven';
import { BILLING_EVENT_TYPES_LIST } from '../../constants/billingEventTypes';
import { getLocalStorageItem, setLocalStorageItem } from '../../utils/localStorage';
const GET_IS_BILLING_HISTORY_ELIGIBILE = registerQuery({
  args: [],
  fieldName: 'billingHistoryEligibility',
  fetcher() {
    return httpClientWithRaven.get(`billing-events-index/v1/is-eligible?${stringify({
      eventType: BILLING_EVENT_TYPES_LIST
    })}`);
  }
});
export const useGetBillingHistoryEligibility = ({
  skip,
  hubId
}) => {
  const BILLING_HISTORY_TAB_VISIBLE_KEY = `billingHistoryTabVisible_${hubId}`;
  const billingHistoryTabVisible = getLocalStorageItem(BILLING_HISTORY_TAB_VISIBLE_KEY);
  let eligible = billingHistoryTabVisible === 'true';
  const {
    data,
    loading,
    error
  } = useQuery(GET_IS_BILLING_HISTORY_ELIGIBILE, {
    skip: skip || eligible
  });
  if (data !== null && data !== void 0 && data.billingHistoryEligibility.eligible) {
    eligible = true;
    setLocalStorageItem(BILLING_HISTORY_TAB_VISIBLE_KEY, 'true');
  }
  return {
    eligible,
    loading,
    error
  };
};
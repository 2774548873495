import { FileTypes } from '.';
const IMG_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'tif', 'tiff', 'ico', 'bmp', 'webp', 'svg'];
const MOVIE_TYPES = ['mov', 'avi', 'flv', 'wmv', 'rm', '3gp', '3g2', 'asf', 'asx', 'mpg', 'mp4', 'mpeg', 'swf', 'm4v', 'webm'];
const DOCUMENT_TYPES = ['csv', 'doc', 'docx', 'dot', 'dotx', 'key', 'md', 'pdf', 'pot', 'potx', 'pps', 'ppsx', 'ppt', 'pptx', 'txt', 'wpd', 'wps', 'wri', 'xls', 'xlsb', 'xlsx', 'xlt', 'xlx', 'xml'];
const AUDIO_TYPES = ['aac', 'aif', 'm4a', 'mp3', 'mpa', 'ra', 'wav', 'wma'];
export const TypeToExtensions = {
  [FileTypes.IMG]: IMG_TYPES,
  [FileTypes.MOVIE]: MOVIE_TYPES,
  [FileTypes.DOCUMENT]: DOCUMENT_TYPES,
  [FileTypes.AUDIO]: AUDIO_TYPES
};
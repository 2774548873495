import { registerQuery, useQuery } from 'data-fetching-client';
import { httpClientWithRaven } from '../clients/hubHttpWithRaven';
const GET_MIGRATION_DETAILS = registerQuery({
  fetcher() {
    return httpClientWithRaven.get('legacying-app/v1/migration-details/completed-migrations/by-hub-id', {
      ravenOptions: {
        message: 'Error fetching migration details'
      }
    });
  },
  fieldName: 'migrationDetails'
});
export const useGetMigrationDetails = ({
  skip = false
}) => {
  const {
    data,
    loading,
    error
  } = useQuery(GET_MIGRATION_DETAILS, {
    skip
  });
  return {
    data: data === null || data === void 0 ? void 0 : data.migrationDetails,
    loading,
    error
  };
};
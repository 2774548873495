import { BILLING_WRITE_SCOPE, BILLING_READ_SCOPE, BILLING_READ_RESTRICTED_SCOPE, MARKETABLE_CONTACTS_WRITE_SCOPE, PARTNER_DOCUMENT_READ_SCOPE, PARTNER_DOCUMENT_WRITE_SCOPE, HUB_CONTACT_ROLES_WRITE_SCOPE } from './scopes.constants';
export const BILLING_ADMIN_ROLE = 'BILLING_ADMIN';
export const JITA_READONLY_ROLE = 'JITA_READONLY';
export const MARKETING_CONTACTS_MANAGER_ROLE = 'MARKETING_CONTACTS_MANAGER';
export const BILLING_VIEW_ROLE = 'BILLING_VIEW';
export const PARTNER_BILLING_ADMIN_ROLE = 'PARTNER_BILLING_ADMIN';
export const PARTNER_BILLING_VIEW_ROLE = 'PARTNER_BILLING_VIEW';
export const BILLING_CONTACTS_MANAGER_ROLE = 'BILLING_CONTACTS_MANAGER';
const billingViewScopes = [BILLING_READ_SCOPE];
const jitaReadOnlyScopes = [...billingViewScopes, BILLING_READ_RESTRICTED_SCOPE];
const billingAdminScopes = [...jitaReadOnlyScopes, BILLING_WRITE_SCOPE];
const partnerBillingAdminScopes = [...billingAdminScopes, PARTNER_DOCUMENT_WRITE_SCOPE];
const partnerBillingViewScopes = [...billingViewScopes, PARTNER_DOCUMENT_READ_SCOPE];
const marketingContactsManagerScopes = [MARKETABLE_CONTACTS_WRITE_SCOPE];
export const BILLING_ROLES = {
  [BILLING_ADMIN_ROLE]: {
    scopes: billingAdminScopes
  },
  [BILLING_CONTACTS_MANAGER_ROLE]: {
    scopes: [...billingViewScopes, HUB_CONTACT_ROLES_WRITE_SCOPE]
  },
  [JITA_READONLY_ROLE]: {
    scopes: jitaReadOnlyScopes
  },
  [MARKETING_CONTACTS_MANAGER_ROLE]: {
    scopes: marketingContactsManagerScopes
  },
  [BILLING_VIEW_ROLE]: {
    scopes: billingViewScopes
  },
  [PARTNER_BILLING_ADMIN_ROLE]: {
    scopes: partnerBillingAdminScopes
  },
  [PARTNER_BILLING_VIEW_ROLE]: {
    scopes: partnerBillingViewScopes
  }
};
import { registerQuery } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';
import { EMBED_DASHBOARD_APP_CONFIG, EMBED_DASHBOARD_APP_IDS } from './embed-dashboard-ids';
const EMBEDDED_DASHBOARD_BASE_URL = '/dashboard/v1/embedded-dashboard-settings';
const filterOutInternalEmbeddedApps = embeddedAppIds => {
  return embeddedAppIds.filter(id => id in EMBED_DASHBOARD_APP_CONFIG && id !== EMBED_DASHBOARD_APP_IDS.KITCHEN_SINK_APP_DASHBOARD_KEY);
};
const fetchUsedInEmbedDashboards = dashboardId => {
  return http.get(`${EMBEDDED_DASHBOARD_BASE_URL}/embedded-app-ids/${dashboardId}`).then(filterOutInternalEmbeddedApps).catch(__err => {
    return [];
  });
};
export const GET_USED_IN_EMBED_DASHBOARDS = registerQuery({
  fieldName: 'embeddedAppIds',
  args: ['dashboardId'],
  fetcher({
    dashboardId
  }) {
    return fetchUsedInEmbedDashboards(dashboardId);
  }
});
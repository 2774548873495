import { useState, useCallback } from 'react';
import identity from 'transmute/identity';
/**
 * Keeps track of the input control states.
 * For example:
 *   - the current value held for this input
 *   - whether the input has been changed (dirty)
 */
export const useInputControl = ({
  initialValue,
  callback = identity
}) => {
  const [dirty, setDirty] = useState(false);
  const [value, setValue] = useState(initialValue);
  const onChange = useCallback(e => {
    const newValue = e.target.value;
    setValue(newValue);
    callback(newValue);
    setDirty(true);
  }, [callback]);
  return {
    value,
    onChange,
    dirty
  };
};
import { httpClientWithRaven } from '../../../clients/hubHttpWithRaven';
import { registerQuery, useQuery } from 'data-fetching-client';
export const GET_PROCESSING_PAYMENT_METHOD_TYPES = registerQuery({
  fieldName: 'processingPaymentMethodTypes',
  fetcher() {
    return httpClientWithRaven.get('/transactions-experience/v1/payments/payment-method-types-with-status?paymentStatus=Processing');
  }
});
export const useGetProcessingPaymentMethodTypes = ({
  skip
} = {}) => {
  var _data$processingPayme, _data$processingPayme2;
  const {
    loading,
    data,
    error,
    refetch
  } = useQuery(GET_PROCESSING_PAYMENT_METHOD_TYPES, {
    refetchWritePolicy: 'overwrite',
    skip
  });
  const paymentMethodTypes = (_data$processingPayme = data === null || data === void 0 || (_data$processingPayme2 = data.processingPaymentMethodTypes) === null || _data$processingPayme2 === void 0 ? void 0 : _data$processingPayme2.paymentMethodTypes) !== null && _data$processingPayme !== void 0 ? _data$processingPayme : [];
  if (paymentMethodTypes.includes('ACH')) {
    return {
      loading,
      isACHPaymentsProcessing: true,
      error,
      refetch
    };
  }
  return {
    loading,
    isACHPaymentsProcessing: false,
    error,
    refetch
  };
};
'use es6';

import enviro from 'enviro';
import I18n from 'I18n';
import { fromJS, List, Map as ImmutableMap } from 'immutable';
import { propertyLabelTranslator } from 'property-translator/propertyTranslator';
import { gate } from 'hub-http/gates';
import { CRM_OBJECT_META_TYPE } from '../constants/crmObjectMetaTypes';
import { APPOINTMENT, ATTRIBUTION_TOUCH_POINTS, CALL, COMMERCE_PAYMENTS, COMPANIES, CONTACT_CREATE_ATTRIBUTION, CONTACTS, CONVERSATIONS, COURSE, DEAL_CREATE_ATTRIBUTION, DEALS, EMAIL_OBJECT, FEEDBACK_SUBMISSIONS, FORECAST, GOAL_TARGET, LEADS, LINE_ITEMS, LISTING, MEETING_EVENT, PLAYBOOK_SUBMISSION, PLAYBOOK, SEQUENCE_ENROLLMENTS, SEQUENCE_STEP_ENROLLMENTS, SERVICES, SUBSCRIPTIONS, TASK, TICKETS, AI_FORECAST, ENGAGEMENT, PARTNER_ACCOUNT, PARTNER_CLIENT, PARTNER_SERVICE, DEAL_REGISTRATION, USER, INVOICE, CAMPAIGN_SPEND_ITEM, CARTS, ORDERS } from '../constants/dataTypes/inboundDb';
import * as checked from '../lib/checked';
import { isCustomObjectType } from '../lib/customObjects';
import * as http from '../request/http';
import { userInfo } from '../request/user-info';
import { fetchCrmObjectProperties } from './tsUtils';
import { dataTypeSettings } from '../retrieve/inboundDb/common/dataTypes';
const CrmObjectDataTypeDefinition = checked.record({
  name: checked.string(),
  objectTypeId: checked.string(),
  id: checked.number(),
  metaTypeId: checked.number(),
  defaultSearchPropertyNames: checked.list().optional(),
  createDatePropertyName: checked.string().optional(),
  requiredDateFilterProperty: checked.string().optional(),
  pluralForm: checked.string(),
  originalMetaDefinition: checked.any()
}, 'CrmObjectDataTypeDefinition');
const CrmObjectPropertyGroupDefinition = checked.record({
  name: checked.string(),
  displayName: checked.string(),
  properties: checked.list(checked.any().fromJS()),
  displayOrder: checked.number(),
  hubspotDefined: checked.boolean(),
  propertyNames: checked.list(checked.any().fromJS())
}, 'CrmObjectPropertyGroupDefinition');
let crmObjectMap = ImmutableMap({});
let crmObjectPropertyMap = ImmutableMap({});
let crmObjectPropertyGroupMap = ImmutableMap({});

/**
 * This is a map for reference to the actual name of the hubspot object while we work through migrating through objects in
 * reporting platform that we still refer to as 'CONTACT', 'DEAL', 'TICKET', etc...
 * In the future this will completely go away and everything will be 0-1,0-3, 0-5, etc...
 *
 * It could just be list of the objectTypeIds but that would be diffcult for traceability while performing these migrations
 */
export const HUBSPOT_CRM_OBJECTS_BY_ID = ImmutableMap({
  '0-1': CONTACTS,
  '0-2': COMPANIES,
  '0-3': DEALS,
  '0-4': ENGAGEMENT,
  '0-5': TICKETS,
  '0-8': LINE_ITEMS,
  '0-11': CONVERSATIONS,
  // CONVERSATIONS https://git.hubteam.com/HubSpot/Reporting-as-a-Service/issues/328
  '0-19': FEEDBACK_SUBMISSIONS,
  '0-20': ATTRIBUTION_TOUCH_POINTS,
  '0-27': TASK,
  '0-47': MEETING_EVENT,
  '0-48': CALL,
  '0-49': EMAIL_OBJECT,
  '0-52': CONTACT_CREATE_ATTRIBUTION,
  '0-53': INVOICE,
  '0-60': FORECAST,
  '0-63': DEAL_CREATE_ATTRIBUTION,
  '0-68': SEQUENCE_ENROLLMENTS,
  '0-69': SUBSCRIPTIONS,
  '0-73': DEAL_REGISTRATION,
  '0-74': GOAL_TARGET,
  '0-79': SEQUENCE_STEP_ENROLLMENTS,
  '0-96': PLAYBOOK,
  '0-99': PLAYBOOK_SUBMISSION,
  '0-101': COMMERCE_PAYMENTS,
  '0-115': USER,
  '0-123': ORDERS,
  '0-125': PARTNER_ACCOUNT,
  '0-131': CAMPAIGN_SPEND_ITEM,
  '0-136': LEADS,
  '0-142': CARTS,
  '0-145': PARTNER_CLIENT,
  '0-149': AI_FORECAST,
  '0-162': SERVICES,
  '0-164': PARTNER_SERVICE,
  '0-410': COURSE,
  '0-420': LISTING,
  '0-421': APPOINTMENT
});
export const ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS = HUBSPOT_CRM_OBJECTS_BY_ID.flip();

/**
 * This List indicates which of the HUBSPOT_CRM_OBJECTS_BY_ID objects can
 * appear in the Single Object Builder alongside the legacy inbound db hubspot
 * objects and custom objects.
 */
const BUILDER_ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS = fromJS(['0-11', '0-27', '0-47', '0-48', '0-53', '0-68', '0-69', '0-73', '0-74', '0-101', '0-115', '0-123', '0-125', '0-136', '0-142', '0-145', '0-162', '0-164', '0-410', '0-420', '0-421']);

/**
 * For a gated roll out of an object type to the single object builder, this
 * mapping can be used to indicate which gates need to be present for the object
 * to appear in the builder. The format of this mapping is `objectTypeId` ->
 * List of gates.
 *  * EX:
 * '0-11': ['HAS_NEW_OBJECT_REPORTING_ACCESS_GATE','OTHER_RELEVANT_GATE']
 *
 * V3 gates must be wrapped here in the `gate` function from 'hub-http/gates'
 * as there's an eslint rule restricting calls of gate to string literals.
 */
const HUBSPOT_DEFINED_CRM_OBJECT_BUILDER_GATES = fromJS({
  '0-74': ['Goals:SOReportBuilder'],
  '0-115': ['UserObject:CustomReportBuilder'],
  '0-123': [gate('Order:ObjectTypeRollout')],
  '0-125': ['PartnerAccount:ObjectTypeRollout'],
  '0-136': ['LeadTracking:GeneralAccess'],
  '0-142': [gate('Cart:ObjectTypeRollout')],
  '0-145': ['PartnerClientCrmObject:ObjectTypeRollout'],
  '0-162': ['DMM:ObjectLibrary'],
  '0-164': ['PartnerServiceCrmObject:ObjectTypeRollout'],
  '0-410': ['DMM:ObjectLibrary'],
  '0-420': ['DMM:ObjectLibrary'],
  '0-421': ['DMM:ObjectLibrary']
});
const buildCRMObjectDataTypeDefinition = objectMetaDefinition => {
  const objectTypeId = objectMetaDefinition.get('objectTypeId');
  const dataType = HUBSPOT_CRM_OBJECTS_BY_ID.get(objectTypeId);
  const dataTypeOverrides = dataTypeSettings[dataType] || {};
  return CrmObjectDataTypeDefinition(Object.assign({
    id: objectMetaDefinition.get('id'),
    metaTypeId: objectMetaDefinition.get('metaTypeId'),
    name: ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS.includes(objectMetaDefinition.get('objectTypeId')) ? I18n.text(`reporting-data.dataTypes.crmObjects.${HUBSPOT_CRM_OBJECTS_BY_ID.get(objectTypeId)}.singularForm`) : objectMetaDefinition.get('singularForm') || objectMetaDefinition.get('name'),
    objectTypeId,
    defaultSearchPropertyNames: objectMetaDefinition.get('defaultSearchPropertyNames'),
    createDatePropertyName: objectMetaDefinition.get('createDatePropertyName'),
    pluralForm: ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS.includes(objectTypeId) ? I18n.text(`reporting-data.dataTypes.crmObjects.${HUBSPOT_CRM_OBJECTS_BY_ID.get(objectTypeId)}.pluralForm`) : objectMetaDefinition.get('pluralForm'),
    originalMetaDefinition: objectMetaDefinition
  }, dataTypeOverrides.requiredDateFilterProperty ? {
    requiredDateFilterProperty: dataTypeOverrides.requiredDateFilterProperty
  } : {}));
};

/**
 * Used for readability in places where dataType could be the objectTypeId or CRM_OBJECT
 *
 * @param {string} dataType
 * @returns {boolean}
 * @deprecated
 * @use isSupportedCrmObjectIncludingHSDefined
 */
export const isSupportedCrmObject = dataType => dataType && (isCustomObjectType(dataType) || dataType === 'CRM_OBJECT') || ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS.includes(dataType);

/**
 * This is a patched version of the above isSupportedCrmObject
 * function that includes the HubSpot defined CRM objects, such
 * as "DEALS". The above function should include these objects
 * but doesn't due to a bug in its implementation,
 * (ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS.includes(...) is always
 * false and should instead be ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS.has(...)).
 *
 * This isSupportedCrmObject function is utilized in many
 * sensitive areas and updating its logic might break existing
 * behavior in some areas, and fixing it would require a deep
 * investigation into these areas. For now I've added the below
 * isSupportedCrmObjectIncludingHSDefined function to use in
 * places we'd like to check for CRM Object types going forward.
 *
 * @param {string} dataType
 * @returns {boolean}
 */
export const isSupportedCrmObjectIncludingHSDefined = dataType => isSupportedCrmObject(dataType) || ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS.has(dataType);
const hasAccessToDealRegistration = portal => {
  // see https://git.hubteam.com/HubSpot/reporting/pull/14455#discussion_r2901516 for why 53 is always allowed
  if (enviro.isProd() && portal.portal_id === 53 || enviro.isQa() && portal.portal_id === 99535353) {
    return true;
  }
  return false;
};
export const getBuilderSupportedCrmObjects = ({
  bypassScopes = false
} = {}) => userInfo().then(info => http.get('customer-object-types/v1/for-portal').then(objectTypes => objectTypes.filter(objectType => {
  const objectTypeId = objectType.get('objectTypeId');
  const {
    user,
    gates,
    portal
  } = info;
  if (DEAL_REGISTRATION === objectType.get('name')) {
    if (!hasAccessToDealRegistration(portal)) {
      return false;
    }
  }
  return objectType.get('metaTypeId') === CRM_OBJECT_META_TYPE.PORTAL_SPECIFIC && (bypassScopes || user.scopes.includes('custom-object-access')) || BUILDER_ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS.includes(objectTypeId) && HUBSPOT_DEFINED_CRM_OBJECT_BUILDER_GATES.get(objectTypeId, List()).every(requiredGate => gates.includes(requiredGate));
})).then(objectTypes => {
  objectTypes.forEach(objectType => {
    crmObjectMap = crmObjectMap.set(objectType.get('objectTypeId'), buildCRMObjectDataTypeDefinition(objectType));
  });
  return objectTypes.map(objectType => buildCRMObjectDataTypeDefinition(objectType));
}));
export const PROPERTIES_V4_ONLY_SUPPORTED_OBJECTS = [ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS.get(SEQUENCE_ENROLLMENTS)];
export const getCrmObjectProperties = objectTypeId => crmObjectPropertyMap.includes(objectTypeId) ? Promise.resolve(crmObjectPropertyMap.get(objectTypeId)) : http.get(`properties/v4/${objectTypeId}`).then(properties => {
  crmObjectPropertyMap = crmObjectPropertyMap.set(objectTypeId, properties.map(property => property.get('property')));
  return crmObjectPropertyMap.get(objectTypeId);
});
export const getCrmObjectPropertyGroups = objectTypeId => crmObjectPropertyGroupMap.includes(objectTypeId) ? Promise.resolve(crmObjectPropertyGroupMap.get(objectTypeId)) : fetchCrmObjectProperties(objectTypeId).then(propertyGroups => {
  crmObjectPropertyGroupMap = crmObjectPropertyMap.set(objectTypeId, propertyGroups.map(property => CrmObjectPropertyGroupDefinition({
    name: property.get('name'),
    displayName: property.get('displayName'),
    properties: property.get('properties').map(prop => prop.update('label', label => propertyLabelTranslator(label))),
    displayOrder: property.get('displayOrder'),
    hubspotDefined: property.get('hubspotDefined')
  })));
  return crmObjectPropertyGroupMap.get(objectTypeId);
});
export const getCrmObjectData = objectTypeId => crmObjectMap.includes(objectTypeId) ? Promise.resolve(crmObjectMap.get(objectTypeId)) : http.get(`inbounddb-meta/v1/object-types/${objectTypeId}`).then(objectMetaDefinition => {
  crmObjectMap = crmObjectMap.set(objectTypeId, buildCRMObjectDataTypeDefinition(objectMetaDefinition));
  return crmObjectMap.get(objectTypeId);
});
const getCrmObjectDataByName = objectName => crmObjectMap.includes(objectName) ? Promise.resolve(crmObjectMap.get(objectName)) : http.get(`inbounddb-meta/v1/object-types/batch-get-by-names?name=${objectName}`).then(objectMetaDefinition => {
  crmObjectMap = crmObjectMap.set(objectName, buildCRMObjectDataTypeDefinition(objectMetaDefinition.get(0)));
  return crmObjectMap.get(objectName);
});
export const getCrmObjectName = objectTypeId => getCrmObjectData(objectTypeId).then(object => object.get('name'));
export const getCrmObjectLabelByName = objectName => getCrmObjectDataByName(objectName).then(object => object.get('name'));
export const getCrmObjectPluralName = objectTypeId => getCrmObjectData(objectTypeId).then(object => object.get('pluralForm'));
export const getDefaultProperties = objectTypeId => getCrmObjectData(objectTypeId).then(object => ({
  defaultProperties: object.get('defaultSearchPropertyNames'),
  defaultDateProperty: object.get('requiredDateFilterProperty') || object.get('createDatePropertyName'),
  pluralName: object.get('pluralForm')
}));
export const __TESTABLE__ = {
  CrmObjectDataTypeDefinition
};
import { createSlice } from '@reduxjs/toolkit';
import { convertToDashboardFilter, convertGroupOperatorsToDashboardFilters, getFilterQueryFormatOperator } from '../filter/utils/filterConversionHelpers';
import { areFiltersDataSourceAndPropertyNameSame } from './dashboardFilters/types';
import { buildWithStateSliceSelector } from './selectorUtils';
import { createSelector } from 'reselect';
import { selectAllDataSourcesByPropertyRecords } from './dataSourcePropertiesSlice';
export const initialState = {
  isPanelOpen: false,
  filters: {}
};
export const DASHBOARD_FILTERS_NAMESPACE = 'dashboardFilters';
const bulkUpdateFiltersOnRemoveFilter = (filters, filtersToRemove) => {
  const updatedFilters = filtersToRemove.reduce((acc, filterToRemove) => {
    const {
      dataSourceId,
      name
    } = filterToRemove;
    return acc.filter(appliedFilter => !(appliedFilter.dataSource === dataSourceId && appliedFilter.propertyName === name));
  }, filters);
  return updatedFilters;
};
const bulkUpdateFiltersOnAddFilter = (filters, quickFilterGroupOperators, systemGenerated, quickFilterGroupName) => {
  const quickFilterGroupDashboardFiltersToApply = convertGroupOperatorsToDashboardFilters({
    quickFilterGroupOperators,
    systemGenerated,
    quickFilterGroupName
  });
  const updatedFilters = filters.filter(currentAppliedFilter => !quickFilterGroupDashboardFiltersToApply.some(nextGroupedFilter => areFiltersDataSourceAndPropertyNameSame(nextGroupedFilter, currentAppliedFilter))).concat(quickFilterGroupDashboardFiltersToApply);
  return updatedFilters;
};
const dashboardFiltersSlice = createSlice({
  name: DASHBOARD_FILTERS_NAMESPACE,
  initialState,
  reducers: {
    initializeDashboardFilters(state, action) {
      const {
        dashboardId,
        filters
      } = action.payload;
      state.isPanelOpen = false;
      state.filters[dashboardId] = filters || [];
    },
    addDashboardFilter: {
      reducer: (state, action) => {
        const {
          dashboardId,
          newFilter
        } = action.payload;
        const newFilterArray = state.filters[dashboardId] || [];
        newFilterArray.push(newFilter);
        state.filters[dashboardId] = newFilterArray;
      },
      prepare: ({
        dashboardId,
        filterData,
        dataSource,
        property
      }) => {
        const newFilter = convertToDashboardFilter(filterData);
        return {
          payload: {
            dashboardId,
            newFilter,
            dataSource,
            property
          }
        };
      }
    },
    addGroupDashboardFilter: (state, action) => {
      const {
        payload: {
          dashboardId,
          quickFilterGroupOperators,
          systemGenerated,
          quickFilterGroupName
        }
      } = action;
      const prevFilters = state.filters[dashboardId] || [];
      state.filters[dashboardId] = bulkUpdateFiltersOnAddFilter(prevFilters, quickFilterGroupOperators, systemGenerated, quickFilterGroupName);
    },
    removeGroupDashboardFilter: (state, action) => {
      const {
        payload: {
          dashboardId,
          properties
        }
      } = action;
      const currentFilters = state.filters[dashboardId] || [];
      state.filters[dashboardId] = bulkUpdateFiltersOnRemoveFilter(currentFilters, properties);
    },
    updateDashboardFilter: {
      reducer: (state, action) => {
        const {
          dashboardId,
          newFilter,
          index
        } = action.payload;
        state.filters[dashboardId][index] = newFilter;
      },
      prepare: ({
        dashboardId,
        filterData,
        index
      }) => {
        const newFilter = convertToDashboardFilter(filterData);
        return {
          payload: {
            dashboardId,
            newFilter,
            index
          }
        };
      }
    },
    removeDashboardFilter(state, action) {
      const {
        dashboardId,
        index
      } = action.payload;
      const filters = state.filters[dashboardId];
      if (filters && filters.length) {
        filters.splice(index, 1);
      }
    },
    removeDashboardFilterByName(state, action) {
      const {
        dashboardId,
        dataSource,
        propertyName
      } = action.payload;
      state.filters[dashboardId] = (state.filters[dashboardId] || []).filter(appliedFilter => !(appliedFilter.dataSource === dataSource && appliedFilter.propertyName === propertyName));
    },
    removeCurrentDashboardFilters(state, action) {
      const {
        dashboardId
      } = action.payload;
      state.filters[dashboardId] = [];
    },
    removeAllDashboardFilters() {
      // const { dashboardId } = action.payload;
      // TODO: DELETE all dashboard filter key value pairs
    },
    openDashboardFiltersPanel(state) {
      state.isPanelOpen = true;
    },
    closeDashboardFiltersPanel(state) {
      state.isPanelOpen = false;
    },
    toggleDashboardFiltersPanel(state) {
      state.isPanelOpen = !state.isPanelOpen;
    }
  }
});
const withDashboardFiltersSliceState = buildWithStateSliceSelector(DASHBOARD_FILTERS_NAMESPACE);

// Selectors
export const selectDashboardFilters = dashboardId => withDashboardFiltersSliceState(state => {
  return state.filters[dashboardId] || [];
});
export const selectIsExistingProperty = (property, dashboardId) => state => {
  const prevFilters = selectDashboardFilters(dashboardId)(state);
  return !!prevFilters.find(filter => filter.propertyName === property.name && filter.dataSource === property.dataSource);
};
export const selectIsDashboardFilterPanelOpen = withDashboardFiltersSliceState(state => state.isPanelOpen);
export const selectDashboardFrequency = __dashboardId => withDashboardFiltersSliceState(() => undefined // TODO implement frequency overrides in platform dashboard
);
export const selectDashboardFrequencyUseFiscalYear = __dashboardId => withDashboardFiltersSliceState(() => undefined // TODO implement frequency overrides in platform dashboard
);
export const selectDashboardFilterOperator = dashboardFilter => createSelector([selectAllDataSourcesByPropertyRecords], dataSourceProperties => {
  return getFilterQueryFormatOperator(dashboardFilter, dataSourceProperties[dashboardFilter.dataSource]);
});

// TODO: add listener middleware

export const {
  initializeDashboardFilters,
  addDashboardFilter,
  updateDashboardFilter,
  removeDashboardFilter,
  removeDashboardFilterByName,
  removeCurrentDashboardFilters,
  removeAllDashboardFilters,
  openDashboardFiltersPanel,
  closeDashboardFiltersPanel,
  toggleDashboardFiltersPanel,
  addGroupDashboardFilter,
  removeGroupDashboardFilter
} = dashboardFiltersSlice.actions;
export default dashboardFiltersSlice.reducer;
import * as ChartTypes from '../constants/chartTypes';
import { ANALYTICS_FUNNEL, ANALYTICS_PAGES, ANALYTICS_PEOPLE, ATTRIBUTION_TOUCH_POINTS, ATTRIBUTION, CONTACT_CREATE_ATTRIBUTION, CRM_OBJECT, CUSTOM_DATA_TYPES, DEAL_CREATE_ATTRIBUTION, UNIFIED_DATA_TYPES, UNIFIED_EVENTS, UNIFIED_EVENTS_QL, GOAL_TARGET } from '../constants/dataTypes';
import { FUNNEL, PIPELINE } from './../constants/configTypes';
import { CUSTOM } from '../constants/chartTypes';
import { DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES } from '../constants/objectCoordinates';
import { getDataType } from '../report/reportGetters';
import { getDataTypeFromConfig, getDimensions, getMetricProperties, getObjectTypeId, getReportConfig } from '../report/configReportGetters';
import { HISTORICAL_REPORTING_METADATA_DELIMITER } from '../constants/historicalReporting';
import { isExternalDataType } from '../report/externalReportGetters';
export const ReportTypes = {
  // Elastic search based reports
  AttributionsReport: 'AttributionsReport',
  CustomWidgetReport: 'CustomWidgetReport',
  ConfigReport: 'ConfigReport',
  HistoricalReport: 'HistoricalReport',
  SingleObjectReport: 'SingleObjectReport',
  UnifiedEventsReport: 'UnifiedEventsReport',
  MultiConfigReport: 'MultiConfigReport',
  // Snowflake based reports
  RelationalReport: 'RelationalReport',
  JourneyReport: 'JourneyReport',
  // Extensible Reports
  ReportWithPlugins: 'ReportWithPlugins',
  // Unified Analytics Reports
  UnifiedAnalyticsReport: 'UnifiedAnalyticsReport'
};

// Report Base

// Snowflake Report

// Journey Report

export const JourneyReportChartTypes = [ChartTypes.COLUMN, ChartTypes.FUNNEL, ChartTypes.SANKEY, ChartTypes.TABLE];
export const isJourneyReportChartType = chartType => JourneyReportChartTypes.includes(chartType);

// Elastic Search reports

// Unified Events

// Unified Events QL/ Unified Event Search

// Deal Funnel

// Attributions

// Single Object

// Custom Widget

// Report

// Report Type Assertions
export const isRelationalReport = report => report.get('reportDefinition') !== undefined;
export const isJourneyReport = report => report.get('journeyQuery') !== undefined;
export const isCustomWidgetReport = report => {
  const customWidgetType = report.getIn(['displayParams', 'customWidget', 'type']);
  if (isRelationalReport(report)) {
    // Some relational reports has chartType set to CUSTOM
    return false;
  }
  return report.get('chartType') === CUSTOM || customWidgetType !== undefined && customWidgetType !== 'DATA_LAYER';
};
export const isReportWithPlugins = report => report.getIn(['displayParams', 'pluginName']) !== undefined;
export const isConfigReport = report => report.get('config') !== undefined;
export const isUnifiedEventsReport = report => report.getIn(['config', 'dataType']) === UNIFIED_EVENTS;
export const isUnifiedEventsQLReport = report => report.getIn(['config', 'dataType']) === UNIFIED_EVENTS_QL;
export const isUnifiedAnalyticsReport = report => UNIFIED_DATA_TYPES.includes(report.getIn(['config', 'dataType']));
export const isFunnelReport = report => {
  const configType = report.getIn(['config', 'configType']);
  return configType === FUNNEL || configType === PIPELINE;
};
export const isAttributionReport = report => {
  const dataType = report.getIn(['config', 'dataType']);
  const objectTypeId = report.getIn(['config', 'objectTypeId']);
  const dealCreateAttributionObjectType = DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES.get(DEAL_CREATE_ATTRIBUTION);
  return [ATTRIBUTION, ATTRIBUTION_TOUCH_POINTS, CONTACT_CREATE_ATTRIBUTION].includes(dataType) || dataType === CRM_OBJECT && objectTypeId === dealCreateAttributionObjectType;
};
export const isSingleObjectReport = report => isConfigReport(report) && ![...CUSTOM_DATA_TYPES, ANALYTICS_FUNNEL, ANALYTICS_PAGES, ANALYTICS_PEOPLE, ...UNIFIED_DATA_TYPES].includes(getDataType(report) || '') && !isFunnelReport(report) && !isAttributionReport(report) && !isCustomWidgetReport(report);
export const isMultiConfigReport = report => report.get('reportConfigs') !== undefined;
export const isHistoricalReport = report => {
  if (!isConfigReport(report)) {
    return false;
  }
  const hasHistoricalMetadata = property => property.includes(HISTORICAL_REPORTING_METADATA_DELIMITER);
  return getDimensions(report).some(hasHistoricalMetadata) || getMetricProperties(report).some(hasHistoricalMetadata);
};
export const isReportWithExternalDataType = report => {
  if (!isConfigReport(report)) {
    return false;
  }
  const dataType = getDataTypeFromConfig(getReportConfig(report));
  return isExternalDataType(dataType);
};
export const isBaseReport = report => isRelationalReport(report) || isConfigReport(report) || isCustomWidgetReport(report) || isJourneyReport(report) || isReportWithPlugins(report);
export const getReportType = report => {
  // Elastic search based reports
  if (isAttributionReport(report)) return ReportTypes.AttributionsReport;
  if (isCustomWidgetReport(report)) return ReportTypes.CustomWidgetReport;
  if (isSingleObjectReport(report)) return ReportTypes.SingleObjectReport;
  if (isUnifiedEventsReport(report)) return ReportTypes.UnifiedEventsReport;
  if (isRelationalReport(report)) return ReportTypes.RelationalReport;
  if (isJourneyReport(report)) return ReportTypes.JourneyReport;
  if (isReportWithPlugins(report)) return ReportTypes.ReportWithPlugins;
  if (isUnifiedAnalyticsReport(report)) return ReportTypes.UnifiedAnalyticsReport;
  return ReportTypes.ConfigReport;
};
export const isGoalReport = report => isSingleObjectReport(report) && getObjectTypeId(report) === DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES.get(GOAL_TARGET);
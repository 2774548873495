import { registerQuery, useQuery } from 'data-fetching-client';
import { httpClientWithRaven } from '../../../../clients/hubHttpWithRaven';
export const GET_DATA_ENRICHMENT_BANNER_DISPLAY_DATA = registerQuery({
  fieldName: 'dataEnrichmentBannerDisplayData',
  args: [],
  fetcher() {
    return httpClientWithRaven.get('data-enrichment-app/notifications/v2/notifications/banner/display');
  }
});
export const useGetDataEnrichmentBannerDisplayData = () => {
  const {
    data,
    loading,
    error
  } = useQuery(GET_DATA_ENRICHMENT_BANNER_DISPLAY_DATA);
  return {
    data: data === null || data === void 0 ? void 0 : data.dataEnrichmentBannerDisplayData,
    loading,
    error
  };
};
import { useQuery } from 'data-fetching-client';
import { GET_USED_IN_EMBED_DASHBOARDS } from '../embed-dashboard/embed-dashboard-dao';
import { EMBED_DASHBOARD_APP_CONFIG } from '../embed-dashboard/embed-dashboard-ids';
const USED_IN_DASHBOARD_TYPES = {
  EMBEDDED_DASHBOARD: 'EMBEDDED_DASHBOARD'
};
export const isUsedInEmbedDashboardInfo = info => info.type === USED_IN_DASHBOARD_TYPES.EMBEDDED_DASHBOARD;
export const useGetDashboardUsedIn = dashboardId => {
  const {
    loading: isLoading,
    data
  } = useQuery(GET_USED_IN_EMBED_DASHBOARDS, {
    variables: {
      dashboardId
    }
  });
  const embeddedAppIds = (data === null || data === void 0 ? void 0 : data.embeddedAppIds) || [];
  const usedInData = embeddedAppIds.map(embedDashboardAppId => {
    const config = EMBED_DASHBOARD_APP_CONFIG[embedDashboardAppId];
    return {
      type: USED_IN_DASHBOARD_TYPES.EMBEDDED_DASHBOARD,
      data: {
        embedDashboardAppId,
        link: config.getAppLink(),
        name: config.getName(),
        location: config.location
      }
    };
  });
  return {
    isLoading,
    usedInData
  };
};
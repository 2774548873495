import { List as ImmutableList } from 'immutable';
import { isBaseReport, isConfigReport, isJourneyReport, isRelationalReport, isReportWithPlugins } from '../tsTypes/reportTypes';
import { isCustomWidgetReport, isUnifiedEventsReport } from './../tsTypes/reportTypes';
import { TABLE } from '../constants/chartTypes';
import { getDataType as getDataTypeFromConfigReport, getDateRangeFilterProperty, getDimensions as getDimensionsFromConfigReport, getMetricProperties as getMetricPropertiesFromConfigReport, getReportConfig, getCustomFilterProperties as getReportCustomFilterPropertiesFromConfigReport, isReportGrouping as isReportGroupingConfigReport, isTimeSeriesReport as isTimeSeriesConfigReport } from './configReportGetters';
import { getRelationalReportVisualType } from './relationalReportGetters';
export { getDataTypeFromConfig } from './configReportGetters';
export const getName = report => {
  return isBaseReport(report) ? report.get('name') : '';
};
export const getActive = report => {
  return isBaseReport(report) ? report.get('active') : false;
};
export const isFlpRestricted = report => {
  return isBaseReport(report) ? report.get('flpRestricted') : false;
};
export const getCreatedAt = report => {
  return isBaseReport(report) ? report.get('createdAt') : undefined;
};
export const getReportPortalId = report => {
  return isBaseReport(report) ? report.get('portalId') : undefined;
};
export const getBusinessUnitId = report => {
  return isBaseReport(report) ? report.get('businessUnitId') : undefined;
};
export const hasChartType = report => !!report.getIn(['chart']) && !!report.getIn(['chart', 'chartType']);
export const getUserReportPermissionLevel = report => {
  return isBaseReport(report) ? report.get('userReportPermissionLevel') : undefined;
};
export const hasVisualization = report => {
  return !!report.get('visualization');
};
export const getDatasetInsightParams = report => {
  return isBaseReport(report) && report.get('insightParams') || undefined;
};
export const getDatasetInsightOptions = report => {
  const maybeDatasetInsightParams = getDatasetInsightParams(report);
  return maybeDatasetInsightParams && maybeDatasetInsightParams.get('insightOptions') || undefined;
};
export const getIsReportStacked = report => {
  if (isConfigReport(report)) {
    return report.getIn(['displayParams', 'stacked'], false);
  } else if (isRelationalReport(report)) {
    return report.getIn(['reportDefinition', 'visual', 'options', 'stacking'], false);
  }
  return false;
};
export const getReportStackingType = report => {
  if (isConfigReport(report)) {
    return report.getIn(['displayParams', 'stackingType'], null);
  } else if (isRelationalReport(report)) {
    return report.getIn(['visual', 'options', 'stackingType'], null);
  }
  return null;
};
export const getReportChartType = report => {
  if (hasChartType(report)) {
    return report.getIn(['chart', 'chartType']);
  }
  if (isConfigReport(report)) {
    return report.get('chartType');
  } else if (isJourneyReport(report)) {
    return report.get('chartType');
  } else if (isRelationalReport(report)) {
    return getRelationalReportVisualType(report);
  }
  return null;
};
export const isTableReport = report => {
  return getReportChartType(report) === TABLE;
};

/**
 * @deprecated - use getDataType method from configReportGetters.
 */
export const getDataType = report => isConfigReport(report) ? getDataTypeFromConfigReport(report) : undefined;

/**
 * @deprecated - use getReportDimensions method from configReportGetters.
 */
export const getReportDimensions = report => {
  if (isConfigReport(report)) {
    return getDimensionsFromConfigReport(report);
  }
  return ImmutableList([]);
};

/**
 * @deprecated - use getReportMetricProperties method from configReportGetters.
 */
export const getReportMetricProperties = report => {
  if (isConfigReport(report)) {
    return getMetricPropertiesFromConfigReport(report);
  }
  return ImmutableList([]);
};

/**
 * @deprecated - use isTimeSeriesReport method from configReportGetters.
 */
export const isTimeSeriesReport = report => {
  return isConfigReport(report) && isTimeSeriesConfigReport(report);
};

/**
 * @deprecated - use isReportGrouping method from configReportGetters.
 */
export const isReportGrouping = report => {
  return isConfigReport(report) && isReportGroupingConfigReport(report);
};
export const getReportCustomFilterProperties = report => {
  if (isConfigReport(report)) {
    return getReportCustomFilterPropertiesFromConfigReport(getReportConfig(report));
  }
  return ImmutableList([]);
};
export const getReportDateProperty = report => {
  if (isConfigReport(report)) {
    return getDateRangeFilterProperty(getReportConfig(report));
  }
  return '';
};
export const getPluginName = report => isReportWithPlugins(report) ? report.getIn(['displayParams', 'pluginName']) : undefined;
export const getCustomWidgetType = report => {
  return isCustomWidgetReport(report) ? report.getIn(['displayParams', 'customWidget', 'type']) : undefined;
};
export const isUETimeSeriesReport = report => isUnifiedEventsReport(report) && isConfigReport(report) && isTimeSeriesReport(report);
export const getReportId = report => isBaseReport(report) && report.get('id');
export const getReportDashboardId = report => isBaseReport(report) && report.get('dashboardId');
export const getReportDashboardInfo = report => isBaseReport(report) && report.get('reportDashboardInfo') || ImmutableList();
export const getReportDefinition = report => isRelationalReport(report) ? report.get('reportDefinition') : undefined;
export const getJourneyQuery = report => isJourneyReport(report) ? report.get('journeyQuery') : undefined;
export const getReportDescription = report => isBaseReport(report) ? report.get('description') : undefined;
export const getReportEditorsSchema = report => {
  return isBaseReport(report) && report.get('editors') || ImmutableList();
};
export const getReportCustomized = report => isBaseReport(report) && !!report.get('customized');
export const getReportDisplayParams = report => isBaseReport(report) ? report.get('displayParams') : undefined;
export const getTemplateKey = report => report.get('templateKey');
export const getIsFavorite = report => isBaseReport(report) && !!report.get('favorite');
import { useQuery, registerQuery } from 'data-fetching-client';
import { httpClientWithRaven } from '../clients/hubHttpWithRaven';
const GET_DELINQUENCY_STATUS = registerQuery({
  fetcher() {
    return httpClientWithRaven.get('transactions-experience/v1/customer/is-delinquent');
  },
  args: [],
  fieldName: 'delinquencyStatus'
});
export const useGetDelinquencyStatus = ({
  skip
} = {}) => {
  var _data$delinquencyStat, _data$delinquencyStat2;
  const {
    data,
    loading,
    error,
    refetch
  } = useQuery(GET_DELINQUENCY_STATUS, {
    skip,
    refetchWritePolicy: 'overwrite'
  });
  return {
    customerDelinquencyStatus: data === null || data === void 0 || (_data$delinquencyStat = data.delinquencyStatus) === null || _data$delinquencyStat === void 0 ? void 0 : _data$delinquencyStat.customerDelinquencyStatus,
    customerDelinquentInvoiceIds: data === null || data === void 0 || (_data$delinquencyStat2 = data.delinquencyStatus) === null || _data$delinquencyStat2 === void 0 ? void 0 : _data$delinquencyStat2.customerDelinquentInvoiceIds,
    loading,
    error,
    refetch
  };
};
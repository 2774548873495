'use es6';

import { ObjectTypesToIds } from 'customer-data-objects/constants/ObjectTypeIds';
import { isPropertySupportedByExternalOptions } from 'customer-data-objects/property/PropertyIdentifier';
import { isExternalOptionsProperty } from 'reference-resolvers-lite/utils/isExternalOptionsProperty';
import MissingField from 'customer-data-filters/filterQueryFormat/MissingField';
import { hasUseIsExternalOptionsProperty } from '../permissions/gates';
import { OWNER } from 'customer-data-objects/property/ExternalOptionTypes';
import * as ExternalOptionsReferenceTypes from 'customer-data-objects/externalOptions/ExternalOptionsReferenceTypes';

// This function is meant to provide a way for us to turn on certain external
// options properites as they are added. Eventually we'll be able to remove it
// once all the options we need to resolve are supported by the External Options
// Service.
export const isExternalOptionsField = (field, filterFamily) => {
  // some filters use cases do not provide filterFamily, do not use external options
  // in this case as it will fail
  // details https://hubspot.slack.com/archives/C50LV5B97/p1628177516067500
  if (!filterFamily) {
    return false;
  }
  if (MissingField.isMissingField(field)) {
    return false;
  }

  // don't use external options for hubspot_team_id since `ReferenceLiteSearchSelect` does not render team hierarchy
  if (field && field.name === 'hubspot_team_id') {
    return false;
  }

  // don't use external options when referencedObjectType is OWNER as FilterOperatorOwnerInput does not work with RRL
  if ((field === null || field === void 0 ? void 0 : field.referencedObjectType) === OWNER) {
    return false;
  }

  // don't use external options when externalOptionsReferenceType is OWNER as FilterOperatorOwnerInput does not work with RRL
  if ((field === null || field === void 0 ? void 0 : field.externalOptionsReferenceType) === ExternalOptionsReferenceTypes.OWNER) {
    return false;
  }

  // don't use external options for deal_currency_code as FilterOperatorCurrencyCodeInput is not compatible with RRL
  /**
   * @temporary Once hasUseIsExternalOptionsProperty is ungated, this check (+ corresponding field definition override) will be removed
   * @expiration 2025-02-15
   * @deprecated
   */
  if ((field === null || field === void 0 ? void 0 : field.name) === 'deal_currency_code') {
    return false;
  }

  // Some usages of the filter still use the old object type constants as a
  // filter family. reference-resolvers-lite requires objectTypeId. This just
  // normalizes the filter family to be an objectTypeId, if the filter family
  // isn't an object type it does nothing.
  const objectTypeId = ObjectTypesToIds[filterFamily] || filterFamily;
  if (hasUseIsExternalOptionsProperty()) {
    return isExternalOptionsProperty(field, objectTypeId);
  }
  return isPropertySupportedByExternalOptions({
    objectTypeId,
    property: field
  });
};
export const base64Resize = (sourceBase64, height, width, callBack) => {
  const img = document.createElement('img');
  img.setAttribute('src', sourceBase64);
  img.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = width + 20;
    canvas.height = height + 20;
    ctx === null || ctx === void 0 || ctx.drawImage(img, 0, 0, width, height);
    const newBase64 = canvas.toDataURL();
    callBack(newBase64);
  };
};
export const triggerImageDownloadInBrowser = (imageBase64, report) => {
  var _link$parentNode;
  const link = document.createElement('a');
  link.download = `${report.get('name') || 'report'}.png`;
  link.href = imageBase64;
  link.click();

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link === null || link === void 0 || (_link$parentNode = link.parentNode) === null || _link$parentNode === void 0 || _link$parentNode.removeChild(link);
};
export const resizeImageWidthWithAspectRatioWithNewHeight = (oldHeight, oldWidth, newHeight) => {
  return newHeight * oldWidth / oldHeight;
};
export const resizeImageHeightWithAspectRatioWithNewWidth = (oldHeight, oldWidth, newWidth) => {
  return newWidth * oldHeight / oldWidth;
};
import { addColumn, createMagicMeasure, getColumnLabel, getMeasures, isMagicMeasure } from './column-utils';
import { DATASET_TABLE } from '../schema/table-records';
import { Limit, LimitDirections, LimitForQuery } from '../schema/limit-records';
import { List } from 'immutable';
import { isSearchTableReport, removeUnencodedColumns } from './report-utils';
import { SortOrders } from '../schema/sort-records';
import { COLUMN_ROLES } from 'reporting-data/constants/relationalReports';
const MAGIC_MEASURE_REFERENCE = '@@MAGIC_MEASURE@@';
const createFallbackByMeasureOption = (table, reportMeta) => {
  if (table.name === DATASET_TABLE) {
    return undefined;
  }
  const defaultMeasure = createMagicMeasure(table);
  if (!defaultMeasure) {
    return undefined;
  }
  const snowflakeTable = reportMeta.tables.get(table.name);
  return {
    text: getColumnLabel(defaultMeasure, undefined, snowflakeTable),
    value: MAGIC_MEASURE_REFERENCE
  };
};
const findEquivalentDefaultLimitMeasure = (primaryTableName, columns) => {
  const isColumnDefaultMeasure = column => isMagicMeasure(column) && column.field.table === primaryTableName;
  return columns.find(isColumnDefaultMeasure);
};
const getValidByMeasureValues = report => {
  const primaryTable = report.table;
  const validMeasures = getMeasures(removeUnencodedColumns(report).columns);
  let measureAliases = validMeasures.map(measure => measure.alias).toList();
  const reportHasDefaultMeasure = !!findEquivalentDefaultLimitMeasure(primaryTable.name, validMeasures);
  if (!reportHasDefaultMeasure && primaryTable.name !== DATASET_TABLE) {
    measureAliases = measureAliases.push(MAGIC_MEASURE_REFERENCE);
  }
  return measureAliases;
};
const toMeasureOption = (measure, reportMeta, report) => {
  const {
    alias,
    field: {
      table,
      name
    }
  } = measure;
  const snowflakeTable = table && reportMeta.tables.get(table);
  const snowflakeProperty = reportMeta.properties.getIn([table, name]);
  const maybeDatasetField = table === DATASET_TABLE && reportMeta.datasetDefinition && reportMeta.datasetDefinition.fields.get(name);
  const text = getColumnLabel(measure, snowflakeProperty, snowflakeTable || undefined, maybeDatasetField || undefined, report.expressions);
  const value = alias;
  return {
    text,
    value
  };
};
export const createMeasureOptionsForDimension = (report, reportMeta) => {
  return getValidByMeasureValues(report).map(alias => {
    if (alias === MAGIC_MEASURE_REFERENCE) {
      const primaryTable = report.table;
      return createFallbackByMeasureOption(primaryTable, reportMeta);
    }
    const measure = report.columns.get(alias);
    return toMeasureOption(measure, reportMeta, report);
  }).filter(measureOption => !!measureOption).toArray();
};
const LIMIT_ALIAS_PREFIX = 'limit_';
const toLimitDependencyAlias = (dimensionAlias, measureAlias) => `${LIMIT_ALIAS_PREFIX}${dimensionAlias}_by_${measureAlias}`;
const createDependencyMeasure = (dimensionAlias, measure) => {
  const measureAlias = measure.alias;
  const alias = toLimitDependencyAlias(dimensionAlias, measureAlias);
  const fixed = List.of(dimensionAlias);
  return measure.set('alias', alias).set('fixedMeasure', true).set('fixed', fixed);
};

/**
 * @param {Limit} limit
 * @param {Map<Column>} columns
 * @param {TableDescription} tableDescription
 * @returns {Column}
 * @throws if dependency measure cannot be created
 */
const mapLimitToDependencyMeasure = (limit, columns, tableDescription) => {
  const {
    byMeasure: measureAlias,
    forDimension: dimensionAlias
  } = limit;
  const dimension = columns.get(dimensionAlias);
  if (dimension == null) {
    throw new Error('mapLimitToDependencyMeasure: found a limit with a `forDimension` referencing an unknown column! Skipping application of this limit!');
  }
  if (dimension.role !== COLUMN_ROLES.DIMENSION) {
    throw new Error('mapLimitToDependencyMeasure: found a limit with a `forDimension` referencing a non-dimension column! Skipping application of this limit!');
  }
  const measure = measureAlias === MAGIC_MEASURE_REFERENCE ? createMagicMeasure(tableDescription) : columns.get(measureAlias);
  if (measure == null) {
    throw new Error('mapLimitToDependencyMeasure: found a limit with a `byMeasure` referencing an unknown column! Skipping application of this limit!');
  }
  if (measure.role !== COLUMN_ROLES.MEASURE) {
    throw new Error('mapLimitToDependencyMeasure: found a limit with a `byMeasure` referencing a non-measure column! Skipping application of this limit!');
  }
  return createDependencyMeasure(dimensionAlias, measure);
};
const fillMagicMeasureReference = (report, column) => {
  const {
    table,
    columns
  } = report;
  const encodedDefaultMeasure = findEquivalentDefaultLimitMeasure(table.name, columns.toList());
  if (!encodedDefaultMeasure) {
    return column;
  }
  return column.setIn(['limit', 'byMeasure'], encodedDefaultMeasure.alias);
};
const autocorrectByMeasure = (report, column) => {
  const firstValue = getValidByMeasureValues(report).first();
  return column.setIn(['limit', 'byMeasure'], firstValue);
};
export const getDefaultBreakdownLimitForReport = (report, column) => {
  const firstValue = getValidByMeasureValues(report).first();
  const sortOrder = column.sort && column.sort.by === firstValue ? column.sort.order : SortOrders.DESCENDING;
  return Limit({
    direction: sortOrder === SortOrders.DESCENDING ? LimitDirections.DESCENDING : LimitDirections.ASCENDING,
    count: 50,
    byMeasure: firstValue,
    forDimension: column.alias,
    autoApplied: true
  });
};
const removeAllLimitsFromReport = report => report.update('columns', columns => columns.map(column => column.delete('limit')).toMap()).set('limitsForQuery', List());
const applyDefaultBreakdownLimit = (report, column) => {
  if (column.limit) {
    return column;
  }
  const defaultBreakdownLimit = getDefaultBreakdownLimitForReport(report, column);
  return column.set('limit', defaultBreakdownLimit);
};
const getColorEncoding = visual => {
  if (visual && visual.encodings && 'color' in visual.encodings) {
    return visual.encodings.color;
  }
  return undefined;
};
export const applyLimits = report => {
  const {
    table,
    columns: uncleanColumns,
    visual
  } = report;
  const colorEncoding = getColorEncoding(visual);

  // search tables do not support limits!
  if (isSearchTableReport(report)) {
    return removeAllLimitsFromReport(report);
  }
  const cleanedColumns = uncleanColumns.map(column => {
    if (column.limit == null) {
      if (colorEncoding && column.alias === colorEncoding.column && column.role === COLUMN_ROLES.DIMENSION) {
        return applyDefaultBreakdownLimit(report, column);
      }
      return column;
    }
    if (column.limit && column.limit.autoApplied && (!colorEncoding || column.alias !== colorEncoding.column)) {
      return column.delete('limit');
    }
    if (column.role === COLUMN_ROLES.MEASURE) {
      return column.delete('limit');
    }
    const byMeasure = column.limit.byMeasure;
    if (report.table.name === DATASET_TABLE && !uncleanColumns.has(byMeasure)) {
      return column.delete('limit');
    }
    if (byMeasure === MAGIC_MEASURE_REFERENCE) {
      return fillMagicMeasureReference(report, column);
    }
    if (uncleanColumns.has(byMeasure)) {
      return column;
    }
    return autocorrectByMeasure(report, column);
  }).toMap();

  /**
   * @type {Map<Limit, Limit>}
   */
  const limits = cleanedColumns.map(column => column.limit).filter(limit => limit != null).toMap().mapKeys((__, limit) => limit).toMap();

  /**
   * @type {Map<Limit, Column>}
   */
  const dependencyMeasures = limits.map(limit => {
    try {
      if (!limit) {
        return undefined;
      }
      return mapLimitToDependencyMeasure(limit, cleanedColumns, table);
    } catch (e) {
      console.error(e); // todo consider logging to newrelic
      return undefined;
    }
  }).filter(measure => !!measure).toMap();
  const newColumns = dependencyMeasures.reduce(addColumn, cleanedColumns);
  const limitsForQuery = dependencyMeasures.map((measure, limit) => LimitForQuery({
    direction: limit.direction,
    count: limit.count,
    byMeasure: measure.alias,
    forDimension: limit.forDimension
  })).toList();
  return report.set('columns', newColumns).set('limitsForQuery', limitsForQuery);
};
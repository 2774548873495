'use es6';

import { makeFuzzyRegExp } from 'customer-data-objects/search/FieldSearch';
import memoizeLast from 'transmute/memoizeLast';
import { stripDiacritics } from 'foundations-components/transitional/utils/Diacritics';
const memoizedMakeFuzzyRegExp = memoizeLast(makeFuzzyRegExp);
export const defaultFilterOption = (option, query) => {
  const queryStringWithNoDiacritics = stripDiacritics(query);
  const fuzzy = memoizedMakeFuzzyRegExp(queryStringWithNoDiacritics);
  const optionTextWithNoDiacritics = option.text && stripDiacritics(option.text);
  const optionHelpTextWithNoDiacritics = option.help && (
  // UISelect requires option text to be a string, but help content may be any react node
  typeof option.help === 'string' ? stripDiacritics(option.help) : undefined);
  return Boolean(optionTextWithNoDiacritics && fuzzy.test(optionTextWithNoDiacritics) || optionHelpTextWithNoDiacritics && fuzzy.test(optionHelpTextWithNoDiacritics));
};
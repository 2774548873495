import { ANALYTICS_FUNNEL } from 'reporting-data/constants/dataTypes';
import { getCustomWidgetType } from 'reporting-data/report/reportGetters';
import { getReportDataSources, isSnowflake
// @ts-expect-error migrate file to typescript
} from 'reporting-action-components/lib/report-logic';
// @ts-expect-error migrate file to typescript
import { getReportDataType } from 'reporting-action-components/data/schemas/reportSchema';
// @ts-expect-error migrate file to typescript
import Widgets from 'reporting-ui-components/components/customWidgets/constants/widgets';
// Used to identify framework data sources, which would have a data source key like "0-11"
// whereas unified data types, for example, would have a key like "FORMS_ANALYTICS"
export const objectTypeIdRegex = /\d+-\d+/;

// List of custom widgets to block from Dashboard filters
const customWidgetBlockList = [
// SALES_PERFORMANCE relies on multiple date sources not listed in the report config. Dashboard filters can
// conflict among various data-sources (https://git.hubteam.com/HubSpot/report-management-issues/issues/1727)
Widgets.SALES_PERFORMANCE];
export const excludeFromDashboardFilters = report => {
  if (isSnowflake(report)) {
    return false;
  }
  const dataType = getReportDataType(report);

  // Explicitly exclude ANALYTICS_FUNNEL custom data source.
  if (dataType === ANALYTICS_FUNNEL) {
    return true;
  }
  const customWidgetType = getCustomWidgetType(report);
  return customWidgetBlockList.includes(customWidgetType);
};
export const getFilterableReportDataSources = report => {
  const filterableReportDataSources = excludeFromDashboardFilters(report) ? [] : getReportDataSources({
    report
  });
  return filterableReportDataSources;
};
export const getReportDataSourceUsages = ({
  reports
}) => reports.reduce((dataSourceReports, report) => {
  const reportDataSources = getFilterableReportDataSources(report);
  for (const dataSource of reportDataSources) {
    const currentSource = dataSourceReports[dataSource];
    if (currentSource) currentSource.push(report);else dataSourceReports[dataSource] = [report];
  }
  return dataSourceReports;
}, {});
export const isFrameworkDataSource = dataSource => objectTypeIdRegex.test(dataSource);
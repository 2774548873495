export const SHARE_ASSET_DIALOG = 'SHARE_ASSET_DIALOG';
export const SHARE_VIA_DIALOG = 'SHARE_VIA_DIALOG';
export const EMAIL_REPORT_DIALOG = 'EMAIL_REPORT_DIALOG';
export const EMAIL_DASHBOARD_DIALOG = 'EMAIL_DASHBOARD_DIALOG';
export const MANAGE_RECURRING_EMAILS_DIALOG = 'MANAGE_RECURRING_EMAILS_DIALOG';
export const SAVE_REPORT_DIALOG = 'SAVE_REPORT_DIALOG';
export const SAVE_REPORT_UPDATES_DIALOG = 'SAVE_REPORT_UPDATES_DIALOG';
export const DELETE_REPORT_DIALOG = 'DELETE_REPORT_DIALOG';
export const CHANGE_REPORT_OWNER_DIALOG = 'CHANGE_REPORT_OWNER_DIALOG';
export const RENAME_REPORT_DIALOG = 'RENAME_REPORT_DIALOG';
export const SINGLE_REPORT_VIEWER = 'SINGLE_REPORT_VIEWER';
export const RESTORE_REPORT_DIALOG = 'RESTORE_REPORT_DIALOG';
export const MANAGE_REPORT_ACCESS_DIALOG = 'MANAGE_REPORT_ACCESS_DIALOG';
export const CREATE_DASHBOARD_DIALOG = 'CREATE_DASHBOARD_DIALOG';
export const ADD_TO_DASHBOARD_DIALOG = 'ADD_TO_DASHBOARD_DIALOG';
export const DELETE_DASHBOARD_DIALOG = 'DELETE_DASHBOARD_DIALOG';
export const DASHBOARD_SET_DEFAULT = 'DASHBOARD_SET_DEFAULT';
export const DASHBOARD_ACTION_SET_DEFAULT = 'DASHBOARD_ACTION_SET_DEFAULT';
export const SET_BUSINESS_UNIT_DIALOG = 'SET_BUSINESS_UNIT_DIALOG';
export const MOVE_REPORT_DIALOG = 'MOVE_REPORT_DIALOG';
export const EXPORT_REPORT_DIALOG = 'EXPORT_REPORT_DIALOG';
export const CUSTOMIZE_SALES_PERFORMANCE_DIALOG = 'CUSTOMIZE_SALES_PERFORMANCE_DIALOG';
export const EDIT_GOALS_DIALOG = 'EDIT_GOALS_DIALOG';
export const VIEW_DASHBOARD_DETAILS = 'VIEW_DASHBOARD_DETAILS';
export const ADD_SECTION_HEADER_TO_DASHBOARD_DIALOG = 'ADD_SECTION_HEADER_TO_DASHBOARD_DIALOG';
export const EXPORT_REPORT_AS_IMAGE_DIALOG = 'EXPORT_REPORT_AS_IMAGE_DIALOG';
import I18n from 'I18n';
import { formatDuration } from './durationFormatter';
// Reason for importing internal formatter: https://git.hubteam.com/HubSpot/I18n/pull/1064#issue-1319290
import { formatCurrency } from 'I18n/utils/internal/light/numberFormatters';
import { MILLISECONDS } from '../constants/timeUnits';
const FALLBACK = '–';
const DEFAULT_PRECISION = 2;
const withFallback = (format, coerce = Number) => (value, ...rest) => {
  const coercedValue = coerce(typeof value === 'string' ? value.replace(/,/g, '') : value);
  if ([null, undefined, ''].includes(value) ||
  // TODO remove following line - Typescript is erroring because the types
  // make the call impossible
  // @ts-expect-error legacy redundant call
  [null, undefined].includes(coercedValue) || !isFinite(coercedValue)) {
    return FALLBACK;
  }
  return format(coercedValue, ...rest);
};
const getPrecision = (value, customPrecision) => !customPrecision && Number(value) % 1 === 0 || customPrecision === 0 ? 0 : customPrecision || DEFAULT_PRECISION;
export const number = withFallback((value, options = {}) => {
  return I18n.formatNumber(value, Object.assign({
    precision: getPrecision(value, options.customPrecision),
    strip_insignificant_zeros: !options.keepInsignificantZeros
  }, options));
});

//TODO: add strip_insignificant_zeros: !keepInsignificantZeros after https://git.hubteam.com/HubSpot/I18n/issues/1152
export const percent = withFallback((value, options = {}) => I18n.formatPercentage(value, Object.assign({
  precision: getPrecision(value, options.customPrecision)
}, options)));

//TODO: add strip_insignificant_zeros: !keepInsignificantZeros after https://git.hubteam.com/HubSpot/I18n/issues/1152
export const currency = withFallback((value, options = {}) => {
  const {
    currencyCode
  } = options;
  return currencyCode ? options.customPrecision || options.customPrecision === 0 ? formatCurrency(value, Object.assign({
    precision: options.customPrecision
  }, options)) : I18n.formatCurrency(value, Object.assign({}, options)) : number(value, options);
});
export const duration = withFallback((value, {
  durationUnit = MILLISECONDS,
  smallScale = false,
  durationPrecision = 1,
  minimumDisplayUnit
} = {}) => {
  return formatDuration(I18n.moment.duration(value, durationUnit), smallScale, durationPrecision, durationUnit, minimumDisplayUnit);
});
import { useQuery, registerQuery } from 'data-fetching-client';
import { httpClientWithRaven } from '../clients/hubHttpWithRaven';
export const GET_DOES_DOCUMENT_EXIST = registerQuery({
  args: ['type', 'recordId'],
  fieldName: 'documentExistsObject',
  fetcher({
    type,
    recordId
  }) {
    return httpClientWithRaven.get(`transactions-experience/v1/documents/${type}/${recordId}/exists`);
  }
});
export const useGetDoesDocumentExist = ({
  recordId,
  type,
  skip
}) => {
  var _data$documentExistsO;
  const {
    data,
    loading,
    error
  } = useQuery(GET_DOES_DOCUMENT_EXIST, {
    skip,
    variables: {
      recordId,
      type
    }
  });
  const hasMigrationDocument = (_data$documentExistsO = data === null || data === void 0 ? void 0 : data.documentExistsObject.documentExists) !== null && _data$documentExistsO !== void 0 ? _data$documentExistsO : false;
  return {
    hasMigrationDocument,
    loading,
    error
  };
};
import { isIdle, STATUS_ERROR, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from '../Status';
import { useEffect, useRef, useState } from 'react';
import allSettled from 'hs-promise-utils/allSettled';
import promiseDone from 'hs-promise-utils/promiseDone';
import { defaultQueryOptions } from '../internals/Search';
import { toBatchObjectsNotFoundError } from '../Errors';
function isFulfilled(input) {
  return input.status === 'fulfilled';
}
export const useFetchApiResolver = (resolver, ids) => {
  const {
    api: {
      byId
    }
  } = resolver;
  const previousIdsRef = useRef(undefined);
  const isBatch = Array.isArray(ids);
  const initialStatus = ids ? STATUS_LOADING : STATUS_IDLE;
  const [results, setResults] = useState({
    error: undefined,
    references: undefined,
    status: initialStatus
  });
  if (ids && isIdle(results.status)) {
    setResults({
      error: undefined,
      references: undefined,
      status: STATUS_LOADING
    });
  }
  useEffect(() => {
    if (!ids) {
      setResults({
        error: undefined,
        references: undefined,
        status: STATUS_IDLE
      });
      previousIdsRef.current = undefined;
      return;
    }

    // Prevent the same ids from being fetched constantly, we can pretty safely
    // just toString the arrays here to compare them because they are always
    // arrays of strings.
    if (previousIdsRef.current && ids.toString() === previousIdsRef.current.toString()) {
      previousIdsRef.current = ids;
      return;
    }
    previousIdsRef.current = ids;
    if (ids.length === 0) {
      setResults({
        error: undefined,
        references: [],
        status: STATUS_SUCCESS
      });
      return;
    }
    const setData = references => setResults({
      error: undefined,
      references,
      status: STATUS_SUCCESS
    });
    const setError = error => setResults({
      error,
      references: undefined,
      status: STATUS_ERROR
    });
    promiseDone(allSettled(ids.map(id => byId(id))).then(values => {
      const fulfilledPromises = values.filter(isFulfilled).map(({
        value
      }) => value);
      if (fulfilledPromises.length === 0) {
        setError(toBatchObjectsNotFoundError(ids));
      } else {
        setData(fulfilledPromises);
      }
    }));
  }, [isBatch, ids, byId]);
  return results;
};
export const useSearchApiResolver = (resolver, queryOptions) => {
  queryOptions = Object.assign({}, defaultQueryOptions, queryOptions);
  const {
    query,
    count,
    offset
  } = queryOptions;
  const {
    api: {
      search
    }
  } = resolver;
  const initialStatus = query == null ? STATUS_IDLE : STATUS_LOADING;
  const [searchResult, setSearchResult] = useState({
    error: undefined,
    results: undefined,
    status: initialStatus
  });
  if (query != null && isIdle(searchResult.status)) {
    setSearchResult({
      error: undefined,
      results: undefined,
      status: STATUS_LOADING
    });
  }
  useEffect(() => {
    if (query == null) {
      return;
    }
    const setData = results => setSearchResult({
      error: undefined,
      results,
      status: STATUS_SUCCESS
    });
    const setError = error => setSearchResult({
      error,
      results: undefined,
      status: STATUS_ERROR
    });
    promiseDone(search({
      query,
      offset,
      count
    }).then(setData, setError));
  }, [query, offset, count, search]);
  return searchResult;
};
import I18n from 'I18n';
import PortalIdParser from 'PortalIdParser';
export const EMBED_DASHBOARD_APP_IDS = {
  KITCHEN_SINK_APP_DASHBOARD_KEY: 'KITCHEN_SINK_APP_DASHBOARD_KEY',
  CUSTOMER_SUCCESS_WORKSPACE: 'CUSTOMER_SUCCESS_WORKSPACE'
};
export const EMBED_DASHBOARD_APP_CONFIG = {
  [EMBED_DASHBOARD_APP_IDS.KITCHEN_SINK_APP_DASHBOARD_KEY]: {
    getAppLink() {
      return `/platform-dashboard-kitchen-sink/${PortalIdParser.get()}`;
    },
    getName() {
      return I18n.text('embedDashboard.apps.KITCHEN_SINK_APP_DASHBOARD_KEY.name');
    },
    location: 'workspace'
  },
  [EMBED_DASHBOARD_APP_IDS.CUSTOMER_SUCCESS_WORKSPACE]: {
    getAppLink() {
      return `/customer-success/${PortalIdParser.get()}`;
    },
    getName() {
      return I18n.text('embedDashboard.apps.CUSTOMER_SUCCESS_WORKSPACE.name');
    },
    location: 'workspace'
  }
};
export const PermissionLevels = {
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  NONE: 'NONE'
};
export const AssignmentTabTypes = {
  Teams: 'Teams',
  Users: 'Users'
};
export const ENTITY_TYPES = {
  TEAM: 'TEAM',
  USER: 'USER',
  ALL: 'ALL',
  ADMIN: 'ADMIN',
  // only for internal use in portal 53
  ACCESS_PROFILE: 'ACCESS_PROFILE'
};
const strictEquals = compareString => testString => testString === compareString;
export const AccessClassifications = {
  EVERYONE: 'EVERYONE',
  SPECIFIC: 'SPECIFIC',
  PRIVATE: 'PRIVATE'
};
export const isEveryone = strictEquals(AccessClassifications.EVERYONE);
export const isSpecific = strictEquals(AccessClassifications.SPECIFIC);
export const isPrivate = strictEquals(AccessClassifications.PRIVATE);
export const AccessClassificationKeys = Object.keys(AccessClassifications);
export const PermissionableUserSets = {
  SINGLE_USER: 'SINGLE_USER',
  TEAM: 'TEAM',
  API_ACCESS: 'API_ACCESS'
};
import events from '../../events.yaml';
import { getName, getType } from '../filter/utils/schema/propertySchema';
import { EVENTS } from '../common/usageTracker';
import { createTracker } from 'usage-tracker';
import { setPrimaryTracker } from 'usage-tracker-container';
import { REPORTING_TRACKER } from 'reporting-data/constants/usageTracker';
const getConfigOptions = () => ({
  events,
  onError: err => {
    console.log(err.message);
  }
});
export const trackFilterInteraction = appUsageTrack => ({
  action,
  filterData,
  userRole,
  additionalProps = {}
}) => {
  const {
    dataSource,
    operator,
    property
  } = filterData;
  const _property = operator ? operator.get('field') : property;
  appUsageTrack.track(EVENTS.dashboardFilterInteraction, Object.assign({
    actionType: 'explore',
    action,
    assetType: 'dashboard',
    dataTypes: [dataSource],
    property: getName(_property),
    type: getType(_property),
    operator: operator ? operator.get('name') : '',
    role: userRole
  }, additionalProps));
};
export const getDefaultTracker = () => createTracker(getConfigOptions());
const setupUsageTracker = tracker => {
  const configOptionsToOverride = getConfigOptions();
  return tracker.clone(Object.assign({}, configOptionsToOverride));
};
export const createTrackerInstace = appUsageTrack => {
  const tracker = appUsageTrack ? setupUsageTracker(appUsageTrack) : getDefaultTracker();
  try {
    setPrimaryTracker(tracker, REPORTING_TRACKER);
  } catch (_unused) {
    console.log('Primary tracker already set');
  }
  return tracker;
};
'use es6';

import { isFunction } from 'dashboard-lib/private/js-util';
import get from 'transmute/get';
export const createTrackHandler = mapOfEventTypeToTracker => event => {
  const type = get('type', event);
  const payload = get('payload', event);
  const tracker = mapOfEventTypeToTracker[type];
  if (isFunction(tracker)) {
    const shouldStop = tracker(payload);
    if (shouldStop) {
      return null;
    }
  }
  return event;
};
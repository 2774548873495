import { fromJS } from 'immutable';
import { RequestRecord, RequestMethods } from './requestTypes';
const forMethod = method => data => new RequestRecord(fromJS(data).set('method', method));
const _instance = data => {
  return new RequestRecord(fromJS(data));
};
export default class extends RequestRecord {
  static get(data) {
    return forMethod(RequestMethods.GET)(data);
  }
  static post(data) {
    return forMethod(RequestMethods.POST)(data);
  }
  static put(data) {
    return forMethod(RequestMethods.PUT)(data);
  }
  static rpc(data) {
    return forMethod(RequestMethods.POST)(data);
  }
  static instance(data) {
    return _instance(data);
  }
  static sanitize(request) {
    return _instance(JSON.parse(JSON.stringify(request.delete('transformer'))));
  }
}
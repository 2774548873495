import { useCallback } from 'react';
import { closeGrowthSidePanel, openGrowthSidePanel, isGrowthSidePanelOpen } from 'user-context/utils/growthSidePanel';
import useIsScreenWidthBelowThreshold from './useIsScreenWidthBelowThreshold';
import { IS_OPEN } from '../constants/CollaborationSidebarStateProperties';
import { clearGrowthSidePanelFlagForReopening, flagGrowthSidePanelForReopening, isGrowthSidePanelFlaggedForReopening } from '../utils/growthSidePanel';
export default function useSidebarOpenCloseHandlers({
  onUpdateCollaborationSidebarState,
  openLeftEditor,
  closeLeftEditor
}) {
  const isScreenWidthBelowThreshold = useIsScreenWidthBelowThreshold();
  const maybeCloseLeftEditor = useCallback(() => {
    if (closeLeftEditor && isScreenWidthBelowThreshold) {
      closeLeftEditor();
    }
  }, [isScreenWidthBelowThreshold, closeLeftEditor]);
  const onOpenSidebar = useCallback(() => {
    // close the Growth Side Panel and flag to reopen when closing the panel
    // should be kept in sync with collaboration-sidebar/static-1.44976/js/hooks/useHandleTabPress.ts
    if (isGrowthSidePanelOpen()) {
      closeGrowthSidePanel();
      flagGrowthSidePanelForReopening();
    }
    onUpdateCollaborationSidebarState({
      [IS_OPEN]: true
    });
    maybeCloseLeftEditor();
  }, [onUpdateCollaborationSidebarState, maybeCloseLeftEditor]);
  const handleClose = useCallback(() => {
    onUpdateCollaborationSidebarState({
      [IS_OPEN]: false
    });
    if (openLeftEditor && isScreenWidthBelowThreshold) {
      openLeftEditor();
    }
    // reopen the Growth Side Panel if it was closed when opening a content from the sidebar
    if (isGrowthSidePanelFlaggedForReopening()) {
      clearGrowthSidePanelFlagForReopening();
      openGrowthSidePanel();
    }
  }, [isScreenWidthBelowThreshold, onUpdateCollaborationSidebarState, openLeftEditor]);
  return {
    handleClose,
    onOpenSidebar,
    maybeCloseLeftEditor
  };
}
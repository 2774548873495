import { createAsyncThunk } from '@reduxjs/toolkit';
import { createDashboard } from '../../data/dashboard-dao';
import { buildCreateDashboardSucceedNotification, failedNotification } from './helpers';
import { getId, getName } from '../../filter/utils/schema/dashboardSchema';
import PortalIdParser from 'PortalIdParser';
export const createDashboardAsyncThunk = createAsyncThunk('dashboard/create', async payload => {
  const res = await createDashboard(payload);
  return res;
});
export const createDashboardAction = createDashboardRequest => async dispatch => {
  try {
    const {
      payload
    } = await dispatch(createDashboardAsyncThunk(createDashboardRequest));
    const dashboardId = getId(payload);
    const dashboardName = getName(payload);
    const path = `/reports-dashboard/${PortalIdParser.get()}/view/${dashboardId}`;
    return buildCreateDashboardSucceedNotification({
      path,
      dashboardName
    });
  } catch (e) {
    console.error('Error creating dashboard', e);
    return failedNotification;
  }
};
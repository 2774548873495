import { useQuery } from 'data-fetching-client';
import { FETCH_ALL_USERS } from '../../../data/user-dao';
import { FETCH_ALL_TEAMS, FETCH_ALL_TEAM_HIERARCHIES } from '../../../data/team-dao';
import { useMemo } from 'react';

/** Fetches connected entity deps used for dashboard permissions using DFC. Can be used outside of the PlatformDashboardProvider */
export const useConnectedEntityDeps = () => {
  const {
    data: userData,
    loading: isUsersLoading
  } = useQuery(FETCH_ALL_USERS);
  const {
    data: teamData,
    loading: isTeamsLoading
  } = useQuery(FETCH_ALL_TEAMS);
  const {
    data: teamHierarchyData,
    loading: isTeamHierarchyLoading
  } = useQuery(FETCH_ALL_TEAM_HIERARCHIES);
  const users = userData ? userData.hubUsers : [];
  const teams = teamData ? teamData.hubTeams : [];
  const teamHierarchy = teamHierarchyData ? teamHierarchyData.hubTeamHierarchies : [];
  const userMap = useMemo(() => userData ? userData.hubUsers.reduce((next, user) => {
    next[user.id] = user;
    return next;
  }, {}) : {}, [userData]);
  const teamMap = useMemo(() => teamData ? teamData.hubTeams.reduce((next, team) => {
    next[team.id] = team;
    return next;
  }, {}) : {}, [teamData]);
  return {
    users: [...users].sort((a, b) => a.email.localeCompare(b.email)),
    isUsersLoading,
    teams,
    teamHierarchy,
    isTeamsLoading: isTeamHierarchyLoading || isTeamsLoading,
    userMap,
    teamMap
  };
};
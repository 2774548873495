import { HubsSettingClient } from 'frontend-preferences-client';
import { useEffect, useState } from 'react';
import { useAuth } from 'account-and-billing-components/hooks/useAuth';
export const hubSettingsClient = HubsSettingClient.forCaller('account-and-billing-components');
export const useHubSettings = (key, defaultValue, userInfo) => {
  const {
    isSuspended
  } = useAuth(userInfo);
  const [value, setValue] = useState(defaultValue);
  const [loading, setLoading] = useState(!isSuspended);
  useEffect(() => {
    if (!isSuspended) {
      hubSettingsClient.fetch(key, defaultValue).then(setValue).catch(() => {
        /** no-op */
      }).finally(() => setLoading(false));
    }
  }, [isSuspended, key, setValue, defaultValue]);
  const updateValue = newValue => {
    hubSettingsClient.write(key, newValue).then(() => setValue(newValue)).catch(() => {
      /** no-op */
    });
  };
  return {
    value,
    updateValue,
    loading,
    skipped: isSuspended
  };
};
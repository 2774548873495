import I18n from 'I18n';
import { DATA_WELL, DONUT, PIE } from 'reporting-data/constants/chartTypes';
import { getDimensions } from 'reporting-data/report/configReportGetters';
import { getReportChartType } from 'reporting-data/report/reportGetters';
import { getIsReportValidForDimensionEditorDropdown } from '../dimension-editor-dropdown/utils';
import getMeasureSizeRanges, { MeasureSizeReasons } from '../../lib/report-utils/getMeasureSizeRanges';
export const getIsReportValidForBreakdownEditorDropdown = ({
  report
}) => {
  // Always show the breakdown editor if the report is eligible for the dimension editor
  // We will disable instead of hide it if the report is not eligible for breakdown
  return getIsReportValidForDimensionEditorDropdown({
    report
  });
};
export const getIsDropdownDisabled = report => {
  const hasNoDimension = !getDimensions(report).first();
  const {
    maxDimensions,
    reason
  } = getMeasureSizeRanges(report);
  const isBreakdownUnsupported = maxDimensions < 2;
  const invalidChartType = [DONUT, DATA_WELL, PIE].includes(getReportChartType(report) || '');
  const isDisabled = isBreakdownUnsupported || hasNoDimension || invalidChartType;
  let disabledReason = '';
  if (invalidChartType || isBreakdownUnsupported && reason === MeasureSizeReasons.ChartType) {
    disabledReason = I18n.text('report-customization-components.customization-components.breakdown-editor-dropdown.config.disabled.invalidChartType');
  } else if (isBreakdownUnsupported && reason === MeasureSizeReasons.NumMetrics) {
    disabledReason = I18n.text('settingLabels.dimension.tooltip.disabled');
  } else if (hasNoDimension) {
    disabledReason = I18n.text('report-customization-components.customization-components.breakdown-editor-dropdown.config.disabled.noDimension');
  }
  return {
    isDisabled,
    disabledReason
  };
};
export const getIsClearButtonDisabled = report => getDimensions(report).count() < 2;
import { Iterable as ImmutableIterable } from 'immutable';
export const ABM = 'ABM';
export const CUSTOM = 'CUSTOM';
export const SALES = 'SALES';
export const MARKETING = 'MARKETING';
export const WEB_ANALYTICS = 'WEB_ANALYTICS';
export const SERVICE = 'SERVICE';
export const ECOMMERCE = 'ECOMMERCE';
export const CRM = 'CRM'; // deprecated TBL-103

export const DashboardType = {
  ABM,
  CUSTOM,
  /** TODO: Likely deprecated, looks like its been removed from BE https://git.hubteam.com/HubSpot/Dashboard/blob/master/DashboardBase/src/main/java/com/hubspot/dashboard/v2/dashboards/DashboardType.java#L18 */
  SALES,
  MARKETING,
  WEB_ANALYTICS,
  SERVICE,
  ECOMMERCE,
  CRM
};

/** from  https://git.hubteam.com/HubSpot/Dashboard/blob/master/DashboardBase/src/main/java/com/hubspot/dashboard/v2/dashboards/DashboardHydrationOption.java */
export const DashboardHydrationOption = {
  USER: 'USER',
  BUSINESS_UNIT: 'BUSINESS_UNIT',
  FAVORITE: 'FAVORITE',
  PERMISSION_CONFIG: 'PERMISSION_CONFIG',
  USER_PERMISSION_LEVEL: 'USER_PERMISSION_LEVEL',
  WIDGET: 'WIDGET',
  FILTERS: 'FILTERS',
  DATA_SOURCES: 'DATA_SOURCES'
};

/**
 * Hydrated dashboard response type returned from the fetch dashboard api
 * replicated from
 * https://git.hubteam.com/HubSpot/Dashboard/blob/master/DashboardBase/src/main/java/com/hubspot/dashboard/v2/dashboards/DashboardCore.java
 * and https://git.hubteam.com/HubSpot/Dashboard/blob/master/DashboardBase/src/main/java/com/hubspot/dashboard/v2/dashboards/DashboardAndMetaDataIF.java
 * */

export const isImmutableDashboardReponse = dashboard => ImmutableIterable.isIterable(dashboard);

/** DashboardRecord type
 * omits filters and appliedFilters from the dashboard response as they are stored and managed seperately in our redux store */

export const isImmutableDashboard = dashboard => ImmutableIterable.isIterable(dashboard);
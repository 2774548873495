import { getSortedFlattenedTeamsList, getFlattenedTeamsMap, getRootLevelTeamIds } from '../../../utils/TeamUtils';
import { useMemo } from 'react';
export const useTeamsData = teams => {
  const sortedFlattenedTeams = getSortedFlattenedTeamsList(teams);
  const teamsById = useMemo(() => getFlattenedTeamsMap(teams), [teams]);
  const allTeamIds = getRootLevelTeamIds(sortedFlattenedTeams);
  const expandableTeamIds = useMemo(() => new Set(sortedFlattenedTeams.filter(team => team.childTeams.length > 0).map(team => team.id)), [sortedFlattenedTeams]);
  return {
    sortedFlattenedTeams,
    teamsById,
    allTeamIds,
    expandableTeamIds
  };
};
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["exception"],
  _excluded2 = ["exception"];
import { List, fromJS } from 'immutable';
import Raven from 'raven-js';
import { TIME_SERIES } from '../constants/configTypes';
import getErrorTypeFromException from '../lib/getErrorTypeFromException';
import { REPORTING_PERF_END_KEY, ReportingPerf } from '../lib/performance';
//@ts-expect-error Untyped import
import { replaceFunctions, stableStringify } from '../lib/stringify';
import { getDatasetInsightParams, getReportChartType, getReportDefinition, getReportDisplayParams } from '../report/reportGetters';
import { Metrics } from './Metrics';
import { REPORTING_DATA_RESOLVE, REPORTING_HTTP_REQUEST_DURATION, REPORTING_HTTP_REQUEST_INFO } from '../constants/trackingMetrics';
import { isBaseReport, isConfigReport, isJourneyReport, isRelationalReport } from '../tsTypes/reportTypes';
import { getReportConfig } from '../report/configReportGetters';
import { convertMetricDimensionPropertiesToStrings } from '../utils/metricsUtils';
// Cleans request urls to allow for grouping
const cleanRequestUrl = requestUrl => requestUrl ? requestUrl.split(/\/\d/)[0] : requestUrl;
const stringifyDisplayParams = displayParams => stableStringify(replaceFunctions(displayParams.toJS(), 'FUNCTION'), null, 2);
const deriveReportDefinitionAttributes = reportDefinition => {
  if (reportDefinition === undefined) {
    return {};
  }
  const getTableList = table => {
    const tableJoinList = (fromJS(table.get('join')) || List()).map(join => join.get('target')).flatMap(getTableList).push(table);
    return tableJoinList;
  };
  const tableList = getTableList(reportDefinition.get('table'));
  const primaryTableName = reportDefinition.getIn(['table', 'name']);
  const nonPrimaryTableNames = tableList.map(t => t.get('name')).filter(name => name !== primaryTableName);
  return {
    reportDefinition: stableStringify(reportDefinition),
    reportDefinitionVisualType: reportDefinition.getIn(['visual', 'type']),
    reportDefinitionTableCount: tableList.count(),
    reportDefinitionPrimaryTableName: reportDefinition.getIn(['table', 'name']),
    reportDefinitionNonPrimaryTableName: stableStringify(nonPrimaryTableNames)
  };
};
const isMismatchedComparison = config => config.get('configType') === TIME_SERIES && config.get('compare') && (config.get('dimensions') || List()).size > 0 && config.getIn(['dimensions', 0]) !== config.getIn(['filters', 'dateRange', 'property']);
const deriveReportConfigAttributes = config => ({
  visualization: config.get('v2'),
  configType: config.get('configType'),
  dataType: config.get('dataType'),
  objectTypeId: config.get('objectTypeId'),
  template: config.get('template'),
  dimensions: stableStringify(config.get('dimensions')),
  filters: stableStringify(config.get('filters')),
  metrics: stableStringify(config.get('metrics')),
  config: stableStringify(config),
  mismatchedDateComparison: stableStringify(isMismatchedComparison(config))
});
const deriveDisplayParamsAttributes = displayParams => displayParams !== undefined ? {
  displayParams: stringifyDisplayParams(displayParams),
  customWidget: displayParams.getIn(['customWidget', 'type'])
} : {};
const deriveDatasetInsightAttributes = datasetInsightParams => {
  if (datasetInsightParams === undefined) {
    return {};
  }
  return {
    insightParams: stableStringify(datasetInsightParams)
  };
};
const deriveReportAttributes = report => {
  if (!isBaseReport(report)) {
    return {};
  }
  const baseReportAttributes = Object.assign({
    reportName: report.get('name'),
    reportId: report.get('id'),
    templateKey: report.get('templateKey')
  }, deriveDisplayParamsAttributes(getReportDisplayParams(report)), deriveDatasetInsightAttributes(getDatasetInsightParams(report)));
  if (isRelationalReport(report)) {
    const reportDefinitionAttributes = deriveReportDefinitionAttributes(getReportDefinition(report));
    return Object.assign({}, baseReportAttributes, reportDefinitionAttributes);
  }
  if (isConfigReport(report)) {
    const reportConfigAttributes = deriveReportConfigAttributes(getReportConfig(report));
    return Object.assign({
      chartType: getReportChartType(report)
    }, baseReportAttributes, reportConfigAttributes);
  }
  if (isJourneyReport(report)) {
    return Object.assign({
      chartType: getReportChartType(report)
    }, baseReportAttributes);
  }
  return baseReportAttributes;
};
export class ReportingLogger {
  constructor() {
    this.measurePerformance = (timingKey = 'timeToResolve') => {
      let reportingPerfMarks = this.perf.getMarks().toJS();
      if (reportingPerfMarks[REPORTING_PERF_END_KEY] === undefined) {
        const measurement = this.perf.markEnd();
        reportingPerfMarks = this.perf.getMarks().toJS();
        return Object.assign({
          [timingKey]: measurement
        }, reportingPerfMarks);
      }
    };
    this.logHttpRequestMetrics = (request, requestDuration, requestSuccess, error = {}) => {
      if (Object.keys(error).length > 0) {
        this.addErrorAttributes(error);
      }
      const {
        status
      } = error;
      const {
        benignError,
        requestUrl
      } = this.attributes;
      const requestMetricInfo = convertMetricDimensionPropertiesToStrings({
        requestSuccess,
        benignError,
        status,
        requestUrl
      });
      Metrics.counter(REPORTING_HTTP_REQUEST_INFO, requestMetricInfo).increment();
      Metrics.timer(REPORTING_HTTP_REQUEST_DURATION).update(requestDuration);
    };
    this.attributes = {
      lib: 'reporting-data'
    };
    this.perf = new ReportingPerf();
    this.perf.markStart();
  }
  addAttribute(attribute, value) {
    this.attributes[attribute] = value;
  }
  addAttributes(map) {
    this.attributes = Object.assign({}, map, this.attributes);
  }
  addReportAttributes(report) {
    try {
      this.addAttributes(deriveReportAttributes(report));
    } catch (error) {
      console.error('Error thrown in addReportAttributes:', error);
    }
  }
  addReportConfigAttributes(config) {
    try {
      this.addAttributes(deriveReportConfigAttributes(config));
    } catch (error) {
      console.error('Error thrown in addReportConfigAttributes:', error);
    }
  }
  addErrorAttributes(exception) {
    const {
      message,
      responseText,
      stack,
      status
    } = exception;
    try {
      this.addAttributes({
        exception,
        reportErrorType: getErrorTypeFromException(exception),
        reportExceptionMessage: message || 'no message',
        reportExceptionResponse: responseText,
        reportExceptionStack: stack || Error().stack,
        reportExceptionStatus: status,
        benignError: [0, 401, 403, 404].includes(status)
      });
    } catch (error) {
      console.error('Error thrown in addErrorAttributes:', error);
    }
  }
  measureTiming(timingKey) {
    this.addAttributes(this.measurePerformance(timingKey));
  }
  /**
   * @param {Error?} exception - optional
   * @param {string} currentPhase
   * @param {string?} pageActionName - optional
   */
  logError(exception = {}, currentPhase, pageActionName) {
    this.addErrorAttributes(exception);
    this.addAttributes(Object.assign({}, this.measurePerformance(), {
      reportSuccess: false,
      reportErrorPhase: currentPhase
    }));
    const _this$attributes = this.attributes,
      extraAttributes = _objectWithoutPropertiesLoose(_this$attributes, _excluded);
    Raven.captureException(exception, {
      extra: Object.assign({}, extraAttributes, {
        isReportingDataInteractionError: true
      })
    });
    const {
      reportSuccess,
      benignError,
      reportErrorType
    } = this.attributes;
    const {
      status
    } = exception;
    Metrics.counter(REPORTING_DATA_RESOLVE, convertMetricDimensionPropertiesToStrings({
      reportSuccess,
      benignError,
      reportErrorType,
      status
    })).increment();
    this.sendPageAction(pageActionName);
  }
  logHttpError(err, request, requestDuration) {
    const {
      url: requestUrl
    } = request;
    const {
      status,
      data,
      message,
      options: {
        method,
        appInfo
      } = {}
    } = err;
    const {
      name
    } = appInfo || {};
    this.addAttributes({
      requestSuccess: false,
      method,
      requestUrl: cleanRequestUrl(requestUrl),
      fullRequestUrl: requestUrl,
      status,
      errorResponse: data,
      appName: name,
      errorMessage: message,
      requestDuration
    });
    const _this$attributes2 = this.attributes,
      extraAttributes = _objectWithoutPropertiesLoose(_this$attributes2, _excluded2);
    Raven.captureException(err, {
      extra: Object.assign({}, extraAttributes, {
        isReportingDataInteractionError: true
      })
    });
    this.logHttpRequestMetrics(request, requestDuration, false, err);
    this.sendPageAction('ReportingHttpRequest');
  }
  logHttpSuccess(request, requestDuration) {
    const {
      url: requestUrl
    } = request;
    this.addAttributes({
      requestSuccess: true,
      requestUrl: cleanRequestUrl(requestUrl),
      fullRequestUrl: requestUrl,
      requestDuration
    });
    this.logHttpRequestMetrics(request, requestDuration, true);
    this.sendPageAction('ReportingHttpRequest');
  }

  /**
   * @param {string?} pageActionName - optional
   */
  logSuccess(pageActionName) {
    this.addAttributes(Object.assign({}, this.measurePerformance(), {
      reportSuccess: true
    }));
    const {
      reportSuccess
    } = this.attributes;
    Metrics.counter(REPORTING_DATA_RESOLVE, convertMetricDimensionPropertiesToStrings({
      reportSuccess
    })).increment();
    this.sendPageAction(pageActionName);
  }
  sendPageAction(name = 'ReportingDataResolve') {
    Raven.capturePageEvent(name, {
      extra: this.attributes
    });
  }
}
import enviro from 'enviro';
import apiClient from 'hub-http/clients/apiClient';
import { stringify } from 'hub-http/helpers/params';
import fromJSOrdered from '../../lib/fromJSOrdered';
import { batchRequests as overrideBatchRequests } from '../../lib/overrides';
// @ts-expect-error Untyped dependency
import { NewRelicInteraction } from '../../monitoring/newrelic';
// @ts-expect-error Untyped dependency
import connectActions from '../../redux/connectActions';
// @ts-expect-error Untyped dependency
import reduxStore from '../../redux/store';
import Request from '../Request';
// @ts-expect-error Untyped dependency
import { client as batchCSClient } from './batchContactsSearch';
// @ts-expect-error Untyped dependency
import { actions, getRequestState, getState, StatusTypes } from './store';
import rpcClient from './rpcClient';
const rewriteLocalReportingUrls = request => {
  const url = request.url;
  if (url.startsWith('reporting') || url.startsWith('sql-reporting')) {
    return request.set('url', `https://local.hubspot${enviro.isQa() ? 'qa' : ''}.com/${url}`);
  }
  return request;
};
const NON_RETRIABLE_ERRORS = [401, 403, 404];
const FORM_URLENCODED = {
  'Content-Type': 'application/x-www-form-urlencoded'
};
const DEFAULT_OPTIONS = {
  bust: false,
  ttl: 30000,
  disableTtl: false,
  batchRequests: false,
  useLocalReportingApis: false,
  // throw when trying to make an actual http request
  // (for debugging regression testing)
  cacheOnly: false,
  // indicate whether the request should still cache, even if it errors
  cacheOnError: false,
  // Called when requests are made, used to prune regression calls
  retrieveHook: () => {}
};
let currentOptions = DEFAULT_OPTIONS;
export const setOptions = newOptions => currentOptions = Object.assign({}, currentOptions, newOptions);
export const getOptions = () => currentOptions;
export const resetOptions = () => currentOptions = DEFAULT_OPTIONS;
const [addRequest, removeRequest, setRequestStatus] = connectActions(reduxStore, [actions.addRequest, actions.removeRequest, actions.setRequestStatus]);
const payload = ({
  form,
  data
}) => form.isEmpty() ? {
  data: data.toJS()
} : {
  data: stringify(form.toJS()),
  headers: FORM_URLENCODED
};
const hubHttp = (request, clientOverrides) => {
  const defaultApiClient = apiClient;
  const client = Object.assign({}, defaultApiClient, clientOverrides);
  return client[request.method](request.url, Object.assign({}, payload(request), {
    query: request.query.toJS(),
    timeout: request.timeout
  })).then(fromJSOrdered);
};
export const retrieve = (rawRequest, options = {}) => {
  var _request$get;
  const {
    bust,
    ttl,
    disableTtl,
    cacheOnly,
    batchRequests,
    useLocalReportingApis,
    cacheOnError,
    retrieveHook
  } = Object.assign({}, currentOptions, options);
  const rpcDetails = rawRequest.get('rpc');
  const sanitizedRequest = Request.sanitize(rawRequest);
  const request = useLocalReportingApis ? rewriteLocalReportingUrls(sanitizedRequest) : sanitizedRequest;
  retrieveHook(request);
  if (bust && !cacheOnly) {
    removeRequest({
      request
    });
  }
  const requestState = getRequestState(reduxStore.getState(), request);
  if (requestState && (requestState.status === StatusTypes.SUCCEEDED || requestState.status === StatusTypes.PENDING || cacheOnError || !requestState.isRetriable)) {
    return requestState.promise;
  }
  const timer = (() => {
    const start = Date.now();
    return () => Date.now() - start;
  })();
  if (cacheOnly) {
    if (requestState && requestState.status === StatusTypes.ERRORED) {
      return Promise.reject(requestState.error);
    }
    throw new Error(`Cache missed for ${request}`);
  }
  const clientOverrides = batchRequests || overrideBatchRequests.enabled ? batchCSClient : undefined;
  const requestPromise = rpcDetails ? rpcClient.call(rpcDetails === null || rpcDetails === void 0 ? void 0 : rpcDetails.toJS(), (_request$get = request.get('data')) === null || _request$get === void 0 ? void 0 : _request$get.toJS()).then(result => {
    return fromJSOrdered(result);
  }) : hubHttp(request, clientOverrides);
  addRequest({
    request,
    promise: requestPromise,
    ttl
  });
  const getTtlTimeout = () => {
    return !disableTtl ? setTimeout(() => removeRequest({
      request
    }), ttl) : undefined;
  };
  const nr = new NewRelicInteraction();
  nr.addAttribute(`requestHasClientOverrides`, clientOverrides !== undefined);
  return requestPromise.then(response => {
    setRequestStatus({
      request,
      response,
      status: StatusTypes.SUCCEEDED,
      duration: timer(),
      timeout: getTtlTimeout()
    });
    nr.logHttpSuccess(request, timer());
    return response;
  }).catch(error => {
    setRequestStatus({
      request,
      error,
      status: StatusTypes.ERRORED,
      duration: timer(),
      timeout: getTtlTimeout(),
      isRetriable: !NON_RETRIABLE_ERRORS.includes(error.status)
    });
    nr.logHttpError(error, request, timer());
    throw error;
  });
};
export const get = (url, data, options) => {
  return retrieve(Request.get(Object.assign({
    url
  }, data)), options);
};
export const post = (url, data, options) => retrieve(Request.post(Object.assign({
  url
}, data)), options);
export const put = (url, data, options) => retrieve(Request.put(Object.assign({
  url
}, data)), options);

/**
 *
 * sample usage:
 * http.rpcCall(rpcSpec, {
    request: {
      objectTypeIdOrFullyQualifiedName: '0-1',
      includeFieldLevelPermission: false,
    },})
 * rpcSpec - This is usually generated by the rpc client generator cli
 */
export function rpc(rpcDetails, data, options) {
  return retrieve(Request.rpc({
    rpc: rpcDetails,
    data
  }), options);
}
export const bustWhen = condition => {
  const state = getState(reduxStore.getState());
  state.forEach((requestState, request) => {
    if (condition(requestState)) {
      removeRequest({
        request
      });
    }
  });
};
export const bustAll = () => {
  bustWhen(() => true);
};
export const bustSettled = () => {
  bustWhen(requestState => requestState.status !== StatusTypes.PENDING);
};

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
// @ts-expect-error ts-migrate(2580) FIXME: Cannot find name 'module'. Do you need to install ... Remove this comment to see the full error message
if (!!module && !!module.exports) {
  // @ts-expect-error ts-migrate(2580) FIXME: Cannot find name 'module'. Do you need to install ... Remove this comment to see the full error message
  module.exports.default = module.exports;
}
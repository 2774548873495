'use es6';

import { stripDiacritics } from 'foundations-components/transitional/utils/Diacritics';
const trim = str => str.replace(/^\s+|\s+$/g, '');
const isValid = value => {
  return typeof value !== 'undefined' && value !== null && value !== '';
};
const defaultFilterOptions = (options, filterValue, excludeOptions, props) => {
  if (props.ignoreAccents) {
    filterValue = stripDiacritics(filterValue);
  }
  if (props.ignoreCase) {
    filterValue = filterValue.toLowerCase();
  }
  if (props.trimFilter) {
    filterValue = trim(filterValue);
  }
  if (excludeOptions) excludeOptions = excludeOptions.map(i => i[props.valueKey]);
  return options.filter(option => {
    if (excludeOptions && excludeOptions.indexOf(option[props.valueKey]) > -1) return false;
    if (props.filterOption) return props.filterOption.call(undefined, option, filterValue);
    if (!filterValue) return true;
    const value = option[props.valueKey];
    const label = option[props.labelKey];
    const help = option.help;
    const hasValue = isValid(value);
    const hasLabel = isValid(label);
    const hasHelp = isValid(help);
    if (!hasValue && !hasLabel) {
      return false;
    }
    let valueTest = hasValue ? String(value) : null;
    let labelTest = hasLabel ? String(label) : null;
    let helpTest = hasHelp ? String(help) : null;
    if (props.ignoreAccents) {
      valueTest = hasValue && stripDiacritics(valueTest);
      labelTest = hasLabel && stripDiacritics(labelTest);
      helpTest = hasHelp && stripDiacritics(helpTest);
    }
    if (props.ignoreCase) {
      valueTest = hasValue && valueTest.toLowerCase();
      labelTest = hasLabel && labelTest.toLowerCase();
      helpTest = hasHelp && helpTest.toLowerCase();
    }
    return props.matchPos === 'start' ? valueTest && valueTest.substr(0, filterValue.length) === filterValue || labelTest && labelTest.substr(0, filterValue.length) === filterValue || helpTest && helpTest.substr(0, filterValue.length) === filterValue : valueTest && valueTest.indexOf(filterValue) >= 0 || labelTest && labelTest.indexOf(filterValue) >= 0 || helpTest && helpTest.indexOf(filterValue) >= 0;
  });
};
export default defaultFilterOptions;
// custom
export const CONVERSION = 'CONVERSION';
export const ATTRIBUTION = 'ATTRIBUTION';
export const EMAIL = 'EMAIL';
export const KNOWLEDGE_ARTICLES = 'KNOWLEDGE_ARTICLES';
export const EXPERIMENTAL_REVENUE = 'EXPERIMENTAL_REVENUE';
export const QUOTAS = 'QUOTAS';
export const CUSTOM_DATA_TYPES = [CONVERSION, ATTRIBUTION, EMAIL, KNOWLEDGE_ARTICLES, EXPERIMENTAL_REVENUE, QUOTAS];

// analytics (non-unified)
export const ANALYTICS_FUNNEL = 'ANALYTICS_FUNNEL';
export const ANALYTICS_PAGES = 'ANALYTICS_PAGES';
export const ANALYTICS_PEOPLE = 'ANALYTICS_PEOPLE';
export * from './dataTypes/inboundDb';
export * from './dataTypes/unified';
export * from './dataTypes/unifiedEvents';

// deprecated
export const DOCUMENTS = 'DOCUMENTS';
export const SEQUENCES = 'SEQUENCES';
export const TEMPLATES = 'TEMPLATES';
export const WEB_ANALYTICS = 'WEB_ANALYTICS';
export const BLOG = 'BLOG';
export const BLOG_POSTS = 'BLOG_POSTS';
export const LEGACY_CAMPAIGNS = 'LEGACY_CAMPAIGNS';
export const ANALYTICS_CAMPAIGNS = 'ANALYTICS_CAMPAIGNS';

// custom objects
export const CRM_OBJECT = 'CRM_OBJECT';
export const OBJECT_LIST = 'OBJECT_LIST';

// Extensible reports
export const EXTERNAL = 'EXTERNAL';
'use es6';

import unescapedText from 'I18n/utils/unescapedText';
import { fromJS, List, Map as ImmutableMap } from 'immutable';
import { DEALS } from '../../constants/dataTypes';
import { DEFAULT_NULL_VALUES } from '../../constants/defaultNullValues';
import dealModule from '../../dataTypeDefinitions/inboundDb/deals';
import prefix from '../../lib/prefix';
import { Promise } from '../../lib/promise';
import { slug } from '../../lib/slug';
import { generatePipelineStageLabel } from '../../references/pipelineStage/index';
import overridePropertyTypes from '../../retrieve/inboundDb/common/overridePropertyTypes';
import getRemoteProperties from '../../retrieve/inboundDb/common/properties';
import { get as getPipelines } from '../../retrieve/inboundDb/pipelines';
import createPropertiesGetterFromGroups from '../createPropertiesGetterFromGroups';
import { mergeProperties } from '../mergeProperties';
import conversionProperty from '../partial/conversion-property';
import countProperty from '../partial/count-property';
import getQuotasProperties from '../partial/quotas';
import memoize from 'transmute/memoize';
import { ENUMERATION } from '../../constants/property-types';
import { userInfo } from '../../request/user-info';
import I18n from 'I18n';
const hasNewScpScope = 'crm-scp-deals-access';
const translate = prefix('reporting-data.properties.deals');
const unescapeTranslate = (key, options = {}) => unescapedText(`reporting-data.properties.deals.${key}`, options);
const translateGroup = prefix('reporting-data.groups.deals');
const translateCommon = prefix('reporting-data.properties.common');
const booleanOptions = () => [{
  value: 'YES',
  label: translateCommon('buckets.included')
}, {
  value: 'NO',
  label: translateCommon('buckets.excluded')
}];
export const getDealCreateStage = () => ({
  value: 'create',
  label: translate('stage.create'),
  displayOrder: -1
});
const getDealStageOptions = () => {
  return getPipelines(DEALS).then(pipelines => {
    return pipelines.reduce((options, {
      label,
      stages
    }) => [/* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
    ...options, ...stages.map(stage => ({
      label: generatePipelineStageLabel(fromJS({
        label: stage.label,
        pipelineLabel: label
      }), stage.stageId),
      displayOrder: stage.displayOrder,
      value: stage.stageId,
      metadata: stage.metadata
    }))], [getDealCreateStage()]);
  });
};
const createEnteredProperty = stageId => `dealstages.${slug(stageId)}_entered`;
const createEnteredCountProperty = stageId => `BUCKET_${createEnteredProperty(stageId)}_enteredCount`;
const getStageDurationProperties = (stages, showLegacyDealSCPLabel = false) => {
  return stages.reduce((acc, {
    label,
    metadata
  }) => {
    if (!metadata) {
      return acc;
    }
    const {
      dateEnteredStagePropertyName,
      timeInStagePropertyName,
      dateExitedStagePropertyName
    } = metadata;
    return Object.assign({}, acc, {
      [dateEnteredStagePropertyName]: {
        name: dateEnteredStagePropertyName,
        label: unescapeTranslate(showLegacyDealSCPLabel ? 'legacy_hs_date_entered' : 'hs_date_entered', {
          stageName: label
        })
      },
      [dateExitedStagePropertyName]: {
        name: dateExitedStagePropertyName,
        label: unescapeTranslate(showLegacyDealSCPLabel ? 'legacy_hs_date_exited' : 'hs_date_exited', {
          stageName: label
        })
      },
      [timeInStagePropertyName]: {
        name: timeInStagePropertyName,
        label: unescapeTranslate(showLegacyDealSCPLabel ? 'legacy_hs_time_in' : 'hs_time_in', {
          stageName: label
        })
      }
    });
  }, {});
};
const getScriptedPropertyGroup = () => {
  return fromJS({
    name: 'dealscripted',
    displayName: translateGroup('dealscripted'),
    displayOrder: 0,
    hubspotDefined: true,
    properties: [{
      name: 'BUCKET_createdate_enteredCount',
      property: 'createdate',
      groupName: 'dealscripted',
      scripted: true,
      label: translate('BUCKET_createdate_enteredCount'),
      type: 'buckets',
      blocklistedForFiltering: true,
      options: booleanOptions()
    }, {
      name: 'BUCKET_dealProgress',
      property: 'deal.probability',
      groupName: 'dealscripted',
      scripted: true,
      label: translate('BUCKET_dealProgress'),
      blocklistedForFiltering: true,
      type: 'buckets',
      options: [{
        value: 'WON',
        label: translate('dealprogress.won')
      }, {
        value: 'OPEN',
        label: translate('dealprogress.open')
      }, {
        value: 'LOST',
        label: translate('dealprogress.lost')
      }]
    }, {
      name: 'dealstage.probability',
      groupName: 'dealscripted',
      scripted: true,
      label: translate('dealstageProbability'),
      type: 'number',
      defaultNullValue: -1,
      options: [{
        value: 'WON',
        label: translate('dealprogress.won')
      }, {
        value: 'OPEN',
        label: translate('dealprogress.open')
      }, {
        value: 'LOST',
        label: translate('dealprogress.lost')
      }]
    }]
  });
};
const getDealStagePropertyGroup = (hasNewScpProperties = false) => {
  return getPipelines(DEALS).then(fromJS).then(pipelines => {
    const enteredStageDates = pipelines.map(pipeline => pipeline.get('stages').map(stage => {
      const stageId = stage.get('stageId');
      const stageLabel = stage.get('label');
      const legacyDateEnteredProperty = ImmutableMap({
        name: createEnteredProperty(stageId),
        type: 'datetime',
        label: generatePipelineStageLabel(fromJS({
          label: unescapeTranslate(hasNewScpProperties ? 'legacyEnteredStage' : 'enteredStage', {
            stageLabel
          }),
          pipelineLabel: pipeline.get('label')
        }), stageId),
        groupName: 'dealstages',
        scripted: true,
        stageId,
        pipeline
      });
      const dateEnteredPropertiesList = List([ImmutableMap({
        name: createEnteredCountProperty(stageId),
        property: createEnteredProperty(stageId),
        type: 'buckets',
        // Single label/property used here - this is reporting created/owned and only the property used to calculate this will be migrated
        label: unescapeTranslate('hasEnteredStage', {
          stageLabel
        }),
        groupName: 'dealstages',
        scripted: true,
        stageId,
        pipeline,
        options: fromJS(booleanOptions()),
        buckets: [{
          name: 'YES',
          operator: 'GT',
          value: 0
        }, {
          name: 'NO'
        }]
      })]);
      return hasNewScpProperties ? dateEnteredPropertiesList : dateEnteredPropertiesList.unshift(legacyDateEnteredProperty);
    })).flatten(2);
    const timeInStageDates = pipelines.map(pipeline => pipeline.get('stages').map(stage => {
      const stageId = stage.get('stageId');
      const stageLabel = stage.get('label');
      return ImmutableMap({
        name: `dealstages.${slug(stageId)}_duration`,
        durationUnit: 'milliseconds',
        label: generatePipelineStageLabel(fromJS({
          label: unescapeTranslate(hasNewScpProperties ? 'legacyTimeInStage' : 'timeInStage', {
            stageLabel
          }),
          pipelineLabel: pipeline.get('label')
        }), stageId),
        description: translate('timeInStageDescriptions'),
        groupName: 'dealstages',
        type: 'duration',
        scripted: true,
        stageId,
        pipeline
      });
    })).flatten(1);
    return ImmutableMap({
      name: 'dealstages',
      displayName: translateGroup('dealstages'),
      displayOrder: 0,
      hubspotDefined: true,
      properties: List([ImmutableMap({
        name: 'dealstages.*_duration',
        groupName: 'dealstages',
        scripted: true,
        // Single label/property used here - this is reporting created/owned and only the property used to calculate this will be migrated
        label: I18n.text('reporting-data.pipelinestages.timeInAllStages'),
        description: translate('timeInStageDescriptions'),
        type: 'duration',
        durationUnit: 'milliseconds',
        blocklistedForFiltering: true
      }), ...(hasNewScpProperties ? [] : timeInStageDates), ...enteredStageDates])
    });
  });
};
export const getPropertyGroups = memoize(() => {
  return userInfo().then(userInfoResult => {
    const {
      gates,
      user: {
        scopes = []
      }
    } = userInfoResult;
    const hasNewScpProperties = scopes.includes(hasNewScpScope);
    return Promise.all([getRemoteProperties(DEALS), getDealStagePropertyGroup(hasNewScpProperties), getDealStageOptions()]).then(([remoteGroups, dealStagePropertyGroup, dealStageOptions]) => {
      const isUngatedForDealListMembership = gates.includes('crm:segments:cobjectlists');
      return mergeProperties(List([...remoteGroups, getScriptedPropertyGroup(), dealStagePropertyGroup]), 'dealinformation', Object.assign({}, isUngatedForDealListMembership ? {
        'ilsListMemberships.listId': {
          name: 'ilsListMemberships.listId',
          label: translate('listMembership'),
          type: ENUMERATION,
          defaultNullValue: DEFAULT_NULL_VALUES.NUMBER,
          reportingOverwrittenNumericType: true,
          scripted: true,
          externalOptions: true
        }
      } : {}, {
        'associations.company': {
          name: 'associations.company',
          label: translateCommon('associatedCompanies'),
          type: 'enumeration'
        },
        'associations.contact': {
          name: 'associations.contact',
          label: translateCommon('associatedContacts'),
          type: 'enumeration'
        },
        dealstage: {
          options: dealStageOptions
        },
        amount: {
          name: 'amount',
          type: 'currency',
          label: translate('amount')
        },
        hubspot_team_id: {
          referencedObjectType: 'TEAM'
        },
        hs_closed_amount: {
          name: 'hs_closed_amount',
          type: 'currency',
          label: translate('hs_closed_amount'),
          hidden: false
        },
        hs_projected_amount: {
          name: 'hs_projected_amount',
          type: 'currency',
          label: translate('hs_projected_amount'),
          hidden: false
        },
        amount_in_home_currency: {
          name: 'amount_in_home_currency',
          type: 'currency',
          label: translate('amount_in_home_currency')
        },
        projectedAmount: {
          name: 'projectedAmount',
          type: 'currency',
          label: translate('projectedAmount'),
          scripted: true,
          hidden: true
        },
        closedAmount: {
          name: 'closedAmount',
          type: 'currency',
          label: translate('closedAmount'),
          scripted: true,
          hidden: true
        },
        closedAmountInHomeCurrency: {
          name: 'closedAmountInHomeCurrency',
          type: 'currency',
          label: translate('closedAmountInHomeCurrency'),
          scripted: true,
          hidden: true
        },
        projectedAmountInHomeCurrency: {
          name: 'projectedAmountInHomeCurrency',
          type: 'currency',
          label: translate('projectedAmountInHomeCurrency'),
          scripted: true,
          hidden: true
        },
        hs_projected_amount_in_home_currency: {
          name: 'hs_projected_amount_in_home_currency',
          type: 'currency',
          label: translate('hs_projected_amount_in_home_currency'),
          hidden: false
        },
        hs_closed_amount_in_home_currency: {
          name: 'hs_closed_amount_in_home_currency',
          type: 'currency',
          label: translate('hs_closed_amount_in_home_currency'),
          hidden: false
        },
        '_inbounddbio.importid_': {
          name: '_inbounddbio.importid_',
          label: translateCommon('inboundDbImport'),
          type: 'enumeration',
          hidden: false,
          blocklistedForAggregation: true
        },
        hs_deal_stage_probability: {
          type: 'percent'
        },
        hs_created_by_user_id: {
          type: 'enumeration',
          externalOptions: true,
          referencedObjectType: 'USER',
          externalOptionsReferenceType: 'USER',
          defaultNullValue: DEFAULT_NULL_VALUES.NUMBER,
          reportingOverwrittenNumericType: true
        }
      }, hasNewScpProperties ? [] : getStageDurationProperties(dealStageOptions, hasNewScpProperties)));
    });
  });
});
export const getProperties = memoize(() => {
  return Promise.all([createPropertiesGetterFromGroups(getPropertyGroups)(), getDealStageOptions()]).then(([properties, stages]) => properties.merge(countProperty(DEALS)).merge(conversionProperty()).merge(getQuotasProperties()).merge(fromJS({
    dealId: {
      name: 'dealId',
      label: translate('deal'),
      type: 'string'
    },
    'funnel.dealstage': {
      name: 'funnel.dealstage',
      label: translate('dealStage'),
      type: 'enumeration',
      options: stages,
      blocklistedForGrouping: true
    },
    'pipeline.dealstage': {
      name: 'pipeline.dealstage',
      label: translate('dealStage'),
      type: 'enumeration',
      options: stages,
      blocklistedForGrouping: true
    },
    snapshotDate: {
      name: 'snapshotDate',
      type: 'date',
      hidden: true,
      blocklistedForAggregation: true,
      blocklistedForFiltering: true
    }
  }))).then(overridePropertyTypes(dealModule.getInboundSpec()));
});
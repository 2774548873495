import { userInfo } from '../../request/user-info';
import { formatPropertiesForValidation, fetchPropertiesFromRaasApi, fetchPropertyGroupsFromRaasApi, validatePropertiesViaRaasAPI, validatePropertyGroupsViaRaasAPI, fetchPropertiesToValidate, fetchAlreadyValidatedProperties } from './private/utils';
import { convertDataTypeToObjectTypeId, isObjectTypeId } from '../../crmObjects/tsUtils';
import { ENGAGEMENT } from '../../constants/dataTypes';
// @ts-expect-error untyped dependency
import { ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS } from '../../crmObjects/utils';
import { debug } from '../../lib/debug';
import { Metrics } from '../../monitoring/Metrics';
import { REPORTING_PROPERTY_METADATA_VALIDATION_FAILURE } from '../../constants/trackingMetrics';
const ValidateAllPropertiesGate = 'RaaS:ValidateAllProperties';
const UseBEPropertiesExceptEngagement = 'RaaS:UseBEPropertiesExceptEngagement';
export const isPropertyProvidedByRaasMetadataStore = objectTypeId => {
  return fetchAlreadyValidatedProperties().then(validatedProperties => {
    return validatedProperties.includes(objectTypeId);
  });
};
export const validateProperties = (objectTypeId, validationDataType, feProperties, beProperties, locale, langEnabled, isPropertyGroups) => {
  if (isPropertyGroups) {
    return validatePropertyGroupsViaRaasAPI(objectTypeId, validationDataType, feProperties, beProperties, locale, langEnabled);
  }
  return validatePropertiesViaRaasAPI(objectTypeId, validationDataType, formatPropertiesForValidation(feProperties), formatPropertiesForValidation(beProperties), locale, langEnabled);
};
const shouldValidateProperties = (objectTypeId, userinfo) => {
  const {
    gates
  } = userinfo;
  const skipValidationUseFeProps = {
    validateProps: false,
    skipValidationUseBeProps: false
  };
  const skipValidationUseBeProps = {
    validateProps: false,
    skipValidationUseBeProps: true
  };
  const validateProperty = {
    validateProps: true,
    skipValidationUseBeProps: false
  };
  const isEngagementObject = objectTypeId === ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS.get(ENGAGEMENT);
  const validateOnlyEngagementProperties = gates.includes(UseBEPropertiesExceptEngagement);
  if (validateOnlyEngagementProperties) {
    if (isEngagementObject) {
      return Promise.resolve(validateProperty);
    }
    return Promise.resolve(skipValidationUseBeProps);
  }
  const validateAllProperties = gates.includes(ValidateAllPropertiesGate);
  if (validateAllProperties) {
    return Promise.resolve(validateProperty);
  }
  return fetchPropertiesToValidate().then(propertiesToValidate => ({
    validateProps: propertiesToValidate.includes(objectTypeId),
    skipValidationUseBeProps: false,
    useBeIgnoreValidationResults: isEngagementObject
  })).catch(() => skipValidationUseFeProps);
};
const fetchAndValidateProperties = (dataType, fetchPropertiesPromiseFE, isPropertyGroups) => {
  const validationDataType = isObjectTypeId(dataType) ? 'CRM_OBJECT' : dataType;
  const objectTypeId = convertDataTypeToObjectTypeId(dataType);
  return userInfo().then(userinfo => shouldValidateProperties(objectTypeId, userinfo).then(({
    validateProps,
    skipValidationUseBeProps,
    useBeIgnoreValidationResults
  }) => {
    const fetchPropertiesOrGroupsFromRaaSApi = isPropertyGroups ? fetchPropertyGroupsFromRaasApi : fetchPropertiesFromRaasApi;
    const fetchPropertiesPromiseBE = validateProps || skipValidationUseBeProps ? fetchPropertiesOrGroupsFromRaaSApi(objectTypeId) : Promise.resolve();
    return Promise.all([fetchPropertiesPromiseFE, fetchPropertiesPromiseBE]).then(([feProperties, beProperties]) => {
      // @ts-expect-error issue with debug, but this is temporary logging only
      debug.once('validateProperties', 'FE Property', isPropertyGroups ? 'Groups:' : 'Lists:', feProperties);
      // @ts-expect-error issue with debug, but this is temporary logging only
      debug.once('validateProperties', 'BE Properties', isPropertyGroups ? 'Groups:' : 'Lists:', beProperties);
      if (skipValidationUseBeProps) {
        // @ts-expect-error issue with debug, but this is temporary logging only
        debug.log('skipping validation, using fe properties', 'Using be provided properties due to skipValidationUseBeProps override');
        return beProperties;
      }
      if (!validateProps) {
        // @ts-expect-error issue with debug, but this is temporary logging only
        debug.log('skipping validation, using fe properties', 'Using fe provided properties, validation not run');
        return feProperties;
      }
      const {
        user: {
          locale,
          lang_enabled
        }
      } = userinfo;
      const validationResult = validateProperties(objectTypeId, validationDataType, feProperties, beProperties, locale, lang_enabled, isPropertyGroups);
      if (useBeIgnoreValidationResults) {
        // @ts-expect-error issue with debug, but this is temporary logging only
        debug.log('running validation but not waiting for the results, using be properties', 'Using be provided properties due to skipValidationUseBeProps override');
        return beProperties;
      }
      return validationResult.then(bePropertiesValid => {
        // @ts-expect-error issue with debug, but this is temporary logging only
        debug.log('validateProperties', 'Properties used for', isPropertyGroups ? 'Groups:' : 'Lists:', bePropertiesValid ? 'beProperties' : 'feProperties');
        return bePropertiesValid ? beProperties : feProperties;
      }).catch(err => {
        Metrics.counter(REPORTING_PROPERTY_METADATA_VALIDATION_FAILURE, {
          dataType,
          status: err === null || err === void 0 ? void 0 : err.status
        }).increment();
        // @ts-expect-error issue with debug, but this is temporary logging only
        debug.log('validateProperties', 'Error in validation, feProperties used');
        return feProperties;
      });
    });
  }));
};
export const getAndValidateSingleObjectProperties = (dataType, fetchPropertiesPromiseFE) => fetchAndValidateProperties(dataType, fetchPropertiesPromiseFE, false);
export const getAndValidatePropertyGroups = (dataType, fetchPropertiesPromiseFE) => fetchAndValidateProperties(dataType, fetchPropertiesPromiseFE, true);
export const __TESTABLE__ = {
  ValidateAllPropertiesGate,
  UseBEPropertiesExceptEngagement
};
import http from 'hub-http/clients/apiClient';
const SOCIAL_CHANNEL_URL = 'broadcast/v2/accounts/with-channels';
const getSocialChannelsOptions = () => {
  const channelOptions = http.get(SOCIAL_CHANNEL_URL, {
    allChannels: true
  }).then(res => {
    const readableChannels = res.readableChannelKeys;
    const channels = res.channels;
    const readableChannelKeys = readableChannels.map(channel => channel.split(':')[1]);
    return readableChannelKeys.map(key => {
      var _channels$find;
      return {
        value: key,
        label: (_channels$find = channels.find(c => c.channelId === key)) === null || _channels$find === void 0 ? void 0 : _channels$find.name
      };
    });
  }).catch(() => {
    return Promise.resolve([]);
  });
  return channelOptions;
};
export default getSocialChannelsOptions;
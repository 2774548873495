import { List } from 'immutable';
import http from 'hub-http/clients/apiClient';
import { SnowflakeProperty } from '../schema/source-records';
import { ListFilterRef } from '../schema/filter-records';
import * as ObjectTypes from './objectTypes';
import { hasObjectTypeId } from '../utils/source-utils';
export const IlsMigrationStatus = {
  MIGRATED: 'MIGRATED'
};
export const getPortalIlsMigrationStatus = () => http.get('/sql-reporting/v1/report-builder/list-seg/migration-status');
export const InboundDbListMembershipSnowflakeProperty = SnowflakeProperty({
  name: 'ilsListMemberships.listId',
  label: 'List membership',
  type: 'number',
  groupName: 'listmembership',
  rollupProperty: false,
  rollupPropertyObjectTypeIds: [],
  metaDefinition: {},
  flpRestricted: false
});
export const getListFilterRefsForTable = (snowflakeTable, showDeal, showCustomObject, showTicketListFilter, showCartAndOrderListFilter) => {
  if (!hasObjectTypeId(snowflakeTable)) {
    return List();
  }
  const isCustomObject = snowflakeTable.objectTypeId.startsWith('2-');
  if (isCustomObject) {
    return List.of(ListFilterRef({
      table: snowflakeTable.name
    }));
  }
  switch (snowflakeTable.objectTypeId) {
    case ObjectTypes.CONTACT:
    case ObjectTypes.COMPANY:
      return List.of(ListFilterRef({
        table: snowflakeTable.name
      }));
    case ObjectTypes.TICKET:
      return List.of(ListFilterRef({
        table: snowflakeTable.name
      }));
    case ObjectTypes.CART:
    case ObjectTypes.ORDER:
      {
        return List.of(ListFilterRef({
          table: snowflakeTable.name
        }));
      }
    case ObjectTypes.DEAL:
      {
        if (!showDeal) {
          return List();
        }
        return List.of(ListFilterRef({
          table: snowflakeTable.name
        }));
      }
    case ObjectTypes.PARTNER_CLIENT:
      {
        return List.of(ListFilterRef({
          table: snowflakeTable.name
        }));
      }
    default:
      return List();
  }
};
import { useCallback } from 'react';
/**
 * Returns a function that accepts an input event and sends the value to another
 * function.
 * @param func: child callback function
 * @returns {function} callback function
 */
export const useEventCallback = func => {
  return useCallback(e => {
    const value = e.target.value;
    func(value);
  }, [func]);
};
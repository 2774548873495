import { gate } from 'hub-http/gates';
export const SUBSCRIPTION_ANALYTICS_GATE = 'RA:SubscriptionAnalytics';
export const LEAD_OBJECT_GATE = 'LeadTracking:GeneralAccess';
export const EMAIL_REPORT_GATE = 'RA:ProspectingEmailTab';
export const MARKETING_ANALYTICS_DEV_GATE = 'RL:MarketingAnalyticsDev';
export const MARKETING_ANALYTICS_PRIVATE_BETA_GATE = 'RL:MarketingAnalyticsPrivateBeta';
export const ALL_SUBDOMAIN_PROCESSING = 'Analytics:AllSubdomainProcessing';
export const DEAL_STAGE_PROPERTIES_BANNER_GATE = 'RaaS:ShowLegacyDealLifecyclePropertyMigrationBanner';
export const PIPELINE_SNAPSHOT_JIT_GATE = 'RA:HistoricalSnapshotsMigration';
export const PIPELINE_SNAPSHOT_RM_GATE = 'RM:FE:Migration:HistoricalSnapshotMigration';
export const DEAL_PUSH_RATE_RM_GATE = 'RM:FE:Migration:DealPushRateMigration';
export const AI_INSIGHTS_COLLECTIONS = 'RE:AIInsightsCollections';
export const IMPROVED_RECURRING_REVENUE_GATE = gate('RA:ImprovedRecurringRevenue');
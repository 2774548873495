import { AREA, BAR, COLUMN, LINE } from 'reporting-data/constants/chartTypes';
import { ACCUMULATE } from 'reporting-data/constants/processorTypes';
import { getReportProcessors, isTimeSeriesReport } from 'reporting-data/report/configReportGetters';
import { setProcessorType } from 'reporting-data/report/configReportSetters';
import { getReportChartType } from 'reporting-data/report/reportGetters';
import { isConfigReport } from 'reporting-data/tsTypes/reportTypes';
import I18n from 'I18n';
export const getIsReportValidForCumulativeCheckbox = ({
  report
}) => {
  return isConfigReport(report);
};
export const getIsCumulativeConfigReport = report => {
  return getReportProcessors(report).includes(ACCUMULATE);
};
export const cumulativeSetterForConfigReportCheckbox = report => {
  return setProcessorType(report, ACCUMULATE);
};
export function isCumulativeCheckboxDisabled(report) {
  const isInvalidChartType = ![LINE, AREA, BAR, COLUMN].includes(getReportChartType(report) || '');
  const isDisabled = isInvalidChartType || !isTimeSeriesReport(report);
  let disabledReason = '';
  if (isDisabled) {
    // This message cover both scenarios
    disabledReason = I18n.text('report-customization-components.customization-components.cumulative-checkbox.config.tooltip');
  }
  return {
    isDisabled,
    disabledReason
  };
}
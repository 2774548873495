import { getUserId } from '../../../utils/UserUtils';
import { AccessClassifications, PermissionLevels, PermissionableUserSets } from '../Constants';
const {
  EVERYONE,
  PRIVATE
} = AccessClassifications;
const {
  SINGLE_USER,
  API_ACCESS
} = PermissionableUserSets;
const {
  EDIT
} = PermissionLevels;
const buildInitialAssignedEntities = initialStateValue => {
  const entities = {};
  initialStateValue.forEach(entity => {
    switch (entity.accessClassification) {
      case PRIVATE:
        {
          const currentUserId = getUserId();
          entities[currentUserId] = {
            type: SINGLE_USER,
            level: entity.permissionLevel
          };
          break;
        }
      case EVERYONE:
        {
          entities[EVERYONE] = {
            type: API_ACCESS,
            level: entity.permissionLevel
          };
          break;
        }
      default:
        {
          entity.permissionGrants.forEach(grant => {
            if (grant.userSetId !== undefined) {
              entities[grant.userSetId] = {
                type: grant.userSetType,
                level: grant.permissionLevel
              };
            }
          });
          break;
        }
    }
  });
  return entities;
};
export const buildInitialPermissionsConfig = initialStateValue => {
  const accessClassification = initialStateValue ? initialStateValue[0].accessClassification : EVERYONE;
  const permissionLevel = initialStateValue ? initialStateValue[0].permissionLevel : EDIT;
  return {
    assignedEntities: initialStateValue ? buildInitialAssignedEntities(initialStateValue) : {},
    accessClassification,
    permissionLevel
  };
};
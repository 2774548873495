import { registerQuery, useQuery } from 'data-fetching-client';
import { httpClientWithRaven } from '../../../../clients/hubHttpWithRaven';
export const GET_DATA_ENRICHMENT_BANNER_OVERAGE_DATA = registerQuery({
  fieldName: 'dataEnrichmentBannerOverageData',
  args: [],
  fetcher() {
    return httpClientWithRaven.get('data-enrichment-app/notifications/v2/notifications/banner/overage');
  }
});
export const useGetDataEnrichmentBannerOverageData = ({
  skip
}) => {
  const {
    data,
    loading,
    error
  } = useQuery(GET_DATA_ENRICHMENT_BANNER_OVERAGE_DATA, {
    skip
  });
  return {
    data: data === null || data === void 0 ? void 0 : data.dataEnrichmentBannerOverageData,
    loading,
    error
  };
};
import I18n from 'I18n';
import { useCallback, useMemo } from 'react';
import PortalIdParser from 'PortalIdParser';
import { OWNER, TEAM, USER
// @ts-expect-error Untyped dependency
} from 'reference-resolvers/constants/ReferenceObjectTypes';
import { getReferenceTypeFromProperty } from 'reference-resolvers-lite/utils/getReferenceTypeFromProperty';
import { ENUMERATION } from 'reporting-data/constants/property-types';
// @ts-expect-error Untyped dependency
import { get as isUnifiedDataType } from 'reporting-data/retrieve/unified/supported';
import { IN } from 'reporting-data/constants/operators';
import { CUSTOM_WIDGET_TYPES } from 'reporting-data/tsTypes/customWidgetTypes';
import { MY_OWNER_ID, MY_TEAM_ID, MY_USER_ID } from 'reporting-data/constants/magicTypes';
// @ts-expect-error Untyped dependency
import { unifiedPropertyResolvers } from '../UnifiedFilterEditor/utils';
// @ts-expect-error Untyped dependency
import { OBJECT_COORDINATES } from 'customer-data-filters/filterQueryFormat/UnifiedEventPropertyTypes';
import { getObjectCoordinateParts } from 'reporting-data/crmObjects/tsUtils';
export const getValueFieldForSchema = schema => schema.operator === IN ? 'values' : 'value';
export const isFilterApplied = filterValue => filterValue && (!Array.isArray(filterValue) || filterValue.length > 0);
export const getReferenceType = (dataType, property) => {
  if (!property) {
    return null;
  }
  if (isUnifiedDataType(dataType)) {
    const unifiedAnalyticsResolvers = unifiedPropertyResolvers[dataType] || {};
    return unifiedAnalyticsResolvers[property.name];
  }
  return property.referencedObjectType || property.externalOptionsReferenceType || getReferenceTypeFromProperty(property, dataType);
};
export const isValidForQuickFiltering = (dataType, property) => {
  if (!property) {
    return false;
  }
  const referenceType = getReferenceType(dataType, property);
  const hasOptions = property.options !== undefined && property.options.length > 0;
  return [ENUMERATION, OBJECT_COORDINATES].includes(property.type) && (hasOptions || referenceType);
};
export const SALES_QUOTA_NO_PIPELINE_VALUE = '@@NO_PIPELINE@@';
export const isSalesQuotaPipelineFilter = (property, customWidgetType) => customWidgetType === CUSTOM_WIDGET_TYPES.SALES_QUOTA && property === 'pipeline';
export const getSpecialOptionsForReferenceType = referenceType => {
  switch (referenceType) {
    case USER:
      return [{
        value: MY_USER_ID,
        text: I18n.text('reporting-ui-components.specialFilterTokens.me')
      }];
    case OWNER:
      return [{
        value: MY_OWNER_ID,
        text: I18n.text('reporting-ui-components.specialFilterTokens.me')
      }];
    case TEAM:
      return [{
        value: MY_TEAM_ID,
        text: I18n.text('reporting-ui-components.specialFilterTokens.myTeam')
      }];
    default:
      return [];
  }
};
const getValueFromObjectCoordinate = objectCoordinateValue => getObjectCoordinateParts(objectCoordinateValue).ObjectId;
const makeValueFromObjectCoordinate = (value, objectTypeId) => `${PortalIdParser.get()}-${objectTypeId}-${value}`;
export const getObjectTypeId = (dataType, filterProperty) => {
  // TODO: This is invalid. object_coordinates properties won't resolve correctly
  // with XO, as their values encode both object type and instance id information.
  // I'm leaving this code here because it was added for a purpose, but it really
  // should be cleaned up sooner rather than later.
  if (filterProperty.type === 'object_coordinates') {
    return filterProperty.externalOptionsReferenceType;
  }
  // SOCIAL_POSTS is a custom legacy datatype that doesn't correspond to an object type.
  if (dataType === 'SOCIAL_POSTS') {
    return 'SOCIAL_POST';
  }
  return dataType;
};
export const useObjectCoordinateSafeValues = (filterProperty, rawValue, onChange, isMultiSelect) => {
  const isObjectCoordinates = useMemo(() => filterProperty.type === 'object_coordinates', [filterProperty]);
  const getValue = useCallback(rawInputValue => isMultiSelect ? rawInputValue.map(getValueFromObjectCoordinate) : getValueFromObjectCoordinate(rawInputValue), [isMultiSelect]);
  const makeValue = useCallback(rawChangeValue => isMultiSelect ? rawChangeValue.map(val => makeValueFromObjectCoordinate(val, filterProperty.externalOptionsReferenceType)) : makeValueFromObjectCoordinate(rawChangeValue, filterProperty.externalOptionsReferenceType), [filterProperty, isMultiSelect]);
  const value = useMemo(() => isObjectCoordinates ? rawValue && getValue(rawValue) : rawValue, [rawValue, isObjectCoordinates, getValue]);
  const handleChange = useMemo(() => isObjectCoordinates ? newValue => onChange(newValue && makeValue(newValue)) : onChange, [onChange, isObjectCoordinates, makeValue]);
  return {
    handleChange,
    value
  };
};
export const createUserToTeamMemberships = (users, teams) => {
  const membershipsInitialState = users.reduce((acc, user) => {
    acc[user.id] = {
      primaryTeam: null,
      secondaryTeams: []
    };
    return acc;
  }, {});
  const processTeam = (memberships, team) => {
    const {
      id,
      name,
      userIds,
      secondaryUserIds
    } = team;
    for (const userId of userIds) {
      if (memberships[userId]) {
        memberships[userId].primaryTeam = {
          id,
          name
        };
      }
    }
    for (const userId of secondaryUserIds) {
      if (memberships[userId]) {
        memberships[userId].secondaryTeams.push({
          id,
          name
        });
      }
    }
    return memberships;
  };
  return teams.reduce((memberships, team) => processTeam(memberships, team), membershipsInitialState);
};
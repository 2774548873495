// Access Problems
export const LACKS_SALES_ANALYTICS_ACCESS = 'LACKS_SALES_ANALYTICS_ACCESS';
export const LACKS_ATTRIBUTION_REPORTING = 'LACKS_ATTRIBUTION_REPORTING';
export const LACKS_CUSTOM_OBJECT_ACCESS = 'LACKS_CUSTOM_OBJECT_ACCESS';
export const LACKS_CUSTOM_REPORTING = 'LACKS_CUSTOM_REPORTING';
export const LACKS_DATASETS_ACCESS = 'LACKS_DATASETS_ACCESS';
export const LACKS_DATASETS_READ = 'LACKS_DATASETS_READ';
export const LACKS_MARKETING_JOURNEY_REPORTING = 'LACKS_MARKETING_JOURNEY_REPORTING';
export const LACKS_SALES_JOURNEY_REPORTING = 'LACKS_SALES_JOURNEY_REPORTING';
export const LACKS_ENTERPRISE_ATTRIBUTION_REPORTING = 'LACKS_ENTERPRISE_ATTRIBUTION_REPORTING';
export const LACKS_PRO_ATTRIBUTION_REPORTING = 'LACKS_PRO_ATTRIBUTION_REPORTING';
export const DASHBOARD_PERMISSION_DENIED = 'DASHBOARD_PERMISSION_DENIED';
export const INSUFFICIENT_ASSET_PERMISSION_LEVEL = 'INSUFFICIENT_ASSET_PERMISSION_LEVEL';
export const INSUFFICIENT_REPORTING_PERMISSION_LEVEL = 'INSUFFICIENT_REPORTING_PERMISSION_LEVEL';

// Dashboard Related Problems
export const IS_READ_ONLY_DEMO_DASHBOARD = 'IS_READ_ONLY_DEMO_DASHBOARD';
export const NO_DASHBOARD_SELECTED = 'NO_DASHBOARD_SELECTED';
export const REPORT_ALREADY_ON_DASHBOARD = 'REPORT_ALREADY_ON_DASHBOARD';
export const REPORT_IS_NOT_STANDALONE = 'REPORT_IS_NOT_STANDALONE';

// Data Fetching Problems
export const DASHBOARD_IS_LOADING = 'DASHBOARD_IS_LOADING';
export const DATA_FETCHING_ERROR = 'DATA_FETCHING_ERROR';
export const LOADING = 'LOADING';

// Limit Problems
export const CUSTOM_REPORT_LIMIT_EXCEEDED = 'CUSTOM_REPORT_LIMIT_EXCEEDED';
export const WILL_EXCEED_CUSTOM_REPORT_LIMIT = 'WILL_EXCEED_CUSTOM_REPORT_LIMIT';
export const WILL_EXCEED_DASHBOARD_LIMIT = 'WILL_EXCEED_DASHBOARD_LIMIT';
export const WILL_EXCEED_DASHBOARD_MAX_WIDGETS = 'WILL_EXCEED_DASHBOARD_MAX_WIDGETS';
export const WILL_EXCEED_STANDARD_REPORT_LIMIT = 'WILL_EXCEED_STANDARD_REPORT_LIMIT';
export const DASHBOARD_LIMIT_EXCEEDED = 'DASHBOARD_LIMIT_EXCEEDED';
export const FAVORITE_ASSET_LIMIT_REACHED = 'FAVORITE_ASSET_LIMIT_REACHED';

// Export Action Specific Problems
export const LACKS_EXPORT_ACCESS = 'LACKS_EXPORT_REPORT_ACCESS';

// Data Type Problem
export const UNSUPPORTED_REPORT_TYPE = 'UNSUPPORTED_REPORT_TYPE';
export const UNSUPPORTED_REPORT_GROUPING = 'UNSUPPORTED_REPORT_GROUPING';

// Field level permissions problem
export const LACKS_FIELD_ACCESS = 'LACKS_FIELD_ACCESS';
export const LACKS_SNOWFLAKE_TEMPLATE_ACCESS = 'LACKS_SNOWFLAKE_TEMPLATE_ACCESS';
export const LACKS_PAYMENTS_REPORTING_ACCESS = 'LACKS_PAYMENTS_REPORTING_ACCESS';
export const IS_MULTI_CONFIG_REPORT = 'IS_MULTI_CONFIG_REPORT';
module.exports = {
  "usageInteraction": {
    "name": "usage interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "click ctu alert button",
          "click threshold alert cta",
          "click threshold alert pricing cta",
          "click breeze intelligence credits usage exceeded alert cta",
          "hover breeze intelligence credits updated time tooltip"
        ]
      }
    },
    "namespace": "account-and-billing-ui"
  },
  "usageEnrichmentInteraction": {
    "name": "usage enrichment interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "click ctu alert button",
          "click threshold alert cta"
        ]
      }
    },
    "namespace": "account-and-billing-ui"
  },
  "transactionsInteraction": {
    "name": "transactions interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "make a payment banner",
          "pay all invoices",
          "view invoice",
          "pay now"
        ]
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "account-and-billing-ui"
  }
};
import { Map as ImmutableMap } from 'immutable';
let cache = ImmutableMap();

/**
 * Rethrow error with logging
 *
 * @param {Error|mixed} error Error or caught value
 * @returns {mixed} Caught value
 * @throws Original error type of Error
 */
const rethrow = error => {
  if (!cache.has(error)) {
    cache = cache.set(error, true);
    console.error(error);
  }
  if (error instanceof Error) {
    throw error;
  }
  return error;
};
rethrow.bust = () => {
  cache = ImmutableMap();
};
export default rethrow;
'use es6';

import { OrderedSet, List } from 'immutable';
import get from 'transmute/get';
import getIn from 'transmute/getIn';
import { defaultProperties } from 'reporting-data/config/drilldown/defaultProperties';
import { SEARCH, PIPELINE, FUNNEL } from 'reporting-data/constants/configTypes';
import { DEALS, EXTERNAL } from 'reporting-data/constants/dataTypes';
import * as inboundDbDataTypes from 'reporting-data/constants/dataTypes/inboundDb';
import * as PEOPLE_TYPES from 'reporting-data/constants/peopleTypes';
import { isSupportedCrmObject } from 'reporting-data/crmObjects/utils';
import { SourceTypes, TableDescription } from 'reporting-snowflake/relational/schema/table-records';
import { getTableList } from 'reporting-snowflake/relational/utils/table-utils';
import { SALES_ANALYTICS_WIDGETS } from '../../constants/salesAnalytics';
import { WATERFALL_DRILLDOWN_FIELDS } from '../../constants/waterfall';
export const DEFAULT_FIELDS = [...defaultProperties[DEALS], 'hubspot_owner_id', 'hubspot_team_id'];
const ORDERED_FIELDS = ['dealname', 'closedate', 'snapshot_period_closedate', 'initialCloseDate', 'finalCloseDate', 'mostRecentPushInitialCloseDate', 'mostRecentPushFinalCloseDate', 'dealstage', 'snapshot_period_dealstage', 'finalPipelineStage', 'pipeline', 'amount_in_home_currency', 'snapshot_period_amount_in_home_currency', 'initialAmountInHomeCurrency', 'finalAmountInHomeCurrency', 'amountInHomeCurrencyChange', 'hs_manual_forecast_category', 'hs_analytics_source', 'closedWonReason', 'closedLostReason', 'numberOfPushes', 'createdate', 'hubspot_owner_id', 'hubspot_team_id'];
const EXCLUDED_FIELDS = {
  DEAL_PUSH_ANALYSIS: ['amount_in_home_currency'],
  WATERFALL: ['hubspot_owner_id']
};
export const getBucketField = report => report.getIn(['config', 'dimensions', 0]);
export const getBreakdownField = report => report.getIn(['config', 'dimensions', 1]);
const getFilterValueByField = (report, field) => {
  const fieldFilter = report.getIn(['config', 'filters', 'custom']).find(filter => filter.get('property') === field);
  return fieldFilter && fieldFilter.get('value');
};
const GET_ADDITIONAL_FIELDS = {
  SALES_QUOTA: report => {
    const bucketField = getBucketField(report);
    return bucketField ? [bucketField] : [];
  },
  PIPELINE_SNAPSHOTS: () => ['snapshot_period_closedate', 'snapshot_period_dealstage', 'snapshot_period_amount_in_home_currency', 'createdate'],
  DEAL_PUSH_ANALYSIS: report => {
    const bucketField = getBucketField(report);
    const breakdownField = getBreakdownField(report);
    if (getFilterValueByField(report, breakdownField) === 'PUSHED') {
      return [bucketField, 'numberOfPushes', 'mostRecentPushInitialCloseDate', 'mostRecentPushFinalCloseDate'];
    }
    return [bucketField];
  },
  WATERFALL: report => {
    const bucketField = getBucketField(report);
    const bucket = getFilterValueByField(report, bucketField);
    return (WATERFALL_DRILLDOWN_FIELDS[bucket] || []).concat(['createdate']);
  },
  CHANGE_HISTORY_DEAL_LIST: () => ['hs_manual_forecast_category']
};
export const DATA_LAYER = 'DATA_LAYER';
export const getId = get('id');
export const getName = get('name');
export const getDescription = get('description');
export const getOwnerId = get('ownerId');
export const getReportDashboardInfo = report => getIn(['reportDashboardInfo'], report) || List();
export const getReportOwnerUser = get('reportOwnerUser');
export const getUpdatedByUser = get('updatedByUser');
export const getUpdatedAt = get('updatedAt');
export const getBusinessUnitId = get('businessUnitId');
export const getFavorite = get('favorite');
export const getConfigCustomized = getIn(['config', 'customized']); //TODO: remove when the customized field is removed from config
export const getConfigType = getIn(['config', 'configType']);
export const getCustomized = get('customized');
export const getReportDefinition = get('reportDefinition');
export const getJourneyQuery = get('journeyQuery');
export const getJourneyObjectTypeId = getIn(['journeyQuery', 'objectQuery', 'table', 'objectTypeId']);
export const getJourneyTableName = getIn(['journeyQuery', 'objectQuery', 'table', 'name']);
export const getReportDataType = getIn(['config', 'dataType']);
export const getObjectTypeId = getIn(['config', 'objectTypeId']);
export const getCustomWidgetType = report => getIn(['displayParams', 'customWidget', 'type'], report) || DATA_LAYER;
export const getTemplateKey = report => get('template', report) || get('templateKey', report) || getIn(['config', 'template'], report) || undefined;
export const getChartType = get('chartType');
export const getSnowflakeVisualType = getIn(['reportDefinition', 'visual', 'type']);
export const getAiGeneratedDescription = get('aiGeneratedDescription');
export const getUserReportPermissionLevel = get('userReportPermissionLevel');
export const getConfig = get('config');
export const getNonReportWidgetConfig = getIn(['nonReportWidget', 'widgetConfig']);
export const getRestrictedFieldLevelProperties = report => report.get('hiddenProperties') || [];
export const getSnowflakeReportTableDescription = report => {
  const table = report.getIn(['reportDefinition', 'table']).toJS();
  const tableDescription = TableDescription(table);
  return tableDescription;
};

/**
 * @deprecated
 * Collects a list of objectTypeIds from a table description.
 * If a table doesn't have an `objectTypeId`, it will be omitted from the result.
 *
 * Note:
 * * `objectTypeId` is no longer guaranteed to be present for every table description.
 * * for example: EVENT_DIGEST table types does not have an objectTypeId
 * * instead: data sources should be referenced using `dataSourceId` using `getDataSourceId`
 */
const getObjectTypeIds = tableDescription => {
  return getTableList(tableDescription).map(table => table.objectTypeId).filter(objectTypeId => !!objectTypeId);
};
export const getSnowflakeReportDataSources = report => {
  const tableDescription = getSnowflakeReportTableDescription(report);
  if (tableDescription.type === SourceTypes.HUBSPOT_DATASET) {
    //TODO: may need to parse the dataset somehow (fetch more data?) to get the data types
    return [];
  }
  return getObjectTypeIds(tableDescription).toJS();
};
export const isSalesAnalyticsWidget = report => {
  const customWidgetType = report.getIn(['displayParams', 'customWidget', 'type']);
  // Being a bit defensive here checking if customWidgetType is truthy because
  // if somehow an undefined value got into the SALES_ANALYTICS_WIDGETS list,
  // we'd return true for all non-customWidget reports.
  return customWidgetType && customWidgetType in SALES_ANALYTICS_WIDGETS;
};
export const isSalesAnalyticsPipelineSnapshotWidget = report => {
  const customWidgetType = report.getIn(['displayParams', 'customWidget', 'type']);
  return customWidgetType && customWidgetType === SALES_ANALYTICS_WIDGETS.PIPELINE_SNAPSHOTS;
};

// Checks to determine report type eligibility for export.
// Inbound DB reports have their own data sources (similar to snowflake
// reports).
export const isInboundDbReport = report => {
  const dataType = getReportDataType(report);
  return inboundDbDataTypes[dataType] || isSupportedCrmObject(dataType);
};
export const isExternalDataTypeReport = report => getReportDataType(report) === EXTERNAL;
export const isSearch = report => report.getIn(['config', 'configType']) === SEARCH;
export const isPipeline = report => report.getIn(['config', 'configType']) === PIPELINE;
export const isFunnel = report => report.getIn(['config', 'configType']) === FUNNEL;
export const getDrilldownFields = report => {
  if (!isSearch(report)) {
    return [];
  }
  const widgetType = getCustomWidgetType(report);
  const additionalFields = GET_ADDITIONAL_FIELDS[widgetType] ? GET_ADDITIONAL_FIELDS[widgetType](report) : [];
  const excludedFields = EXCLUDED_FIELDS[widgetType] || [];
  return OrderedSet(ORDERED_FIELDS).intersect(OrderedSet([...DEFAULT_FIELDS, ...additionalFields])).subtract(OrderedSet(excludedFields)).toArray();
};
export const isValidUnifiedReport = report => {
  if (report.getIn(['config', 'dimensions', 0]) === 'people') {
    const custom = report.getIn(['config', 'filters', 'custom']);
    const peopleType = custom && custom.find(filter => filter.get('property') === 'peopleType');
    return peopleType && Object.values(PEOPLE_TYPES).includes(peopleType.get('value'));
  }
  return true;
};
import { List } from 'immutable';
import * as checked from 'reporting-data/lib/checked';
import { Encoding } from '../column-records';
import { Scale, ColorOption } from '../highcharts-settings';
import { legendPosition, legendWidth } from '../highcharts-settings';
import { VisualTypes } from '../constants/visual-constants';
export const GaugeEncodings = checked.record({
  single_value: Encoding.optional(),
  compare: Encoding.optional()
}, 'GaugeEncodings');
export const GaugeBandRecord = checked.record({
  from: checked.number().optional(),
  to: checked.number().optional(),
  label: checked.string().defaultValue(''),
  color: checked.string().optional(),
  thickness: checked.number().optional()
}, 'GaugeBand');
export const GaugeReferenceLine = checked.record({
  value: checked.number().optional(),
  label: checked.string().optional()
});
export const GaugeVisualOptions = checked.record({
  showRangeLabels: checked.boolean().defaultValue(true),
  range: Scale.defaultValue({}),
  bands: checked.list(GaugeBandRecord).defaultValue(List([])),
  legendPosition,
  legendWidth,
  showPercentChangeComparison: checked.boolean().defaultValue(false),
  color: ColorOption.defaultValue({}),
  referenceLineValues: checked.list(GaugeReferenceLine).defaultValue(List([]))
}, 'GaugeVisualOptions');
export const GaugeVisual = checked.record({
  type: checked.string().always(VisualTypes.GAUGE),
  encodings: GaugeEncodings,
  options: GaugeVisualOptions.defaultValue({})
}, 'GaugeVisual');
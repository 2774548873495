import { isConfigReport, isCustomWidgetReport, isUnifiedAnalyticsReport, isUnifiedEventsQLReport, isUnifiedEventsReport, isRelationalReport, isJourneyReport } from './../tsTypes/reportTypes';
import { getDataType, getFilterGroups, getReportConfig } from '../report/configReportGetters';
import { CRM_SEARCH_FILTERS_V2, CRM_SEARCH_FILTERS_V2_UA_REPORTS, CRM_SEARCH_FILTERS_V2_UEQL_REPORTS, JOURNEY_REPORT_FILTERS_BAR, EXTENSIBLE_REPORT_FILTERS_BAR } from '../constants/gates';
import { getCustomWidgetType } from '../report/reportGetters';
import { CUSTOM_WIDGET_TYPES } from '../tsTypes/customWidgetTypes';
import { DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES } from '../constants/objectCoordinates';
import { isExternalDataType } from './../report/externalReportGetters';
import { ENGAGEMENT } from '../constants/dataTypes';
export const areFilterGroupsSupported = (report, userInfo) => {
  if (!isConfigReport(report)) {
    return false;
  }

  // Reports with existing filter groups should enable the filtersV2 experience
  const filterGroups = getFilterGroups(getReportConfig(report));
  if (filterGroups && filterGroups.size > 0) {
    return true;
  }
  if (!(userInfo !== null && userInfo !== void 0 && userInfo.gates.includes(CRM_SEARCH_FILTERS_V2))) {
    // Main gate to enable filtersV2
    return false;
  }
  const customWidgetType = getCustomWidgetType(report);
  if (customWidgetType === CUSTOM_WIDGET_TYPES.SALES_PERFORMANCE) {
    // SalesPerformanceFilterEditor has a custom list of properties and operators that isn't yet supported
    return false;
  } else if (report.hasIn(['displayParams', 'salesAnalytics', 'subAppKey']) && (isCustomWidgetReport(report) || ['timeInDealStage', 'ticketTimeInPipeline'].includes(report.getIn(['displayParams', 'salesAnalytics', 'subAppKey'])))) {
    // Sales reporting uses SalesAnalyticsEditor for their filters/editors and not all of the filters are supported yet
    return false;
  } else if (isUnifiedAnalyticsReport(report)) {
    // Enable filtersV2 for Unified Analytics reports when ungated
    // Migrate Blog Editor to Functional Component
    return userInfo === null || userInfo === void 0 ? void 0 : userInfo.gates.includes(CRM_SEARCH_FILTERS_V2_UA_REPORTS);
  } else if (isUnifiedEventsQLReport(report)) {
    return userInfo === null || userInfo === void 0 ? void 0 : userInfo.gates.includes(CRM_SEARCH_FILTERS_V2_UEQL_REPORTS);
  }
  const dataType = getDataType(report);
  if (isExternalDataType(dataType)) {
    return userInfo === null || userInfo === void 0 ? void 0 : userInfo.gates.includes(EXTENSIBLE_REPORT_FILTERS_BAR);
  }
  if (!isUnifiedEventsReport(report)) {
    // UE reports dataType does not resolve to ObjectTypeId
    if (!(dataType !== null && dataType !== void 0 && dataType.match(/^\d-\d+$/) ||
    // dataType is in ObjectTypeId format
    DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES.get(dataType) || dataType === ENGAGEMENT) // Can be converted to ObjectTypeId
    ) {
      return false;
    }
  }
  return userInfo === null || userInfo === void 0 ? void 0 : userInfo.gates.includes(CRM_SEARCH_FILTERS_V2);
};
export const isRelationalReportFiltersBarSupported = (report, userInfo) => {
  if (!isRelationalReport(report)) {
    return false;
  }
  return userInfo === null || userInfo === void 0 ? void 0 : userInfo.gates.includes(CRM_SEARCH_FILTERS_V2);
};
export const isJourneyReportFiltersBarSupported = (report, userInfo) => {
  if (!isJourneyReport(report)) {
    return false;
  }
  return (userInfo === null || userInfo === void 0 ? void 0 : userInfo.gates.includes(CRM_SEARCH_FILTERS_V2)) && (userInfo === null || userInfo === void 0 ? void 0 : userInfo.gates.includes(JOURNEY_REPORT_FILTERS_BAR));
};
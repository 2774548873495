import { useEffect, useState } from 'react';
import { checkWithinSevenDays } from '../utils/renewalDate';
import { localStorage } from '../utils/localStorage';
export const useHasLocalStorageDismissal = (featureLimitName, subscriptionRenewalDate) => {
  const localStorageKey = `res0010-${featureLimitName}-dismissed`;
  const [dismissalTimeLimit, setDismissalTimeLimit] = useState(localStorage.getItem(localStorageKey));
  const setDismissInLocalStorage = newDismissalTimeLimit => {
    localStorage.setItem(localStorageKey, newDismissalTimeLimit);
    setDismissalTimeLimit(newDismissalTimeLimit);
  };
  useEffect(() => {
    if (checkWithinSevenDays(new Date(subscriptionRenewalDate)) && dismissalTimeLimit !== '7days') {
      localStorage.removeItem(localStorageKey);
      setDismissalTimeLimit(null);
    }
  }, [dismissalTimeLimit, subscriptionRenewalDate, localStorageKey]);
  return {
    dismissInLocalStorage: dismissalTimeLimit,
    setDismissInLocalStorage
  };
};
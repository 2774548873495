module.exports = {
  "updateFavoriteAssets": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "add",
          "remove"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ],
        "isOptional": false
      },
      "location": {
        "type": [
          "single-asset-view",
          "asset-list-page"
        ],
        "isOptional": false
      }
    },
    "namespace": "reporting"
  },
  "clickSetBusinessUnit": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "tag"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ],
        "isOptional": false
      },
      "count": {
        "type": "number",
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "single-asset-view",
          "asset-list-page"
        ],
        "isOptional": false
      },
      "interactionWith": [
        "asset-action"
      ]
    },
    "namespace": "reporting"
  },
  "setBusinessUnit": {
    "name": "ANALYTICS_INTERACTION",
    "class": "usage",
    "properties": {
      "actionType": {
        "type": [
          "save"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "tag"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ],
        "isOptional": false
      },
      "count": {
        "type": "number",
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "single-asset-view",
          "asset-list-page"
        ],
        "isOptional": false
      },
      "interactionWith": [
        "modal"
      ]
    },
    "namespace": "reporting"
  },
  "clickCreateDashboardFromTemplate": {
    "name": "DASHBOARD_LIBRARY_INTERACTION",
    "class": "interaction",
    "properties": {
      "templateName": "string"
    },
    "namespace": "reporting"
  },
  "clickCreateBlankDashboard": {
    "name": "DASHBOARD_LIBRARY_INTERACTION",
    "class": "interaction",
    "namespace": "reporting"
  },
  "createBlankDashboard": {
    "name": "DASHBOARD_LIBRARY_INTERACTION",
    "class": "interaction",
    "namespace": "reporting"
  },
  "createDashboardFromTemplate": {
    "name": "DASHBOARD_LIBRARY_INTERACTION",
    "class": "interaction",
    "properties": {
      "templateName": "string",
      "widgetNames": "array"
    },
    "namespace": "reporting"
  },
  "clickViewAssetDetails": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "action": {
        "type": [
          "edit"
        ],
        "isOptional": false
      },
      "assetType": {
        "type": [
          "dashboard",
          "report"
        ],
        "isOptional": false
      },
      "interactionSource": {
        "type": [
          "single-asset-view",
          "asset-list-page"
        ],
        "isOptional": false
      },
      "interactionWith": [
        "asset-action"
      ]
    },
    "namespace": "reporting"
  },
  "saveDashboardAccess": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "whichFlow": {
        "type": "string"
      },
      "assignmentMode": {
        "type": "string"
      },
      "permissionValue": {
        "type": "string"
      },
      "teamCount": "number",
      "userCount": "number",
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "clickBackEditDashboardAccess": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "whichFlow": {
        "type": "string"
      },
      "whichStage": {
        "type": "string"
      },
      "dashboardId": {
        "type": "number",
        "isOptional": true
      },
      "assignmentMode": {
        "type": "string"
      },
      "permissionValue": {
        "type": "string"
      },
      "teamCount": "number",
      "userCount": "number",
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "clickNextEditDashboardAccess": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "whichFlow": {
        "type": "string"
      },
      "whichStage": {
        "type": "string"
      },
      "dashboardId": {
        "type": "number",
        "isOptional": true
      },
      "assignmentMode": {
        "type": "string",
        "isOptional": true
      },
      "permissionValue": {
        "type": "string",
        "isOptional": true
      },
      "teamCount": "number",
      "userCount": "number",
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "trackDashboardAccessPanelClicks": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "actionType": {
        "type": [
          "CLICK_CANCEL",
          "CLICK_SAVE",
          "CLICK_BACK",
          "CLICK_NEXT"
        ],
        "isOptional": false
      },
      "whichFlow": {
        "type": "string",
        "isOptional": true
      },
      "whichStage": {
        "type": "string",
        "isOptional": true
      },
      "dashboardId": {
        "type": "number",
        "isOptional": true
      },
      "accessClassification": {
        "type": "string",
        "isOptional": true
      },
      "permissionLevel": {
        "type": "string",
        "isOptional": true
      },
      "numOfAssignedEntities": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  },
  "clickCancelEditDashboardAccess": {
    "name": "DASHBOARD_INTERACTION",
    "class": "interaction",
    "properties": {
      "whichFlow": {
        "type": "string"
      },
      "whichStage": {
        "type": "string"
      },
      "dashboardId": {
        "type": "number",
        "isOptional": true
      },
      "assignmentMode": {
        "type": "string",
        "isOptional": true
      },
      "permissionValue": {
        "type": "string",
        "isOptional": true
      },
      "teamCount": {
        "type": "number",
        "isOptional": true
      },
      "userCount": {
        "type": "number",
        "isOptional": true
      },
      "dashboardCount": {
        "type": "number",
        "isOptional": true
      },
      "initialAssignmentMode": {
        "type": "string",
        "isOptional": true
      },
      "initialPermissionValue": {
        "type": "string",
        "isOptional": true
      },
      "initialTeamCount": {
        "type": "number",
        "isOptional": true
      },
      "initialUserCount": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "reporting"
  }
};
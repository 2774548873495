import I18n from 'I18n';
import * as OperatorTypes from '../ObjectSegOperatorTypes';
import * as OperationTypes from '../OperationTypes';
import * as DateTimePointReferenceTypes from './DateTimePointReferenceTypes';
import * as IndexedTimePointReferenceTypes from './IndexedTimePointReferenceTypes';
import * as TimeOperationEndpointBehaviorTypes from './TimeOperationEndpointBehaviorTypes';
import * as TimeOperationPropertyParserTypes from './TimeOperationPropertyParserTypes';
import * as TimePointTimeTypes from './TimePointTimeTypes';
export const isEqualToDateOperation = (operation, enforceMidnightAlignment = false) => {
  if ((operation.propertyParser === TimeOperationPropertyParserTypes.VALUE || operation.propertyParser === TimeOperationPropertyParserTypes.VALUE_WITH_ZONE_SAME_LOCAL_CONVERSION) && operation.propertyType === OperationTypes.RANGED_TIME && operation.operator === OperatorTypes.IS_BETWEEN && operation.lowerBoundTimePoint.timeType === TimePointTimeTypes.DATE && operation.upperBoundTimePoint.timeType === TimePointTimeTypes.DATE && operation.lowerBoundEndpointBehavior === TimeOperationEndpointBehaviorTypes.INCLUSIVE && operation.upperBoundEndpointBehavior === TimeOperationEndpointBehaviorTypes.INCLUSIVE) {
    const {
      lowerBoundTimePoint,
      upperBoundTimePoint
    } = operation;
    if (lowerBoundTimePoint.year === upperBoundTimePoint.year && lowerBoundTimePoint.month === upperBoundTimePoint.month && lowerBoundTimePoint.day === upperBoundTimePoint.day) {
      if (enforceMidnightAlignment) {
        return lowerBoundTimePoint.hour === 0 && lowerBoundTimePoint.minute === 0 && lowerBoundTimePoint.second === 0 && lowerBoundTimePoint.millisecond === 0 && upperBoundTimePoint.hour === 23 && upperBoundTimePoint.minute === 59 && upperBoundTimePoint.second === 59 && upperBoundTimePoint.millisecond === 999;
      }
      return true;
    }
  }
  return false;
};
export const isBetweenDatesOperation = (operation, enforceMidnightAlignment = false) => {
  if ((operation.propertyParser === TimeOperationPropertyParserTypes.VALUE || operation.propertyParser === TimeOperationPropertyParserTypes.VALUE_WITH_ZONE_SAME_LOCAL_CONVERSION) && operation.propertyType === OperationTypes.RANGED_TIME && operation.operator === OperatorTypes.IS_BETWEEN && operation.lowerBoundTimePoint.timeType === TimePointTimeTypes.DATE && operation.upperBoundTimePoint.timeType === TimePointTimeTypes.DATE && operation.lowerBoundEndpointBehavior === TimeOperationEndpointBehaviorTypes.INCLUSIVE && operation.upperBoundEndpointBehavior === TimeOperationEndpointBehaviorTypes.INCLUSIVE) {
    if (enforceMidnightAlignment) {
      const {
        lowerBoundTimePoint,
        upperBoundTimePoint
      } = operation;
      return lowerBoundTimePoint.hour === 0 && lowerBoundTimePoint.minute === 0 && lowerBoundTimePoint.second === 0 && lowerBoundTimePoint.millisecond === 0 && upperBoundTimePoint.hour === 23 && upperBoundTimePoint.minute === 59 && upperBoundTimePoint.second === 59 && upperBoundTimePoint.millisecond === 999;
    }
    return true;
  }
  return false;
};
export const isNotBetweenDatesOperation = (operation, enforceMidnightAlignment = false) => {
  if ((operation.propertyParser === TimeOperationPropertyParserTypes.VALUE || operation.propertyParser === TimeOperationPropertyParserTypes.VALUE_WITH_ZONE_SAME_LOCAL_CONVERSION) && operation.propertyType === OperationTypes.RANGED_TIME && operation.operator === OperatorTypes.IS_NOT_BETWEEN && operation.lowerBoundTimePoint.timeType === TimePointTimeTypes.DATE && operation.upperBoundTimePoint.timeType === TimePointTimeTypes.DATE && operation.lowerBoundEndpointBehavior === TimeOperationEndpointBehaviorTypes.INCLUSIVE && operation.upperBoundEndpointBehavior === TimeOperationEndpointBehaviorTypes.INCLUSIVE) {
    if (enforceMidnightAlignment) {
      const {
        lowerBoundTimePoint,
        upperBoundTimePoint
      } = operation;
      return lowerBoundTimePoint.hour === 0 && lowerBoundTimePoint.minute === 0 && lowerBoundTimePoint.second === 0 && lowerBoundTimePoint.millisecond === 0 && upperBoundTimePoint.hour === 23 && upperBoundTimePoint.minute === 59 && upperBoundTimePoint.second === 59 && upperBoundTimePoint.millisecond === 999;
    }
    return true;
  }
  return false;
};
export const isAfterDateOperation = (operation, enforceMidnightAlignment = false) => {
  if ((operation.propertyParser === TimeOperationPropertyParserTypes.VALUE || operation.propertyParser === TimeOperationPropertyParserTypes.VALUE_WITH_ZONE_SAME_LOCAL_CONVERSION) && 'endpointBehavior' in operation && operation.endpointBehavior === TimeOperationEndpointBehaviorTypes.EXCLUSIVE && operation.propertyType === OperationTypes.TIME_POINT && operation.operator === OperatorTypes.IS_AFTER && operation.timePoint.timeType === TimePointTimeTypes.DATE) {
    if (enforceMidnightAlignment) {
      return operation.timePoint.hour === 23 && operation.timePoint.minute === 59 && operation.timePoint.second === 59 && operation.timePoint.millisecond === 999;
    }
    return true;
  }
  return false;
};
export const isBeforeDateOperation = (operation, enforceMidnightAlignment = false) => {
  if ((operation.propertyParser === TimeOperationPropertyParserTypes.VALUE || operation.propertyParser === TimeOperationPropertyParserTypes.VALUE_WITH_ZONE_SAME_LOCAL_CONVERSION) && operation.propertyType === OperationTypes.TIME_POINT && operation.operator === OperatorTypes.IS_BEFORE && operation.timePoint.timeType === TimePointTimeTypes.DATE) {
    if (enforceMidnightAlignment) {
      return operation.timePoint.hour === 0 && operation.timePoint.minute === 0 && operation.timePoint.second === 0 && operation.timePoint.millisecond === 0;
    }
    return true;
  }
  return false;
};
export const isMoreThanXDaysAgoOperation = operation => {
  if ((operation.propertyParser === TimeOperationPropertyParserTypes.VALUE || operation.propertyParser === TimeOperationPropertyParserTypes.VALUE_WITH_ZONE_SAME_LOCAL_CONVERSION) && operation.propertyType === OperationTypes.TIME_POINT && operation.operator === OperatorTypes.IS_BEFORE && operation.timePoint.timeType === TimePointTimeTypes.INDEXED && operation.timePoint.indexReference.referenceType === IndexedTimePointReferenceTypes.TODAY && operation.endpointBehavior === TimeOperationEndpointBehaviorTypes.EXCLUSIVE) {
    // do not return false, while this case is not expected for new filters it needs to be supported
    // if (operation.timePoint.offset.days > 0) { return false; }

    return true;
  }
  return false;
};
export const isMoreThanXDaysFromNowOperation = (operation, enforceMidnightAlignment = false) => {
  if ((operation.propertyParser === TimeOperationPropertyParserTypes.VALUE || operation.propertyParser === TimeOperationPropertyParserTypes.VALUE_WITH_ZONE_SAME_LOCAL_CONVERSION) && operation.propertyType === OperationTypes.TIME_POINT && operation.operator === OperatorTypes.IS_AFTER && operation.timePoint.timeType === TimePointTimeTypes.INDEXED && operation.timePoint.indexReference.referenceType === IndexedTimePointReferenceTypes.TODAY && operation.endpointBehavior === TimeOperationEndpointBehaviorTypes.EXCLUSIVE) {
    if (enforceMidnightAlignment) {
      return operation.timePoint.offset.hours === 23 && operation.timePoint.offset.minutes === 59 && operation.timePoint.offset.seconds === 59 && operation.timePoint.offset.milliseconds === 999;
    }
    return true;
  }
  return false;
};
export const isLessThanXDaysAgoOperation = operation => {
  if ((operation.propertyParser === TimeOperationPropertyParserTypes.VALUE || operation.propertyParser === TimeOperationPropertyParserTypes.VALUE_WITH_ZONE_SAME_LOCAL_CONVERSION) && operation.propertyType === OperationTypes.RANGED_TIME && operation.operator === OperatorTypes.IS_BETWEEN && operation.lowerBoundTimePoint.timeType === TimePointTimeTypes.INDEXED && operation.lowerBoundTimePoint.indexReference.referenceType === IndexedTimePointReferenceTypes.TODAY && operation.lowerBoundEndpointBehavior === TimeOperationEndpointBehaviorTypes.INCLUSIVE && 'offset' in operation.lowerBoundTimePoint && operation.lowerBoundTimePoint.offset && operation.lowerBoundTimePoint.offset.days <= 0 && operation.upperBoundTimePoint.timeType === TimePointTimeTypes.INDEXED && operation.upperBoundTimePoint.indexReference.referenceType === IndexedTimePointReferenceTypes.NOW && operation.upperBoundEndpointBehavior === TimeOperationEndpointBehaviorTypes.INCLUSIVE) {
    return true;
  }
  return false;
};
export const isLessThanXDaysFromNowOperation = (operation, enforceMidnightAlignment = false) => {
  if ((operation.propertyParser === TimeOperationPropertyParserTypes.VALUE || operation.propertyParser === TimeOperationPropertyParserTypes.VALUE_WITH_ZONE_SAME_LOCAL_CONVERSION) && operation.propertyType === OperationTypes.RANGED_TIME && operation.operator === OperatorTypes.IS_BETWEEN && operation.lowerBoundTimePoint.timeType === TimePointTimeTypes.INDEXED && operation.lowerBoundTimePoint.indexReference.referenceType === IndexedTimePointReferenceTypes.NOW && operation.lowerBoundEndpointBehavior === TimeOperationEndpointBehaviorTypes.INCLUSIVE && operation.upperBoundTimePoint.timeType === TimePointTimeTypes.INDEXED && operation.upperBoundTimePoint.indexReference.referenceType === IndexedTimePointReferenceTypes.TODAY && operation.upperBoundEndpointBehavior === TimeOperationEndpointBehaviorTypes.INCLUSIVE && 'offset' in operation.upperBoundTimePoint && operation.upperBoundTimePoint.offset) {
    if (enforceMidnightAlignment) {
      return operation.upperBoundTimePoint.offset.days >= 0 && operation.upperBoundTimePoint.offset.hours === 23 && operation.upperBoundTimePoint.offset.minutes === 59 && operation.upperBoundTimePoint.offset.seconds === 59 && operation.upperBoundTimePoint.offset.milliseconds === 999;
    }
    return true;
  }
  return false;
};
const isValueReferencedOperation = operation => {
  if ((operation.propertyParser === TimeOperationPropertyParserTypes.VALUE || operation.propertyParser === TimeOperationPropertyParserTypes.VALUE_WITH_ZONE_SAME_LOCAL_CONVERSION) && operation.propertyType === OperationTypes.TIME_POINT && operation.timePoint.timeType === TimePointTimeTypes.PROPERTY_REFERENCED && (operation.timePoint.referenceType === DateTimePointReferenceTypes.VALUE || operation.timePoint.referenceType === DateTimePointReferenceTypes.VALUE_WITH_ZONE_SAME_LOCAL_CONVERSION)) {
    return true;
  }
  return false;
};
export const isAfterPropertyOperation = operation => {
  if (isValueReferencedOperation(operation) && operation.operator === OperatorTypes.IS_AFTER) {
    return true;
  }
  return false;
};
export const isBeforePropertyOperation = operation => {
  if (isValueReferencedOperation(operation) && operation.operator === OperatorTypes.IS_BEFORE) {
    return true;
  }
  return false;
};
const isUpdatedInLastXDaysOperationBase = operation => {
  if (operation.propertyParser === TimeOperationPropertyParserTypes.UPDATED_AT && operation.propertyType === OperationTypes.RANGED_TIME && operation.lowerBoundEndpointBehavior === TimeOperationEndpointBehaviorTypes.INCLUSIVE && operation.lowerBoundTimePoint.timeType === TimePointTimeTypes.INDEXED && operation.lowerBoundTimePoint.indexReference.referenceType === IndexedTimePointReferenceTypes.TODAY && 'offset' in operation.lowerBoundTimePoint && operation.lowerBoundTimePoint.offset && operation.lowerBoundTimePoint.offset.days <= 0 && operation.upperBoundEndpointBehavior === TimeOperationEndpointBehaviorTypes.INCLUSIVE && operation.upperBoundTimePoint.timeType === TimePointTimeTypes.INDEXED && operation.upperBoundTimePoint.indexReference.referenceType === IndexedTimePointReferenceTypes.NOW) {
    return true;
  }
  return false;
};
export const isUpdatedInLastXDaysOperation = operation => {
  if (isUpdatedInLastXDaysOperationBase(operation) && operation.operator === OperatorTypes.IS_BETWEEN) {
    return true;
  }
  return false;
};
export const isNotUpdatedInLastXDaysOperation = operation => {
  if (isUpdatedInLastXDaysOperationBase(operation) && operation.operator === OperatorTypes.IS_NOT_BETWEEN) {
    return true;
  }
  return false;
};
const isPropertiesUpdatedAtComparisonOperation = operation => {
  if (operation.propertyParser === TimeOperationPropertyParserTypes.UPDATED_AT && operation.propertyType === OperationTypes.TIME_POINT && operation.timePoint.timeType === TimePointTimeTypes.PROPERTY_REFERENCED && operation.timePoint.referenceType === DateTimePointReferenceTypes.UPDATED_AT && typeof operation.timePoint.property === 'string') {
    return true;
  }
  return false;
};
export const isUpdatedAfterPropertyOperation = operation => {
  if (isPropertiesUpdatedAtComparisonOperation(operation) && operation.operator === OperatorTypes.IS_AFTER) {
    return true;
  }
  return false;
};
export const isUpdatedBeforePropertyOperation = operation => {
  if (isPropertiesUpdatedAtComparisonOperation(operation) && operation.operator === OperatorTypes.IS_BEFORE) {
    return true;
  }
  return false;
};
export const convertMomentDateToTimePoint = (date, dayAlignment) => {
  let time = {};
  if (dayAlignment === 'start') {
    date.startOf('date');
    time = {
      hour: date.hour(),
      minute: date.minute(),
      second: date.second(),
      millisecond: date.millisecond()
    };
  } else if (dayAlignment === 'end') {
    date.endOf('date');
    time = {
      hour: date.hour(),
      minute: date.minute(),
      second: date.second(),
      millisecond: date.millisecond()
    };
  }
  return Object.assign({
    timeType: TimePointTimeTypes.DATE,
    year: date.year(),
    month: date.month() + 1,
    day: date.date()
  }, time, {
    zoneId: date.tz() || 'UTC'
  });
};
const isUTCOffset = zoneId => {
  return zoneId === 'Z' || /^[-+]\d\d:\d\d$/.test(zoneId);
};
export const convertTimePointToMomentDate = timePoint => {
  if (isUTCOffset(timePoint.zoneId)) {
    return I18n.moment.utc({
      year: timePoint.year,
      month: timePoint.month - 1,
      date: timePoint.day
    }).utcOffset(timePoint.zoneId, true);
  }
  return I18n.moment.tz({
    year: timePoint.year,
    month: timePoint.month - 1,
    date: timePoint.day
  }, timePoint.zoneId);
};
import { QUICK_FILTER_LIMIT, getQuickFilterUniqueKey } from './constants';
import { dataSourcePropertiesNamespace, selectAllDataSourcesByPropertyRecords } from '../dataSourcePropertiesSlice';
import { createSelector } from '@reduxjs/toolkit';
import isEmpty from 'transmute/isEmpty';
import { RequestState } from '../../filter/requests/request-state';
import { selectDashboardFilters } from '../dashboardFiltersSlice';
import { applyInvalidPropertiesFlagToQuickFilterGroups, checkIfQuickFilterGroupIsApplied, extractQuickFilterGroupFromAppliedFilters, filterOutQuickFilterGroupsInvalidProperties, getQuickFilterGroupWithProperty } from '../../filter/utils/groupQuickFilterHelpers';
import { getPropertyFromDataSourceByPropertyRecord } from '../../filter/utils/schema/propertySchema';
import { getFilterQueryFormatOperator } from '../../filter/utils/filterConversionHelpers';

// Selectors
export const selectQuickFilters = dashboardId => ({
  quickFilter
}) => {
  return quickFilter.quickFilterSet[dashboardId] || [];
};
export const selectIsInitialQuickFilterRender = dashboardId => ({
  quickFilter
}) => {
  const quickFilters = quickFilter.quickFilterSet[dashboardId];
  const quickFilterGroups = quickFilter.quickFilterGroupSet[dashboardId];
  return quickFilters || quickFilterGroups;
};
export const selectHasQuickFilterApplied = dashboardId => state => {
  return selectQuickFilters(dashboardId)(state).length > 0;
};
export const selectIsPanelOpen = ({
  quickFilter
}) => {
  return quickFilter.isPanelOpen;
};
export const selectIsPinnedQuickFilterReachLimit = dashboardId => state => {
  return selectQuickFilters(dashboardId)(state).length === QUICK_FILTER_LIMIT;
};
export const selectQuickFilterPanelView = ({
  quickFilter
}) => {
  return quickFilter.panelView;
};
export const selectIsQuickFilterPropertyPinned = ({
  dashboardId,
  dataSourceId,
  propertyName
}) => state => {
  const quickFilters = selectQuickFilters(dashboardId)(state);
  return quickFilters.some(quickFilter => quickFilter.dataSourceId === dataSourceId && quickFilter.name === propertyName);
};
export const selectShouldDisableAction = ({
  quickFilter
}) => {
  const fetchStatus = quickFilter.fetchQuickFiltersStatus;
  return fetchStatus === RequestState.UNINITIALIZED || fetchStatus === RequestState.PENDING;
};
export const selectQuickFilterToProperty = dashboardId => createSelector([selectQuickFilters(dashboardId), state => state[dataSourcePropertiesNamespace]], (quickFilters, dataSourceProperties) => {
  if (isEmpty(dataSourceProperties)) return {};
  return quickFilters.map(({
    dataSourceId,
    name
  }) => {
    if (!dataSourceProperties[dataSourceId]) return {};
    return {
      dataSource: dataSourceId,
      name,
      property: dataSourceProperties[dataSourceId].properties
    };
  });
});
export const selectQuickFilterOperators = dashboardId => createSelector([selectQuickFilters(dashboardId), selectDashboardFilters(dashboardId)], (quickFilters, appliedFilters) => {
  return quickFilters.reduce((memo, quickFilter) => {
    const {
      dataSourceId,
      name
    } = quickFilter;
    const [filterValue] = appliedFilters.filter(appliedFilter => appliedFilter.propertyName === name && appliedFilter.dataSource === dataSourceId);
    if (filterValue) {
      memo.set(getQuickFilterUniqueKey({
        dataSource: dataSourceId,
        name
      }), filterValue);
    }
    return memo;
  }, new Map());
});

/** Selects quick filter groups including invalid data sources and properties */
const _selectQuickFilterGroups = dashboardId => state => {
  return state.quickFilter.quickFilterGroupSet[dashboardId] || [];
};
const selectDisplayQuickFilterGroupsWithMaybeInvalidProperties = dashboardId => createSelector([_selectQuickFilterGroups(dashboardId), selectAllDataSourcesByPropertyRecords], applyInvalidPropertiesFlagToQuickFilterGroups);

/** Selects quick filter groups excluding any invalid properties and data sources */
export const selectQuickFilterGroups = dashboardId => createSelector([selectDisplayQuickFilterGroupsWithMaybeInvalidProperties(dashboardId)], filterOutQuickFilterGroupsInvalidProperties);
export const selectQuickFilterGroupWithProperty = (dashboardId, dataSourceId, propertyName) => createSelector([selectQuickFilterGroups(dashboardId)], quickFilterGroups => getQuickFilterGroupWithProperty(quickFilterGroups, propertyName, dataSourceId));
export const selectIsQuickFilterGroupLimitReached = dashboardId => createSelector([selectQuickFilterGroups(dashboardId)], groups => {
  return groups.length >= QUICK_FILTER_LIMIT;
});
export const selectQuickFilterDataSourceKeys = dashboardId => createSelector([selectQuickFilters(dashboardId)], quickFilters => {
  return [...new Set(quickFilters.map(f => f.dataSourceId))];
});
export const selectQuickFilterGroupDataSourceKeys = dashboardId => createSelector([selectDisplayQuickFilterGroupsWithMaybeInvalidProperties(dashboardId)], quickFilterGroups => {
  const allDataSources = quickFilterGroups.map(g => g.properties.map(p => p.dataSourceId)).flat();
  return [...new Set(allDataSources)];
});
export const selectHasQuickFilterGroupBeenApplied = ({
  dashboardId,
  quickFilterGroup
}) => createSelector([selectDashboardFilters(dashboardId)], appliedFilters => {
  const hasQuickFilterGroupBeenApplied = checkIfQuickFilterGroupIsApplied(quickFilterGroup, appliedFilters);
  return hasQuickFilterGroupBeenApplied;
});
export const selectQuickFilterGroupAppliedOperator = ({
  dashboardId,
  quickFilterGroup
}) => createSelector([selectAllDataSourcesByPropertyRecords, selectDashboardFilters(dashboardId), selectHasQuickFilterGroupBeenApplied({
  dashboardId,
  quickFilterGroup
})], (dataSourceProperties, appliedFilters, hasQuickFilterGroupBeenApplied) => {
  if (!hasQuickFilterGroupBeenApplied) {
    return null;
  }
  const {
    properties
  } = quickFilterGroup;
  const firstProperty = properties.at(0);
  if (!firstProperty) {
    return null;
  }
  const quickFilterGroupAppliedFilters = extractQuickFilterGroupFromAppliedFilters(quickFilterGroup, appliedFilters);
  const groupAppliedFilter = quickFilterGroupAppliedFilters.find(appliedFilter => {
    const {
      dataSource,
      propertyName
    } = appliedFilter;
    return dataSource === firstProperty.dataSourceId && propertyName === firstProperty.name;
  });
  const propertyDefinition = getPropertyFromDataSourceByPropertyRecord(dataSourceProperties, firstProperty.dataSourceId, firstProperty.name);
  if (groupAppliedFilter && propertyDefinition) {
    return getFilterQueryFormatOperator(groupAppliedFilter, dataSourceProperties[firstProperty.dataSourceId]);
  }
  return null;
});
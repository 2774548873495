import I18n from 'I18n';
import { fromJS, Map as ImmutableMap } from 'immutable';
import { UNKNOWN } from '../constants/errorTypes';
import { CONFIG_VALIDATION_ERROR, JOURNEY_ANALYTICS_ERROR, REPORT_DATA_ERROR, FIELD_LEVEL_PERMISSION_ERROR } from '../constants/reportingApiErrorTypes';
import * as Exceptions from '../exceptions';
const PIPELINESTAGE_DURATION_PROPERTIES = ['dealstages.*_duration'];
const errorTypeToException = ImmutableMap({
  [CONFIG_VALIDATION_ERROR.INVALID_PROPERTY]: context => {
    const properties = context.get('propertyName');
    throw new Exceptions.InvalidPropertiesException(properties.toJS());
  },
  [CONFIG_VALIDATION_ERROR.DEPRECATED_PROPERTY]: context => {
    const property = context.getIn(['propertyName', 0]);
    throw new Exceptions.DeprecatedPropertyException(property);
  },
  [CONFIG_VALIDATION_ERROR.TOO_MANY_METRICS_REQUESTED]: () => {
    throw new Exceptions.TooManyMetricsException();
  },
  [CONFIG_VALIDATION_ERROR.MISSING_DIMENSIONS_AND_METRICS]: () => {
    throw new Exceptions.NoDimensionsOrMetricsException();
  },
  [CONFIG_VALIDATION_ERROR.TOO_MANY_DEAL_STAGES_REQUESTED]: () => {
    throw new Exceptions.TooManyStagesException();
  },
  [CONFIG_VALIDATION_ERROR.TOO_MANY_OBJECT_STAGES_REQUESTED]: () => {
    throw new Exceptions.TooManyStagesException();
  },
  [CONFIG_VALIDATION_ERROR.INVALID_TWO_DIMENSION_METRIC]: (context, rawError) => {
    const property = context.getIn(['propertyName', 0]);
    if (PIPELINESTAGE_DURATION_PROPERTIES.includes(property)) {
      throw new Exceptions.InvalidTwoDimensionMetricException({
        timeInAllStagesProperty: property,
        propertyName: I18n.text('reporting-data.pipelinestages.timeInAllStages')
      });
    }
    throw new Exceptions.Exception(rawError && rawError.toString && rawError.toString() ? rawError.toString() : undefined);
  },
  [CONFIG_VALIDATION_ERROR.FUNNEL_COUNT_PROPERTY_NOT_PRESENT]: context => {
    const metricName = context.getIn(['metricName', 0]);
    throw new Exceptions.FunnelCountPropertyNotPresent(metricName);
  },
  [CONFIG_VALIDATION_ERROR.UNSUPPORTED_DATE_RANGE_FOR_ATTRIBUTION_REPORTS]: () => {
    throw new Exceptions.UnsupportedDateRangeForAttributionReportsException();
  },
  [REPORT_DATA_ERROR.ATTRIBUTION_BACKFILL_IN_PROGRESS]: () => {
    throw new Exceptions.DataReprocessingException();
  },
  [REPORT_DATA_ERROR.TOO_MANY_FILLING_PERMUTATIONS]: () => {
    throw new Exceptions.TooMuchDataException();
  },
  [REPORT_DATA_ERROR.TOO_MUCH_DATA_TO_PROCESS]: () => {
    throw new Exceptions.TooMuchDataException();
  },
  [JOURNEY_ANALYTICS_ERROR.THREE_SEQUENTIAL_OPTIONAL_STAGES_WITH_OVERLAPPING_EVENTS]: () => {
    throw new Exceptions.TooManySequentialOptionalStages();
  },
  [JOURNEY_ANALYTICS_ERROR.UNFILTERED_PIPELINE_STAGE]: () => {
    throw new Exceptions.UnfilteredPipelineStage();
  },
  [FIELD_LEVEL_PERMISSION_ERROR]: context => {
    var _context$get;
    const propertyNames = (_context$get = context.get('propertyNames')) === null || _context$get === void 0 ? void 0 : _context$get.toJS();
    throw new Exceptions.FieldLevelPermission({
      propertyNames
    });
  },
  [UNKNOWN]: (context, rawError) => {
    throw new Exceptions.Exception(rawError && rawError.toString && rawError.toString() ? rawError.toString() : undefined);
  }
});
export const createExceptionFromErrorType = (errorType, context, rawError) => errorTypeToException.has(errorType) ? errorTypeToException.get(errorType)(fromJS(context), rawError) : errorTypeToException.get(UNKNOWN)(fromJS(context), rawError);
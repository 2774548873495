'use es6';

import count from 'transmute/count';
import { track } from 'dashboard-components/usage-tracking/track';
import { CREATE_DASHBOARD } from 'dashboard-components/usage-tracking/flow-name/dashboard-permission-flow-name';
import { createTrackHandler } from 'dashboard-components/usage-tracking/track-handler';
const whichFlow = CREATE_DASHBOARD;
export const CLICK_ACTION_BUTTON = 'CLICK_ACTION_BUTTON';
export const CLICK_SAVE = 'CLICK_SAVE';
export const CLICK_NEXT = 'CLICK_NEXT';
export const CLICK_BACK = 'CLICK_BACK';
export const CLICK_CANCEL = 'CLICK_CANCEL';
export const CLICK_DASHBOARD_TEMPLATE = 'CLICK_DASHBOARD_TEMPLATE';
export const CLICK_CREATE_BLANK_DASHBOARD = 'CLICK_CREATE_BLANK_DASHBOARD';
export const CLICK_DISCOVER_DASHBOARDS = 'CLICK_DISCOVER_DASHBOARDS';
export const CLICK_UPGRADE_BUTTON = 'CLICK_UPGRADE_BUTTON';
export const TRACK_DASHBOARD_ACCESS_PANEL_CLICKS = 'TRACK_DASHBOARD_ACCESS_PANEL_CLICKS';
const trackDashboardTemplateType = (templateName, widgetNames) => {
  if (templateName) {
    track('createDashboardFromTemplate', {
      templateName,
      widgetNames
    });
  } else {
    track('createBlankDashboard');
  }
};
const trackSave = ({
  selectedTeamSet,
  selectedUserSet,
  assignmentMode,
  permissionValue,
  templateName,
  widgetNames
}) => {
  track('saveDashboardAccess', {
    whichFlow,
    teamCount: count(selectedTeamSet || []),
    userCount: count(selectedUserSet || []),
    assignmentMode,
    permissionValue
  });
  trackDashboardTemplateType(templateName, widgetNames);
};
const trackNext = ({
  whichStage,
  selectedTeamSet,
  selectedUserSet,
  assignmentMode,
  permissionValue
}) => {
  track('clickNextEditDashboardAccess', {
    whichFlow,
    whichStage,
    teamCount: count(selectedTeamSet || []),
    userCount: count(selectedUserSet || []),
    assignmentMode,
    permissionValue
  });
};
const trackBack = ({
  whichStage,
  selectedTeamSet,
  selectedUserSet,
  assignmentMode,
  permissionValue
}) => {
  track('clickBackEditDashboardAccess', {
    whichFlow,
    whichStage,
    teamCount: count(selectedTeamSet || []),
    userCount: count(selectedUserSet || []),
    assignmentMode,
    permissionValue
  });
};
const trackCancel = ({
  whichStage,
  selectedTeamSet,
  selectedUserSet,
  assignmentMode,
  permissionValue
}) => {
  track('clickCancelEditDashboardAccess', {
    whichFlow,
    whichStage,
    teamCount: count(selectedTeamSet || []),
    userCount: count(selectedUserSet || []),
    assignmentMode,
    permissionValue
  });
};
const trackDashboardAccessPanelClicks = ({
  whichStage,
  numOfAssignedEntities,
  accessClassification,
  permissionLevel,
  actionType,
  templateName,
  widgetNames
}) => {
  track('trackDashboardAccessPanelClicks', {
    whichFlow,
    whichStage,
    numOfAssignedEntities,
    accessClassification,
    permissionLevel,
    actionType
  });
  if (actionType === CLICK_SAVE) {
    trackDashboardTemplateType(templateName, widgetNames);
  }
};
const trackCreateDashboardFromTemplateClick = ({
  templateName
}) => {
  track('clickCreateDashboardFromTemplate', {
    templateName
  });
};
const trackCreateBlankDashboardClick = () => {
  track('clickCreateBlankDashboard');
};
export const defaultTrackHandler = createTrackHandler({
  [CLICK_SAVE]: trackSave,
  [CLICK_NEXT]: trackNext,
  [CLICK_BACK]: trackBack,
  [CLICK_CANCEL]: trackCancel,
  [TRACK_DASHBOARD_ACCESS_PANEL_CLICKS]: trackDashboardAccessPanelClicks,
  [CLICK_DASHBOARD_TEMPLATE]: trackCreateDashboardFromTemplateClick,
  [CLICK_CREATE_BLANK_DASHBOARD]: trackCreateBlankDashboardClick
});
export const ADS_PROPERTY = 'ADS';
export const ACTIVITY_TYPE = 'ACTIVITY_TYPE';
export const CALL_DISPOSITION = 'CALL_DISPOSITION';
export const COMPANY = 'COMPANY';
export const COMPANY_PROPERTY = '0-2';
export const CONTACT = 'CONTACT';
export const CONTACT_IMPORT = 'CONTACT_IMPORT';
export const CONTACT_LIST = 'CONTACT_LIST';
export const CONTACT_PROPERTY = '0-1';
export const CTA = 'CTA';
export const CTA_TO_PLACEMENT = 'CTA_TO_PLACEMENT';
export const DEAL = 'DEAL';
export const DEAL_PROPERTY = '0-3';
export const EMAIL_CAMPAIGN = 'EMAIL_CAMPAIGN';
export const EMAIL_CAMPAIGN_URL = 'EMAIL_CAMPAIGN_URL';
export const EMAIL_SUBSCRIPTION = 'EMAIL_SUBSCRIPTION';
export const ENGAGEMENT_PROPERTY = '0-4';
export const EVENT = 'EVENT';
export const FORM = 'FORM';
export const GOTO_WEBINAR_GROUP = 'GOTO_WEBINAR_GROUP';
export const GOTO_WEBINAR_SETTING = 'GOTO_WEBINAR_SETTING';
export const GOTO_WEBINAR_WEBINAR = 'GOTO_WEBINAR_WEBINAR';
export const INTEGRATION = 'INTEGRATION';
export const INTEGRATION_APP = 'INTEGRATION_APP';
export const INTEGRATION_PROPERTY = 'INTEGRATION_PROPERTY';
export const INTEGRATOR_EVENT = 'INTEGRATOR_EVENT';
export const LEAD_PROPERTY = '0-136';
export const LINE_ITEM_PROPERTY = '0-8';
export const MULTI_CURRENCY_CURRENCY_CODE = 'MULTI_CURRENCY_CURRENCY_CODE';
export const OWNER = 'OWNER';
export const PAGE_VIEW = 'PAGE_VIEW';
export const PRODUCT_PROPERTY = '0-7';
export const PROPERTY_GROUP = 'PROPERTY_GROUP';
export const QUOTE_PROPERTY = '0-14';
export const SITE_CONTENT = 'SITE_CONTENT';
export const SURVEY_MONKEY_GROUP = 'SURVEY_MONKEY_GROUP';
export const SURVEY_MONKEY_SETTING = 'SURVEY_MONKEY_SETTING';
export const SURVEY_MONKEY_SURVEY = 'SURVEY_MONKEY_SURVEY';
export const SURVEY_MONKEY_QUESTION = 'SURVEY_MONKEY_QUESTION';
export const TASK_PROPERTY = '0-27';
export const TEAM = 'TEAM';
export const TICKET = 'TICKET';
export const TICKET_PROPERTY = '0-5';
export const UNIFIED_EVENT_MARKETING_EVENT_GROUP = 'UNIFIED_EVENT_MARKETING_EVENT_GROUP';
export const UNIFIED_EVENT_MARKETING_EVENT = 'UNIFIED_EVENT_MARKETING_EVENT';
export const UNIFIED_EVENT_MARKETING_EVENT_TYPE = 'UNIFIED_EVENT_MARKETING_EVENT_TYPE';
export const USER = 'USER';
export const WORKFLOW = 'WORKFLOW';
export const IN_LIST = 'IN_LIST';
export const IMPORT = 'IMPORT';
export const PRIVACY_CONSENT = 'PRIVACY_CONSENT';
export const STANDARD_OBJECT_DEFINITION = 'STANDARD_OBJECT_DEFINITION';
export const CUSTOM_OBJECT_DEFINITION = 'CUSTOM_OBJECT_DEFINITION';
export const APP_OBJECT_DEFINITION = 'APP_OBJECT_DEFINITION';
export const ASSOCIATION_DEFINITION = 'ASSOCIATION_DEFINITION';
export const CUSTOM_BEHAVIORAL_EVENT = 'CUSTOM_BEHAVIORAL_EVENT';
export const UNIFIED_EVENT = 'UNIFIED_EVENT';
export const GOAL_TARGET_PROPERTY = '0-74';
export const SMS_SUBSCRIPTION = 'SMS_SUBSCRIPTION';
export const WHATSAPP_SUBSCRIPTION = 'WHATSAPP_SUBSCRIPTION';
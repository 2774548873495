'use es6';

import { Map as ImmutableMap, List, fromJS } from 'immutable';
import { parse, stringify } from 'hub-http/helpers/params';
import makeDateRangeByType from 'reporting-data/lib/makeDateRangeByType';
import { ALL_PROPS, LIST_PROPS, JSON_PROPS } from '../records/Context';
import { contextKeyCorrections } from './fixInitialContext';
const LEGACY_DATE_PROPS = ['rangeType', 'rollingDays', 'startDate', 'endDate', 'entireCurrentUnit'];
const serializeContextParam = value => btoa(encodeURIComponent(JSON.stringify(value)));
export const deserializeContextParam = param => {
  try {
    return JSON.parse(decodeURIComponent(atob(param)));
  } catch (e) {
    return null;
  }
};
export const parseQueryParams = (search = window.location.search) => {
  const query = parse(search.replace(/^\?/, ''));
  const startParams = {};
  if (Object.keys(query).some(key => LEGACY_DATE_PROPS.includes(key))) {
    startParams.dateRange = ImmutableMap(makeDateRangeByType(query));
  }
  return Object.keys(query).filter(key => ALL_PROPS.includes(key) || Object.keys(contextKeyCorrections).includes(key)).reduce((collect, key) => {
    let value = query[key];
    const correctedKey = contextKeyCorrections[key] || key;
    if (value === 'true') {
      value = true;
    }
    if (value === 'false') {
      value = false;
    }
    if (LIST_PROPS.includes(correctedKey)) {
      value = value ? List(value.split(',')) : List();
    }
    if (JSON_PROPS.includes(correctedKey)) {
      value = value ? fromJS(deserializeContextParam(value)) : null;
    }
    /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
    return Object.assign({}, collect, {
      [correctedKey]: value
    });
  }, startParams);
};
export const flattenQueryParams = (params, serializeJson) => Object.keys(params).reduce((collect, key) => {
  let value = params[key];
  if (LIST_PROPS.includes(key) && value) {
    value = value.size > 0 ? value.toJS().join(',') : null;
  }
  if (JSON_PROPS.includes(key) && value) {
    if (serializeJson) {
      value = value.size > 0 ? serializeContextParam(value.toJS()) : null;
    } else {
      value = value.size > 0 ? JSON.stringify(value.toJS()) : null;
    }
  }
  /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
  return Object.assign({}, collect, {
    [key]: value
  });
}, {});
export const stringifyQueryParams = params => stringify(flattenQueryParams(params, true));
'use es6';

import { hasSufficientCreatePermission, hasSufficientEditPermission, hasSufficientViewPermission } from '../permissions/permission-logic';
import { getUserPermissionLevel } from '../data/schemas/dashboardSchema';
import { getCreatedAt } from '../data/schemas/dashboardSchema';
export const hasSufficientDashboardCreatePermission = dashboard => hasSufficientCreatePermission(getUserPermissionLevel(dashboard));
export const hasSufficientDashboardEditPermission = dashboard => hasSufficientEditPermission(getUserPermissionLevel(dashboard));
export const hasSufficientDashboardViewPermission = dashboard => hasSufficientViewPermission(getUserPermissionLevel(dashboard));
const LOCKDOWN_EFFECTIVE_TIMESTAMP = new Date('May 19, 2020 00:00:00').getTime();
export const lockdownIsInEffect = dashboard => {
  return getCreatedAt(dashboard) > LOCKDOWN_EFFECTIVE_TIMESTAMP;
};
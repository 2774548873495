import { AccessClassifications, PermissionableUserSets } from '../Constants';
const {
  API_ACCESS
} = PermissionableUserSets;
const {
  PRIVATE,
  EVERYONE
} = AccessClassifications;
export const createPermissionConfig = (assignedEntities, accessClassification, permissionLevel) => {
  const permissionConfig = [];
  if (accessClassification === PRIVATE) {
    const privateConfig = {
      accessClassification,
      permissionLevel,
      permissionGrants: []
    };
    return [privateConfig];
  }
  if (accessClassification === EVERYONE) {
    const newPermissionGrant = {
      userSetType: API_ACCESS,
      userSetId: undefined
    };
    const everyoneConfig = {
      accessClassification,
      permissionLevel,
      permissionGrants: [newPermissionGrant]
    };
    return [everyoneConfig];
  }
  for (const id in assignedEntities) {
    if (assignedEntities[id]) {
      const grant = assignedEntities[id];
      const currentPermissionConfig = permissionConfig.find(config => config.permissionLevel === grant.level);
      const newPermissionGrant = {
        userSetType: grant.type,
        userSetId: id === EVERYONE ? undefined : Number(id)
      };
      if (currentPermissionConfig) {
        currentPermissionConfig.permissionGrants.push(newPermissionGrant);
      } else {
        const newConfig = {
          accessClassification,
          permissionLevel: grant.level,
          permissionGrants: [newPermissionGrant]
        };
        permissionConfig.push(newConfig);
      }
    }
  }
  return permissionConfig;
};
import { Record, Map as ImmutableMap } from 'immutable';
const missing = object => () => {
  throw new Error(`missing ${object}`);
};
export const RequestMethods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put'
};
const defaultRequestValues = {
  method: RequestMethods.GET,
  url: '',
  form: ImmutableMap(),
  query: ImmutableMap(),
  data: ImmutableMap(),
  timeout: 30000,
  transformer: missing('transformation'),
  rpc: undefined
};
export const RequestRecord = Record(defaultRequestValues);
import { SUB_APPS } from 'reporting-data/tsTypes/subAppTypes';
import { HD_SERVICE_ANALYTICS_SCOPE, PROSPECTING_WORKSPACE_ACCESS, REVENUE_ANALYTICS } from '../constants/scopes';
import { EMAIL_REPORT_GATE, IMPROVED_RECURRING_REVENUE_GATE, LEAD_OBJECT_GATE } from '../constants/gates';
const REPORT_HYBRID_SRV_SUB_APP_DATA = {
  // MARKETING
  [SUB_APPS.ads]: {
    adNetworks: {
      tabKey: 'adNetworks'
    },
    adCampaigns: {
      tabKey: 'adCampaigns'
    }
  },
  [SUB_APPS.leadCapture]: {
    forms: {
      tabKey: 'forms'
    }
  },
  [SUB_APPS.marketingEmails]: {
    marketingEmails: {
      tabKey: 'marketingEmails'
    }
  },
  [SUB_APPS.sms]: {
    sms: {
      tabKey: 'sms'
    }
  },
  [SUB_APPS.social]: {
    social: {
      tabKey: 'social'
    }
  },
  [SUB_APPS.cta]: {
    cta: {
      tabKey: 'cta'
    }
  },
  [SUB_APPS.customerJourneyAnalytics]: {
    // dealStageProgression: {
    //   tabKey: 'dealStageProgression',
    // },
    lifecycleStageProgression: {
      tabKey: 'lifecycleStageProgression'
    },
    pageVisitsToFormSubmissions: {
      tabKey: 'pageVisitsToFormSubmissions'
    },
    leadCreationBrokenDownByAdNetwork: {
      tabKey: 'leadCreationBrokenDownByAdNetwork'
    },
    meetingsThatGeneratedDeals: {
      tabKey: 'meetingsThatGeneratedDeals'
    }
  },
  [SUB_APPS.sources]: {
    sources: {
      tabKey: 'sources'
    }
  },
  [SUB_APPS.pages]: {
    pages: {
      tabKey: 'pages'
    }
  },
  [SUB_APPS.utmParameters]: {
    utmCampaign: {
      tabKey: 'utmCampaign'
    },
    utmSource: {
      tabKey: 'utmSource'
    },
    utmMedium: {
      tabKey: 'utmMedium'
    },
    utmContent: {
      tabKey: 'utmContent'
    },
    utmTerm: {
      tabKey: 'utmTerm'
    }
  },
  [SUB_APPS.deviceTypes]: {
    deviceTypes: {
      tabKey: 'deviceTypes'
    }
  },
  [SUB_APPS.geolocation]: {
    geolocation: {
      tabKey: 'geolocation'
    }
  },
  [SUB_APPS.browsers]: {
    browsers: {
      tabKey: 'browsers'
    }
  },
  [SUB_APPS.topicClusters]: {
    topicClusters: {
      tabKey: 'topicClusters'
    }
  },
  [SUB_APPS.contactInsights]: {
    contactInsights: {
      tabKey: 'contactInsights'
    }
  },
  [SUB_APPS.attribution]: {
    revenueAttribution: {
      tabKey: 'attribution'
    }
  },
  // SALES
  [SUB_APPS.prospectEngagement]: {
    sequences: {
      tabKey: 'sequences'
    },
    meetings: {
      tabKey: 'meetings'
    },
    calls: {
      tabKey: 'calls'
    },
    tasks: {
      tabKey: 'tasks'
    },
    emails: {
      tabKey: 'emails',
      gate: EMAIL_REPORT_GATE
    }
  },
  [SUB_APPS.leadFunnel]: {
    leads: {
      tabKey: 'leads',
      scope: PROSPECTING_WORKSPACE_ACCESS,
      gate: LEAD_OBJECT_GATE
    },
    leadStages: {
      tabKey: 'leadStages'
    }
  },
  [SUB_APPS.salesVelocity]: {
    salesVelocity: {
      tabKey: 'salesVelocity'
    }
  },
  [SUB_APPS.lineItemRevenue]: {
    closedWonRecurringRevenue: {
      tabKey: 'closedWonRecurringRevenue',
      gate: IMPROVED_RECURRING_REVENUE_GATE,
      scope: REVENUE_ANALYTICS
    },
    openRecurringRevenue: {
      tabKey: 'openRecurringRevenue',
      gate: IMPROVED_RECURRING_REVENUE_GATE,
      scope: REVENUE_ANALYTICS
    },
    allClosedWonRevenue: {
      tabKey: 'allClosedWonRevenue',
      gate: IMPROVED_RECURRING_REVENUE_GATE,
      scope: REVENUE_ANALYTICS
    }
  },
  [SUB_APPS.activities]: {
    activities: {
      tabKey: 'activities'
    }
  },
  [SUB_APPS.callOutcomes]: {
    callOutcomes: {
      tabKey: 'callOutcomes'
    }
  },
  [SUB_APPS.taskOutcomes]: {
    taskOutcomes: {
      tabKey: 'taskOutcomes'
    }
  },
  [SUB_APPS.conversations]: {
    conversations: {
      tabKey: 'conversations'
    }
  },
  [SUB_APPS.lossReasons]: {
    lossReasons: {
      tabKey: 'lossReasons'
    }
  },
  [SUB_APPS.salesBySource]: {
    salesBySource: {
      tabKey: 'salesBySource'
    }
  },
  [SUB_APPS.dealJourney]: {
    dealJourney: {
      tabKey: 'dealJourney'
    }
  },
  [SUB_APPS.dealsCreatedByRep]: {
    dealsCreatedByRep: {
      tabKey: 'dealsCreatedByRep'
    }
  },
  [SUB_APPS.dealsWon]: {
    dealsWon: {
      tabKey: 'dealsWon'
    }
  },
  [SUB_APPS.weightedPipelineForecast]: {
    weightedPipelineForecast: {
      tabKey: 'weightedPipelineForecast'
    }
  },
  [SUB_APPS.averageSalesPrice]: {
    averageSalesPrice: {
      tabKey: 'averageSalesPrice'
    }
  },
  [SUB_APPS.timeInDealStage]: {
    timeInDealStage: {
      tabKey: 'timeInDealStage'
    }
  },
  [SUB_APPS.activityStream]: {
    activityStream: {
      tabKey: 'activityStream'
    }
  },
  [SUB_APPS.dealVelocity]: {
    dealVelocity: {
      tabKey: 'dealVelocity'
    }
  },
  [SUB_APPS.leadResponseTime]: {
    leadResponseTime: {
      tabKey: 'leadResponseTime'
    }
  },
  [SUB_APPS.waterfall]: {
    waterfall: {
      tabKey: 'waterfall'
    }
  },
  [SUB_APPS.changeHistoryDealList]: {
    changeHistoryDealList: {
      tabKey: 'changeHistoryDealList'
    }
  },
  [SUB_APPS.dealPushAnalysis]: {
    dealPushAnalysis: {
      tabKey: 'dealPushAnalysis'
    }
  },
  [SUB_APPS.pipelineSnapshots]: {
    pipelineSnapshots: {
      tabKey: 'pipelineSnapshots'
    }
  },
  [SUB_APPS.quotaAttainment]: {
    quotaAttainment: {
      tabKey: 'quotaAttainment'
    }
  },
  [SUB_APPS.manualForecast]: {
    manualForecast: {
      tabKey: 'manualForecast'
    }
  },
  [SUB_APPS.manualForecastTotal]: {
    manualForecastTotal: {
      tabKey: 'manualForecastTotal'
    }
  },
  [SUB_APPS.meetingOutcomes]: {
    meetingOutcomes: {
      tabKey: 'meetingOutcomes'
    }
  },
  // SERVICE
  [SUB_APPS.ticketTimeInPipeline]: {
    ticketTimeInPipeline: {
      tabKey: 'ticketTimeInPipeline'
    }
  },
  [SUB_APPS.supportVolume]: {
    supportVolume: {
      tabKey: 'supportVolume'
    }
  },
  [SUB_APPS.resolutionTime]: {
    resolutionTime: {
      tabKey: 'resolutionTime'
    }
  },
  [SUB_APPS.queueTime]: {
    queueTime: {
      tabKey: 'queueTime'
    }
  },
  [SUB_APPS.postSupportCSAT]: {
    postSupportCSAT: {
      tabKey: 'postSupportCSAT'
    }
  },
  [SUB_APPS.ticketTimeToClose]: {
    ticketTimeToCloseAllHours: {
      tabKey: 'ticketTimeToRespondAllHours'
    },
    ticketTimeToCloseSLAHours: {
      tabKey: 'ticketTimeToRespondSLAHours',
      lockedScope: HD_SERVICE_ANALYTICS_SCOPE
    }
  },
  [SUB_APPS.ticketTimeToRespond]: {
    ticketTimeToRespondAllHours: {
      tabKey: 'ticketTimeToRespondAllHours'
    },
    ticketTimeToRespondSLAHours: {
      tabKey: 'ticketTimeToRespondSLAHours',
      lockedScope: HD_SERVICE_ANALYTICS_SCOPE
    }
  },
  [SUB_APPS.customerEffortScore]: {
    customerEffortScore: {
      tabKey: 'customerEffortScore'
    }
  },
  [SUB_APPS.customerReplyTime]: {
    averageCustomerReplyTime: {
      tabKey: 'averageCustomerReplyTime'
    },
    customersWithHighestAverageReplyTime: {
      tabKey: 'customersWithHighestAverageReplyTime'
    }
  },
  [SUB_APPS.customerWaitTime]: {
    customerWaitTime: {
      tabKey: 'customerWaitTime'
    }
  },
  [SUB_APPS.ownerReplyCount]: {
    ownerReplyCount: {
      tabKey: 'ownerReplyCount'
    }
  },
  [SUB_APPS.ownerReplyTime]: {
    averageReplyTime: {
      tabKey: 'averageReplyTime'
    },
    averageReplyTimeByOwner: {
      tabKey: 'averageReplyTimeByOwner'
    }
  },
  [SUB_APPS.ticketReplyCount]: {
    ticketsWithMostMessagesSent: {
      tabKey: 'ticketsWithMostMessagesSent'
    },
    ticketsWithMostMessagesReceived: {
      tabKey: 'ticketsWithMostMessagesReceived'
    }
  },
  [SUB_APPS.timeOfDay]: {
    messagesSentByTimeOfDay: {
      tabKey: 'messagesSentByTimeOfDay'
    },
    messagesReceivedByTimeOfDay: {
      tabKey: 'messagesReceivedByTimeOfDay'
    }
  },
  [SUB_APPS.workingHoursAvailability]: {
    workingHoursAvailability: {
      tabKey: 'workingHoursAvailability'
    }
  },
  [SUB_APPS.maxAvailableRep]: {
    maxAvailableRep: {
      tabKey: 'maxAvailableRep'
    }
  },
  [SUB_APPS.repAvailability]: {
    repAvailabilityTopLevel: {
      tabKey: 'repAvailabilityTopLevel'
    },
    repAvailabilityDrilldown: {
      tabKey: 'repAvailabilityDrilldown'
    }
  }
};
export const getHybridSRVSubAppData = subAppKey => REPORT_HYBRID_SRV_SUB_APP_DATA[subAppKey];
export const isSubAppHybridSRVReport = subAppKey => !!getHybridSRVSubAppData(subAppKey);
export const getHybridSRVSubAppTabs = (subAppKey, scopes, gates, includeLocked = false) => {
  const subAppData = getHybridSRVSubAppData(subAppKey) || {};
  return Object.keys(subAppData).filter(tabKey => {
    const tabData = subAppData[tabKey];
    const requiredScope = tabData.scope;
    const requiredGate = tabData.gate;
    const lockedScope = tabData.lockedScope;
    return (!requiredScope || scopes.includes(requiredScope)) && (!requiredGate || gates.includes(requiredGate)) && (includeLocked || !lockedScope || scopes.includes(lockedScope));
  });
};
export const getTabLockedScope = (subAppKey, tabKey) => {
  const subAppData = getHybridSRVSubAppData(subAppKey);
  if (!subAppData) {
    return null;
  }
  const tabData = subAppData[tabKey];
  return tabData.lockedScope;
};
import { useMemo, useState } from 'react';
const levenshteinDistanceByWord = (searchQuery, testString) => {
  const costMatrix = [];
  for (let j = 0; j <= testString.length; j++) {
    costMatrix[j] = [j];
    for (let i = 1; i <= searchQuery.length; i++) {
      const swapBit = (searchQuery[i - 1] ? searchQuery[i - 1] : '') === (testString[j - 1] ? testString[j - 1] : '') ? 0 : 1;
      costMatrix[j][i] = j === 0 ? i : Math.min(costMatrix[j][i - 1] + 1, costMatrix[j - 1][i] + 1, costMatrix[j - 1][i - 1] + swapBit);
    }
  }
  return costMatrix[testString.length][searchQuery.length] / testString.length;
};

// lower the threshold the closer to an exact match it is
export const MAX_LEVENSHTEIN_DISTANCE_THRESHOLD = 0.4;
export const MAX_FULLY_QUALIFIED_LEVENSHTEIN_DISTANCE_RANK_THRESHOLD = 0.03;

/** duplicated from https://git.hubteam.com/HubSpot/advanced-builder/blob/master/builder-common/static/js/lib/search-utils.ts#L36 */
export const levenshteinDistance = (searchQuery, testString, fullyQualifiedTestString = false) => {
  const testSubStrings = testString.toLowerCase().split(' ');
  const searchSubStrings = searchQuery.toLowerCase().split(' ');
  const distances = [];
  for (let i = 0; i < searchSubStrings.length; i++) {
    let minDistance = 999;
    for (let j = 0; j < testSubStrings.length; j++) {
      //this allows us to handle different tenses of the same word without being too forgiving of actual typos
      if (!fullyQualifiedTestString && searchSubStrings[i].length > 2 && testSubStrings[j].indexOf(searchSubStrings[i]) >= 0) {
        minDistance = 0;
        break;
      }
      const ld = levenshteinDistanceByWord(searchSubStrings[i], testSubStrings[j]);
      if (ld < minDistance) {
        minDistance = ld;
      }
      if (minDistance === 0) {
        break;
      }
    }
    distances.push(minDistance);
  }
  return distances.reduce((total, lDistance) => total + lDistance);
};
function filterByLevensteinDistance(items, searchTerm, getSearchTermCB, preferredDistance = 1, minReturnedItems = 5) {
  const lowerSearchTerm = searchTerm.toLowerCase();
  const itemsWithDistances = items.map(item => ({
    item,
    distance: levenshteinDistance(lowerSearchTerm, getSearchTermCB(item).toLowerCase())
  })).sort((a, b) => a.distance - b.distance);
  const filteredByDistanceThreshold = itemsWithDistances.filter(({
    distance
  }) => distance < preferredDistance).map(({
    item
  }) => item);
  if (filteredByDistanceThreshold.length < minReturnedItems) {
    return itemsWithDistances.slice(0, 5).map(({
      item
    }) => item);
  }
  return filteredByDistanceThreshold;
}
export const useLevenshteinDistanceFilter = params => {
  const {
    items,
    getSearchTermCB,
    preferredDistance = 1,
    minReturnedItems = 5
  } = params;
  const [searchTerm, setSearchTerm] = useState('');
  const filteredItems = useMemo(() => {
    if (searchTerm.length > 0 && items) {
      return filterByLevensteinDistance(items, searchTerm, getSearchTermCB, preferredDistance, minReturnedItems);
    }
    return items || [];
  }, [getSearchTermCB, items, minReturnedItems, preferredDistance, searchTerm]);
  return {
    searchTerm,
    onSearchTermChanged: setSearchTerm,
    filteredItems
  };
};
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { asyncRequest, cachedRequest
// @ts-expect-error migrate upstream reducer
} from 'dashboard-lib/public/request/request-middleware';
// @ts-expect-error migrate upstream reducer
import { namespace as usersNamespace } from 'dashboard-lib/public/users/users-action';
// @ts-expect-error migrate upstream reducer
import { namespace as teamNamespace } from 'dashboard-lib/public/team/team-action';
// @ts-expect-error migrate upstream reducer
import { namespace as teamHierarchyNamespace } from 'dashboard-lib/public/team/team-hierarchy-action';
// @ts-expect-error migrate upstream reducer
import { rootNameSpace as permissionNamespace } from 'dashboard-lib/public/permission/permission-action';
// @ts-expect-error migrate upstream reducer
import { usersReducer } from 'dashboard-lib/public/users/users-reducer';
// @ts-expect-error migrate upstream reducer
import { teamReducer } from 'dashboard-lib/public/team/team-reducer';
// @ts-expect-error migrate upstream reducer
import { teamHierarchyReducer } from 'dashboard-lib/public/team/team-hierarchy-reducer';
// @ts-expect-error migrate upstream reducer
import { permissionReducer } from 'dashboard-lib/public/permission/permission-reducer';
import { contentAssistanceNamespace, contentAssistanceReducer
// @ts-expect-error migrate upstream reducer
} from 'dashboard-lib/public/contentAssistance/contentAssistanceReducer';
import { FORCE_RENDER_COUNT_ROOT, forceRenderCountReducer
// @ts-expect-error migrate upstream reducer
} from '../ducks/forceRenderCount';
import { userInfoNamespace, userInfoReducer
// @ts-expect-error migrate upstream reducer
} from 'dashboard-lib/public/userInfo';
import { dashboardCreateNamespace, dashboardCreateReducer
// @ts-expect-error migrate upstream reducer
} from '../ducks/dashboardCreate';
import { dataSourceTopPropertiesNamespace, dataSourceTopPropertiesReducer
// @ts-expect-error migrate upstream reducer
} from '../ducks/dataSourceTopProperties';
import { quickFilterReducer
// @ts-expect-error migrate upstream reducer
} from '../ducks/quick-filters/quickFilters';
import { quickFiltersNamespace } from '../ducks/quick-filters';
import { history } from '../lib/history';
import { dataSourceRecentlyUsedPropertiesNamespace, dataSourceRecentlyUsedPropertiesReducer
// @ts-expect-error migrate upstream reducer
} from '../ducks/dataSourceRecentlyUsedProperties';
import { appliedDashboardFiltersNamespace, appliedDashboardFiltersReducer } from '../ducks/applied-dashboard-filters';
import { filterQueryParamsMiddleware } from '../ducks/applied-dashboard-filters/middleware';
import { dashboardReportsNamespace, dashboardReportsReducer } from '../ducks/dashboard-reports';
import { savedDashboardFiltersNamespace, savedDashboardFiltersReducer } from '../ducks/savedDashboardFilters';
import { contactCountNamespace, contactCountReducer } from '../ducks/contactCount';
import { platformDataSourceMetadataReducer, platformDataSourceMetadataSliceNamespace } from '../ducks/platform-data-source-metadata';
const rootReducer = combineReducers({
  [userInfoNamespace]: userInfoReducer,
  [FORCE_RENDER_COUNT_ROOT]: forceRenderCountReducer,
  [usersNamespace]: usersReducer,
  [teamNamespace]: teamReducer,
  [teamHierarchyNamespace]: teamHierarchyReducer,
  [permissionNamespace]: permissionReducer,
  [dashboardCreateNamespace]: dashboardCreateReducer,
  [dataSourceTopPropertiesNamespace]: dataSourceTopPropertiesReducer,
  [dataSourceRecentlyUsedPropertiesNamespace]: dataSourceRecentlyUsedPropertiesReducer,
  [quickFiltersNamespace]: quickFilterReducer,
  [savedDashboardFiltersNamespace]: savedDashboardFiltersReducer,
  [appliedDashboardFiltersNamespace]: appliedDashboardFiltersReducer,
  [contentAssistanceNamespace]: contentAssistanceReducer,
  [dashboardReportsNamespace]: dashboardReportsReducer,
  [contactCountNamespace]: contactCountReducer,
  [platformDataSourceMetadataSliceNamespace]: platformDataSourceMetadataReducer
});
const composeEnhancers =
// @ts-expect-error migrate legacy redux usages
typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
// @ts-expect-error migrate legacy redux usages
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  name: 'DashboardUI'
}) : compose;
export default createStore(rootReducer, composeEnhancers(applyMiddleware(asyncRequest, cachedRequest, thunk, filterQueryParamsMiddleware(history))));
'use es6';

import { isFieldExpressionBased } from '../../utils/column-utils';
import { checkExpressionField, expressionFieldsListToMap, reportHasExpressionEncoded } from '../../utils/expression-field-utils';
import { ConstraintTypes, createConstraint, InvalidSpecificationTypes } from '../validate';
import toJS from 'reporting-data/lib/toJS';
import { isDatePartExpressionFieldAlias } from '../../enhance/drilldown/drilldown-utils';
const checkFieldExpressionIsValid = (field, meta, expressionFields) => {
  if (isFieldExpressionBased(field)) {
    const expressionFieldsMap = expressionFieldsListToMap(expressionFields);
    const expressionFieldAlias = field.name;
    if (!expressionFieldsMap.has(expressionFieldAlias)) return false;
    const expressionField = expressionFieldsMap.get(expressionFieldAlias);
    const {
      violations
    } = checkExpressionField(expressionField, expressionFields, meta);
    return !violations || violations.size === 0;
  }
  return true;
};
export const noExpressionFunctionsAccess = createConstraint('no-expression-functions-access', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, (report, meta, userScopes) => {
  if (userScopes.length === 0) {
    return true;
  }
  const hasFunctionAccessScopeKey = 'reporting-datasets-access';
  const hasExpressionFunctionAccess = userScopes.includes(hasFunctionAccessScopeKey);
  if (!hasExpressionFunctionAccess) {
    const expressionFieldsList = report.expressions;
    return !expressionFieldsList.find(expressionField => {
      if (reportHasExpressionEncoded(report, expressionField) && !isDatePartExpressionFieldAlias(expressionField.alias)) {
        const {
          violations
        } = checkExpressionField(expressionField, expressionFieldsList, meta, true);
        return violations.size > 0;
      }
      return false;
    });
  }
  return true;
}, [report => report, (_, meta) => meta, (_, __, userInfo) => {
  if (!userInfo) return [];
  const {
    user: {
      scopes
    }
  } = toJS(userInfo);
  return scopes;
}]);
export const validateExpressionColumns = (columns, expressionFields, meta) => {
  return columns.every(column => checkFieldExpressionIsValid(column.field, meta, expressionFields));
};
export const columnExpressionsAreValid = createConstraint('column-expressions-are-valid', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, validateExpressionColumns, [report => report.columns, report => report.expressions, (_, meta) => meta]);
const validateFilterGroupExpressions = (filterGroups, expressionFields, meta) => filterGroups.every(group => group.filters.every(filter => checkFieldExpressionIsValid(filter.field, meta, expressionFields)));
export const filterExpressionsAreValid = createConstraint('filter-expressions-valid', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, validateFilterGroupExpressions, [report => report.filtering.groups, report => report.expressions, (_, meta) => meta]);

// Have a combined validation message if both filters and columns contain invalid expressions
export const filterAndColumnExpressionsAreValid = createConstraint('filter-and-columns-expressions-valid', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, (filterGroups, columns, expressionFields, meta) => {
  const allColumnsValid = validateExpressionColumns(columns, expressionFields, meta);
  const allFiltersValid = validateFilterGroupExpressions(filterGroups, expressionFields, meta);
  return !(!allColumnsValid && !allFiltersValid);
}, [report => report.filtering.groups, report => report.columns, report => report.expressions, (_, meta) => meta]);
const datasetProperties = datasetFields => datasetFields.reduce((acc, field) => {
  acc.push(field.name);
  return acc;
}, []);
const datasetBasedColumnsUseValidDatasetProperties = (columns, datasetDefinition) => {
  const currentDatasetProperties = datasetProperties(datasetDefinition.fields);
  return columns.every(column => {
    // ensures we aren't checking formula field based columns
    if (column.field.source === 'TABLE') {
      return currentDatasetProperties.includes(column.field.name);
    }
    return true;
  });
};
const datasetBasedFiltersUseValidDatasetProperties = (filterGroups, datasetDefinition) => {
  const currentDatasetProperties = datasetProperties(datasetDefinition.fields);
  return filterGroups.every(group => group.filters.every(filter => {
    // ensures we are only checking dataset filters, not formula field or dashboard filters
    if (filter.field.source === 'TABLE' && filter.field.table === '__DATASET__') {
      return currentDatasetProperties.includes(filter.field.name);
    }
    return true;
  }));
};
export const datasetPropertiesAreValid = createConstraint('dataset-properties-are-valid', ConstraintTypes.HARD, InvalidSpecificationTypes.UNSUPPORTED, (columns, datasetDefinition, filtering) => {
  if (!datasetDefinition) {
    return true;
  }
  const columnsValid = datasetBasedColumnsUseValidDatasetProperties(columns, datasetDefinition);
  const filtersValid = datasetBasedFiltersUseValidDatasetProperties(filtering, datasetDefinition);
  return columnsValid && filtersValid;
}, [report => report.columns, (_, meta) => meta.datasetDefinition, report => report.filtering.groups]);
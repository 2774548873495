import { isImmutableDashboardReponse } from 'dashboard-lib/public/dashboard';
import { getReportDataSourceUsages } from '../filter/utils/dataSourceHelpers';
import { getDashboardReports } from '../filter/utils/schema/dashboardSchema';
export const getDashboardReportDataSources = dashboard => {
  const reports = getDashboardReports(dashboard);
  const dataSources = getReportDataSourceUsages({
    reports
  });
  return Object.keys(dataSources);
};
export const getDashboardDataSources = dashboard => {
  const nonImmutableDash = isImmutableDashboardReponse(dashboard) ? dashboard.toJS() : dashboard;
  const dashboardDataSources = nonImmutableDash.dataSources || [];
  const reportDataSourceIds = getDashboardReportDataSources(nonImmutableDash);
  const quickFilterGroupSources = (nonImmutableDash.filterGroups || []).map(g => g.properties.map(p => p.dataSourceId)).flat();
  return Array.from(new Set(reportDataSourceIds.concat(quickFilterGroupSources).concat(dashboardDataSources)));
};
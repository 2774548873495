'use es6';

import { fromJS, List, Map as ImmutableMap } from 'immutable';
import { constructContext, getContextDiff } from '../records/Context';
import { getReportGenerator } from '../config/generate';
import { deriveContextFromReport } from '../constants/sub-app/contextDefaults';
import { fixInitialContext } from './fixInitialContext';
import { isJourneyReport } from './salesAnalyticsHelpers';
import { checkHasV2DealStageProperties } from './access';
export function getSubAppKeyFromReport(report) {
  const subAppKey = report.getIn(['displayParams', 'salesAnalytics', 'subAppKey']);
  return subAppKey === 'actualVersusGoal' ? 'quotaAttainment' : subAppKey;
}
export function getContextFromReport(report, scopes, gates) {
  const subAppKey = getSubAppKeyFromReport(report);
  const derivedContext = deriveContextFromReport(report, subAppKey, scopes, gates, true);
  const existingContext = report.getIn(['displayParams', 'salesAnalytics', 'context'], ImmutableMap());
  const correctedReportContext = fixInitialContext(existingContext.toJS());
  const newContext = fromJS(Object.assign({}, correctedReportContext, derivedContext));
  return constructContext().merge(newContext);
}
export function updateReportWithContext(report, newContext, filteredGates, scopes) {
  const subAppKey = getSubAppKeyFromReport(report);
  const reportWithoutCustomFilters = isJourneyReport(report) ? report.setIn(['journeyQuery', 'objectQuery', 'filtering'], null) : report.setIn(['config', 'filters', 'custom'], List());
  const generateReport = getReportGenerator(report);
  const generatedReport = generateReport({
    subAppKey,
    context: newContext,
    reportTemplate: reportWithoutCustomFilters,
    filteredGates,
    options: {
      hasV2DealStageProperties: checkHasV2DealStageProperties(scopes)
    }
  });
  const updatedReport = isJourneyReport(report) ? report.set('journeyQuery', generatedReport.get('journeyQuery')) : report.set('config', generatedReport.get('config'));
  return updatedReport.set('displayParams', generatedReport.get('displayParams', ImmutableMap())).set('chartType', generatedReport.get('chartType')).setIn(['displayParams', 'salesAnalytics', 'subAppKey'], subAppKey).setIn(['displayParams', 'salesAnalytics', 'context'], getContextDiff(newContext));
}
import { Set as ImmutableSet } from 'immutable';
import map from 'transmute/map';
import get from 'transmute/get';
import { isStaticWidget } from './reportSchema';
import { getReport } from './widgetSchema';
import { isString } from '../../../common/jsUtils';
import indexBy from 'transmute/indexBy';
import compose from 'transmute/compose';
import set from 'transmute/set';
import { List, Record, Map as ImmutableMap } from 'immutable';
// @ts-expect-error upstream file not migrated
import UrlPattern from 'PatternValidationJS/patterns/Url';
export const PINNED_PROPERTIES = 'pinnedProperties';
export const FILTER_GROUPS = 'filterGroups';
export const DashboardTemplateResponse = Record({
  id: null,
  createdAt: null,
  updatedAt: null,
  name: null,
  description: null,
  scopes: List(),
  audience: List(),
  widgetTemplates: ImmutableMap(),
  status: null
});
export const getId = get('id');
export const getWidgets = get('widgets');
export const getWidgetTemplates = get('widgetTemplates');
export const getName = get('name');
export const getDescription = get('description');
export const getAudience = get('audience');
export const getScopes = get('scopes');
export const getStatus = get('status');
export const getFilterGroups = get('filterGroups');
export const getTemplateKey = get('templateKey');
export const getDashboardReports = dashboard => {
  const widgets = getWidgets(dashboard);
  return widgets.reduce((reports, widget) => {
    const report = widget.report;
    if (!isStaticWidget(report)) {
      reports.push(report);
    }
    return reports;
  }, []);
};
export const getReportTemplateIdSet = compose(ImmutableSet, map(wTemplate => {
  // @ts-expect-error immutablejs/TS incompatability
  return get('id')(getReport(wTemplate));
}), getWidgetTemplates);
export const setWidgetTemplates = set('widgetTemplates');
export const isValidDashboardString = name => {
  const isNonEmpty = isString(name) && name.trim().length;
  return isNonEmpty && !UrlPattern.test(name);
};
export const normalizeDashboardTemplate = dashboardTemplate => {
  const widgetTemplateList = getWidgetTemplates(dashboardTemplate);
  const widgetTemplateMap = indexBy(compose(getId, get('reportTemplate')), widgetTemplateList);
  return setWidgetTemplates(widgetTemplateMap, dashboardTemplate);
};
export const isDashboardTemplate = dashboard => 'templateKey' in dashboard;
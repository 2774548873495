'use es6';

import { Map as ImmutableMap, List as ImmutableList } from 'immutable';
import { getCustomFilters } from 'reporting-data/report/configReportGetters';
import { ANALYTICS_SOURCES, ANALYTICS_ALL_PAGES, ANALYTICS_UTM_CAMPAIGNS, ANALYTICS_UTM_CONTENTS, ANALYTICS_UTM_MEDIUMS, ANALYTICS_UTM_SOURCES, ANALYTICS_UTM_TERMS, ANALYTICS_DEVICE_TYPE_DETAILS, ANALYTICS_DEVICE_TYPES, ANALYTICS_GEOLOCATION, ANALYTICS_BROWSER_DETAILS, ANALYTICS_PATHS } from 'reporting-data/constants/dataTypes/unified';
export const viewIsSubdomain = view => isNaN(Number(view));
export const dataTypeSupportsAnalyticsViews = dataType => [ANALYTICS_SOURCES, ANALYTICS_ALL_PAGES, ANALYTICS_UTM_CAMPAIGNS, ANALYTICS_UTM_CONTENTS, ANALYTICS_UTM_MEDIUMS, ANALYTICS_UTM_SOURCES, ANALYTICS_UTM_TERMS, ANALYTICS_DEVICE_TYPES, ANALYTICS_DEVICE_TYPE_DETAILS, ANALYTICS_GEOLOCATION, ANALYTICS_BROWSER_DETAILS, ANALYTICS_PATHS].includes(dataType);
export const dataTypeSupportsSubdomains = dataType => dataType === ANALYTICS_SOURCES;
export const convertListToSingleValue = maybeList => ImmutableList.isList(maybeList) ? maybeList.first() : maybeList;
export const getSingleFilterValue = filter => {
  if (!filter) {
    return null;
  }

  // reports can still have an IN filter where value is a list -- we should make analytics view only support EQ eventually
  const filterValue = filter.get('value') || filter.get('values');
  return convertListToSingleValue(filterValue);
};
export const getAnalyticsViewFilters = report => {
  return getCustomFilters(report.get('config')).reduce((matchedFilters, filter) => {
    if (filter.get('property') === 'filterId') {
      return matchedFilters.set('analyticsView', filter);
    }
    if (filter.get('property') === 'subdomain') {
      return matchedFilters.set('subdomain', filter);
    }
    return matchedFilters;
  }, ImmutableMap({
    analyticsView: null,
    subdomain: null
  }));
};
export const hasAnalyticsViewsAccess = ({
  scopes
}) => {
  return scopes && scopes.includes('analytics-filters-read');
};